import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.numberAggregation()
    this.collapsableStructure()
  }

  collapsableStructure() {
    const collapsedElements = this.element.querySelectorAll('ul.collapse')
    const _this = this

    // Adds the collapsable structure
    for (let i = 0; i < collapsedElements.length; i++) {
      const prevElement = collapsedElements[i].previousElementSibling
      prevElement.innerHTML = '<i class="fas fa-caret-right pr-2"></i>' + prevElement.innerHTML
    }

    $('#category-tree li i').click(function() {
      const el = $(_this).parent('li').next('ul')
      if (el.hasClass('show')) {
        el.removeClass('show')
        _this.classList.remove('fa-caret-down')
        _this.classList.add('fa-caret-right')
      } else {
        el.addClass('show')
        _this.classList.remove('fa-caret-right')
        _this.classList.add('fa-caret-down')
      }
    })
  }

  numberAggregation() {
    const ulElements = this.element.getElementsByTagName('ul')
    for (let i = ulElements.length - 1; i >= 0; i--) {
      const liElements = ulElements[i].getElementsByTagName('li')

      // Don't loop through the top category structure, as no number is aggregated
      if (i > 0) {
        let aggregateCount = 0
        for (let j = liElements.length - 1; j>= 0; j--) {
          aggregateCount += parseInt(liElements[j].dataset.itemCount)
        }

        // update the <li> element with the aggregate information
        const prevLiElement = ulElements[i].previousElementSibling
        const prevLiLabelElement = prevLiElement.querySelector('label')
        let newStr = prevLiLabelElement.innerHTML.trim()
        aggregateCount += parseInt(prevLiElement.dataset.itemCount)
        newStr = newStr.replace(/\(\d+\)$/, '(' + aggregateCount + ')')
        prevLiLabelElement.innerHTML = newStr
      }
    }
  }
}
