export class TicketItem {
  static add(response, screenNotification) {
    if (response.hasOwnProperty('error')) {
      return screenNotification.show(response.error, 'error')
    }

    const itemValue = JSON.parse(response.itemValue)
    if (this.itemIsIncluded(itemValue.id)) {
      return screenNotification.show(window.I18n.t('js.barcode_added_to_list'), 'error')
    } else {
      const ticketList = document.getElementById('ticket-items-table')
      ticketList.insertAdjacentHTML('afterend', this._template(response.itemValue))
      screenNotification.show(response.message, 'success')
    }
  }

  static itemIsIncluded(id) {
    const ids = this.currentIds()

    return ids.includes(id)
  }

  static currentIds() {
    const ids = []
    document.querySelectorAll('[data-item-id]').forEach(
      (row) => {
        ids.push(parseInt(row.dataset.itemId))
      },
    )

    return ids
  }

  static _template(newItem) {
    return `<div data-controller="ticket-item-form" data-ticket-item-form-item-value='${newItem}' ` +
      'data-ticket-item-form-merge-value="true"></div>'
  }
}
