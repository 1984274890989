import { Controller } from 'stimulus'
import Trix from 'trix'
require('@rails/actiontext')
require('trix/dist/trix.css')

const keyToAddAttachment = '#training'

export default class extends Controller {
    static targets = ['template', 'editor']

    connect() {
      this.checkIfContainsKeyWord()

      this.editorTarget.addEventListener('trix-change', () => {
        this.checkIfContainsKeyWord()
      })
    }

    checkIfContainsKeyWord() {
      if (this.editorTarget.editor.getDocument().toString().includes('#')) {
        const editor = this.editorTarget.editor
        const editorText = editor.getDocument().toString().toLowerCase()
        const indexOfKeyWord = editorText.indexOf(keyToAddAttachment)
        if (indexOfKeyWord > 0) {
          this.createAttachment()
        }
      }
    }

    createAttachment() {
      const editor = this.editorTarget.editor

      const attachment = new Trix.Attachment({
        sgid: this.templateTarget.dataset.sgid,
        content: this.templateTarget.innerHTML,
      })

      // Get the 1) current position of the keyword, 2) select it, 3) delete it and 4) insert attachment
      const keyWordPos = editor.getDocument().toString().toLowerCase().indexOf(keyToAddAttachment)
      editor.setSelectedRange([keyWordPos, keyWordPos + keyToAddAttachment.length])
      editor.deleteInDirection('backward')
      editor.insertAttachment(attachment)
    }
}
