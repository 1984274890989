import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['emailSignatureDefault', 'onSiteSignatureDefault']

  toggle(e) {
    const currentTargetId = e.currentTarget.id
    if (currentTargetId === 'entity_settings_allow_e_signatures_by_default') {
      return this.onSiteSignatureDefaultTarget.checked = false
    }

    if (currentTargetId === 'entity_settings_allow_on_site_signatures_by_default') {
      return this.emailSignatureDefaultTarget.checked = false
    }
  }
}
