// Entry point for the build script in your package.json
import './jquery'
import '../../vendor/assets/javascripts/modernizr.custom/modernizr.custom.js'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/js/dist/popover.js'
import '@preline/strong-password'
import { setCookie, getCookie } from './utils/cookies_helper'

import Rails from '@rails/ujs'
Rails.start()
window.Rails = Rails

import appInit from '../../vendor/assets/javascripts/angle-modules/app.init.js'
document.addEventListener('DOMContentLoaded', appInit)

import swal from 'sweetalert2'
// import 'weetalert2/dist/sweetalert2.min.css'
window.swal = swal

import introJs from 'intro.js/intro.js'
window.introJs = introJs

// load Stimulus.js
import './controllers'

// load rails-turbo
import './turbo'
// NOTE: while not all app migrated to turbo
window.Turbo.session.drive = false

Turbo.StreamActions.dispatch_custom_event = function () {
  const content = this.querySelector("template").content
  const params = content.querySelector("div[params]").getAttribute("params")
  const turboAction = content.querySelector("div[turbo-action]").getAttribute("turbo-action")
  window.dispatchEvent(new CustomEvent(turboAction, { detail: JSON.parse(params) }))
};

import '@fortawesome/fontawesome-pro/css/regular'
import '@fortawesome/fontawesome-pro/css/solid'
import '@fortawesome/fontawesome-pro/css/fontawesome'

import select2 from 'select2/dist/js/select2'
import 'select2/dist/css/select2.css'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css'
window.select2 = select2()

window.fadeOut = function(element) {
  let op = 1 // initial opacity

  const timer = setInterval(function() {
    if (!element) {
      clearInterval(timer)
    }
    if (op <= 0.15) {
      clearInterval(timer)
      element.style.display = 'none'
    }
    element.style.opacity = op
    element.style.filter = 'alpha(opacity=' + op * 100 + ')'
    op -= op * 0.1
  }, 50)
}

$('#scanner').on('hide.bs.modal', function() {
  window.dispatchEvent(new CustomEvent('turn-off-scanner', {}))
})

$(document).on('show.bs.dropdown', function(e) {
  const isTableDropdown = $(e.target).find('.dropdown-menu').is('#table-sticky-headers .dropdown-menu')
  if (!isTableDropdown) {
    return
  }
  const dropdown = $(e.target).find('.dropdown-menu')
  $('body').append(dropdown.css({
    position: 'absolute',
    left: $('.dropdown').offset().left,
    top: $('.dropdown').offset().top,
  }).detach())
})

$('.aside-inner a[data-toggle="collapse"]').on('click', function(e) {
  $('body').removeClass('nav-toggled')
})

$('.aside-inner .nav-toggle').on('click', function(e) {
  $('body').toggleClass('nav-toggled')
  setCookie('nav_toggled', $('body').hasClass('nav-toggled'), 365)
  $('.aside-inner a[data-toggle="collapse"]').attr('aria-expanded', false)
  $('.aside-inner .collapse').removeClass('show')
  $('.aside-inner a').each((index, link) => {
    if ($('body').hasClass('nav-toggled')) {
      $(link).attr('title', $(link).attr('tmp-title')).attr('tmp-title', '')
    } else {
      $(link).attr('tmp-title', $(link).attr('title')).attr('title', '')
    }
  })
})

getCookie('nav_toggled') === 'true' && $('body').addClass('nav-toggled')

$('.aside-inner a').each((index, link) => {
  if (!$('body').hasClass('nav-toggled')) {
    $(link).attr('tmp-title', $(link).attr('title')).attr('title', '')
  }
})

$('#locationFilter').on('keyup change', function(e) {
  const links = $(e.target).parents('.dropdown-container').find('a')
  const searchValue = $(e.target).val()
  links.each((index, link) => {
    if ($(link).text().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
      $(link).show()
    } else {
      $(link).hide()
    }
  })
})

$('.list-group-item.dropdown-link').on('click', function(e) {
  e.stopPropagation()
  $(e.target).next('.dropdown-container').toggleClass('open')
  $(e.target).find('.fa-solid').toggleClass('open')
})

// tw modal actions
$('[modal-id]').on('click', (e) => {
  const modalId = $(e.target).attr('modal-id')
  $(`#${modalId}`).removeClass('tw-hidden')
})

$('[modal-close]').on('click', (e) => {
  if (e.target.attributes['modal-close']){
    $('[aria-modal="true"]').addClass('tw-hidden')
  }
})
