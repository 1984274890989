import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['showMessage', 'input']
  static values = {
    data: Array,
  }
  // data-controller="text-display" to initialize the data controller
  // <div data-text-display-target="showMessage"> for the value display
  // Array will have data like
  // [ { max: 1, message:['Singular Message', 'plural Message'] }, { min: 1, message: ['Generic message'] }]
  // Element to change text display
  // data-action="click->text-display#toggle"


  // You then must provide a message using the value API of stimulus as such:
  // data-toggle-data-value='[]'

  connect() {
    this.showTextForInputValue()
  }

  change() {
    this.showTextForInputValue()
  }

  showTextForInputValue() {
    const currentValue = parseInt(this.inputTarget.value)
    for (let i = 0; i < this.dataValue.length; i++) {
      if (currentValue > this.dataValue[i].max || currentValue < this.dataValue[i].min) {
        this.showMessageTarget.innerHTML = this.dataValue[i].message[this.dataValue[i].message.length - 1]
        break
      }
      if (currentValue == this.dataValue[i].max || currentValue == this.dataValue[i].min) {
        this.showMessageTarget.innerHTML = this.dataValue[i].message[0]
        break
      }
      if (currentValue == this.dataValue[i].exact) {
        this.showMessageTarget.innerHTML = this.dataValue[i].message[0]
        break
      }
    }
  }
}
