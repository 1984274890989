import { Controller } from 'stimulus'
import { Loader } from '@googlemaps/js-api-loader'
// https://github.com/googlemaps/js-markerclustererplus
import MarkerClusterer from '@googlemaps/markerclustererplus'

const loader = new Loader({
  apiKey: window.GOOGLE_API_KEY,
  version: 'weekly',
})
const DELAY_TO_LOAD_MARKERS = 1000

export default class extends Controller {
  static targets = ['map', 'position', 'geolocation', 'createdAt', 'timeAgo', 'createdBy', 'title'];

  connect() {
    // if an object doesn't have a gps_position yet, prevent the map from firing up
    const _this = this
    setTimeout(function() {
      if (window.markerPositions.length > 0) {
        _this.map_element = _this.hasMapTarget ? _this.mapTarget : _this.element
        loader.load().then(() => {
          _this.createMap(window.markerPositions[0].latitude, window.markerPositions[0].longitude, 16)
          _this.setMarkers()
        })
      }
    }, DELAY_TO_LOAD_MARKERS)
  }

  setMarkers() {
    const self = this

    const bounds = new google.maps.LatLngBounds()
    const infowindow = new google.maps.InfoWindow()

    // Remove any existing markers
    if (this.hasOwnProperty('markers')) {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null)
      }
      this.markers = []
    }

    // window.markerPositions is a global variable, that is loaded in the rails view
    self.markers = window.markerPositions.map((position, i) => {
      const marker = this.createMarker(position.latitude, position.longitude, position.created_at)

      // Set the map bounderies
      bounds.extend(marker.position)

      // Add the event listener for the info box to open when clicked on the marker
      marker.addListener('click', () => {
        this.prepInfoWindow(position, marker, infowindow)
      })

      return marker
    })

    // If there is only one marker. Open up the infoWindow immerdiately
    if (window.markerPositions.length == 1) {
      this.map.setCenter(self.markers[0].position)
      this.map.setZoom(17)
      this.prepInfoWindow(window.markerPositions[0], self.markers[0], infowindow)
    } else {
      this.map.fitBounds(bounds)
      this.clusterMarkers()
    }
  }

  createMap(lat, lng, zoom) {
    if (typeof lat === 'string') {
      lat = parseFloat(lat)
    }
    if (typeof lng === 'string') {
      lng = parseFloat(lng)
    }
    this.map_element = this.hasMapTarget ? this.mapTarget : this.element
    this.map = new google.maps.Map(this.map_element, {
      center: { lat: lat, lng: lng },
      zoom: zoom,
    })
  }

  createMarker(lat, lng, posCreatedAt = null) {
    let icon
    if (typeof lat === 'string') {
      lat = parseFloat(lat)
    }
    if (typeof lng === 'string') {
      lng = parseFloat(lng)
    }
    if (Object.prototype.toString.call(posCreatedAt) != '[object Date]') {
      posCreatedAt = new Date(posCreatedAt)
    }

    if (!posCreatedAt) {
      icon = window.GMAPS_MARKERS.red
    } else if (posCreatedAt >= this.dateMonthAgo(1)) {
      icon = window.GMAPS_MARKERS.green
    } else if (posCreatedAt >= this.dateMonthAgo(6)) {
      icon = window.GMAPS_MARKERS.blue
    } else if (posCreatedAt >= this.dateMonthAgo(12)) {
      icon = window.GMAPS_MARKERS.yellow
    } else {
      icon = window.GMAPS_MARKERS.gray
    }
    return new google.maps.Marker({
      position: { lat: lat, lng: lng },
      map: this.map,
      icon: icon,
      animation: google.maps.Animation.DROP,
    })
  }

  clusterMarkers() {
    // https://github.com/googlemaps/js-markerclustererplus
    this.markerClusterer = new MarkerClusterer(this.map, this.markers, {
      maxZoom: 17,
      minimumClusterSize: 2,
      gridSize: 40,
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    })
  }

  prepInfoWindow(position, marker, infowindow) {
    infowindow.close() // Close previously opened infowindow
    const gmapUrl = `https://www.google.com/maps/search/?api=1&query=${position.latitude},${position.longitude}`

    let content = `<h4>${position.name}</h4>
                      <p>${position.geolocation ? position.geolocation : this.gpsFormater(position) }</p>
                      <p><b>${window.I18n.t('js.category')}</b>: ${position.category}<br>`

    // only show this link, if it is provided in the global window.markerPositions
    // on the individual item, the URL should not be shown
    if (position.hasOwnProperty('link')) {
      content += `<a href="${position.link}">${window.I18n.t('js.to_object')}</a><br>`
    }
    content += `<a href="${gmapUrl}" target="_blank">${window.I18n.t('js.switch_to_google_maps')}</a></p>
                   <p><b>${window.I18n.t('js.position_created_at')}</b>:<br>
                   ${position.created_at_formated} - ${position.created_by}</p>`

    infowindow.setContent(content)
    infowindow.open(map, marker)
  }
  updateMap(event) {
    this.loadNewPosition(event)
    this.setMarkers()
  }

  loadNewPosition(event) {
    // empty the current values from the array
    const newPosition = event.target.dataset
    window.markerPositions = []
    window.markerPositions.push({
      name: newPosition.name,
      category: newPosition.category,
      latitude: newPosition.latitude,
      longitude: newPosition.longitude,
      geolocation: newPosition.geolocation,
      created_at: newPosition.createdAt,
      created_at_formated: newPosition.createdAtFormated,
      created_by: newPosition.createdBy,
    })
  }

  gpsFormater(position) {
    const lat = (position.latitude >= 0 ?
      position.latitude.toString() + 'N' :
      (position.latitude * -1).toString() + 'S')
    const lng = (position.longitude >= 0 ?
      position.longitude.toString() + 'E' :
      (position.longitude * -1).toString() + 'W')
    return lat + ', ' + lng
  }

  dateMonthAgo(months) {
    const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate()
    const currentDate = new Date()
    const date = new Date()
    date.setDate(1)
    date.setMonth(date.getMonth() - months)
    date.setDate(Math.min(currentDate.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)))
    return date
  }
}
