import { Controller } from 'stimulus'
import 'bootstrap-filestyle'

export default class extends Controller {
    static values = { text: String,
      classInput: String,
      btnClass: String,
      icon: String,
      input: Boolean, // Filestyle default: true
      dragdrop: Boolean, // Filestyle default: true
    }

    connect() {
      const options = {}

      if (this.hasTextValue) {
        options['text'] = this.textValue
      } else {
        const defaultText = [
          window.I18n.t('js.select_file', { count: 1 }), window.I18n.t('js.select_file', { count: 2 }),
        ]
        // if multiple is given as attribute, use plural translation
        options['text'] = (this.element.attributes.hasOwnProperty('multiple') ? defaultText[1] : defaultText[0])
      }
      if (this.hasClassInputValue) {
        options['classInput'] = this.classInputValue
      }
      if (this.hasBtnClassValue) {
        options['btnClass'] = this.btnClassValue
      }
      if (this.hasDragdropValue) {
        options['dragdrop'] = this.dragdropValue
      }
      if (this.hasInputValue) {
        options['input'] = this.inputValue
      }
      // make the fa-folder-open icon the default icon
      if (this.element.attributes.hasOwnProperty('multiple')) {
        options['badge'] = true
        options['badgeName'] = 'badge-danger'
      }
      options['htmlIcon'] = this.iconValue || '<i class="far fa-folder-open mr-1"></i>'
      $(this.element).filestyle( options )
    }
}
