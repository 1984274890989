import { Controller } from 'stimulus'

export default class extends Controller {
  // add data-controller="select"

  // define the select box as a target
  // <%= f.select :item_type, #options#, data-select-target: 'select'

  // display the information depending selected value --> add target to callout or some other p or div tag
  // data-select-target='selectedInfo'
  // add a data attribute: will be displayed if value is equal to the selected value in the select box
  // data-selected-value='# value from selected option#'

    static targets = ['select', 'selectedInfo']

    static values = { disableChange: Boolean,
      disableChangeErrorMessage: String }

    connect() {
      this.initialValue = this.selectTarget.value
      this.showInformationForSelectValue()
    }

    change(e) {
      this.showInformationForSelectValue()
      const event = new CustomEvent('update-select', {
        detail: {
          name: e.target.name, value: e.target.value,
        },
      })
      window.dispatchEvent(event)
    }

    showInformationForSelectValue() {
      this.selectedInfoTargets.forEach((selectedInfo) => {
        if (selectedInfo.dataset.selectedValue == this.selectTarget.value) {
          selectedInfo.classList.remove('d-none')
        } else {
          selectedInfo.classList.add('d-none')
        }
      })
    }
}
