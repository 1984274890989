import { Application } from '@hotwired/stimulus'
import Appsignal from '@appsignal/javascript'

export const appsignal = new Appsignal({
  key: window.APPSIGNAL_API_KEY,
})

const application = Application.start()

// Configure Stimulus development experience
application.debug = window.ENV !== 'production'
window.Stimulus = application

export { application }
