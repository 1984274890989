import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image', 'wrapper']
  static values = {
    process: { type: Boolean, default: true },
  }

  // this controller resizes the wrapping div to also fit portrait images.
  // data-controller="responsive-image"

  // define the warpping div data-responsive-image-target="wrapper"
  // image target --> 'data-responsive-image-target': 'image'

  // set the action on load event
  // 'data-action': 'complete->responsive-image#resize'

  // to deactivate processing add the following value (same level where you set data-controller)
  // data-responsive-image-process-value="false"

  connect() {
    // Also on connect is added. Because if image is already loaded before controller loads
    // data-action with complete event won't fire
    if (this.hasImageTarget && this.imageIsLoaded) {
      this.resize()
      this.zoom({ params: { largeImage: null } })
    }
  }

  resize() {
    if (!this.processValue) return
    const width = this.imageTarget.naturalWidth
    const height = this.imageTarget.naturalHeight
    const ratio = height / width * 100
    this.wrapperTarget.style.paddingBottom = ratio + '%'
  }

  zoom({ params }) {
    if (params.largeImage) {
      document.body.insertAdjacentHTML('beforeend', `
        <div class="image-popup" data-controller="responsive-image">
          <button class="image-popup-close" data-action="click->responsive-image#close"></button>
          <img src="${params.largeImage}" alt="" />
        </div>
      `)
    }
  }

  close() {
    document.querySelector('.image-popup').remove()
  }

  get imageIsLoaded() {
    return this.imageTarget.complete
  }
}
