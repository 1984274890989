import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="ticket-form"
export default class extends Controller {
  static targets = [
    'locationSelect',
    'searchUrl',
    'itemList',
    'ticketType',
    'ticketId',
  ]

  connect() {
    this.previousLocationValue = ''
  }

  previousLocation(event) {
    event.preventDefault()
    this.previousLocationValue = this.locationSelectTarget.value
  }

  updateTicketType() {
    const ticketType = this.ticketTypeTarget.value
    const ticketId = this.ticketIdTarget.value
    if (this.hasTicketTypeTarget) {
      const href = `/tickets/ticket_type_attributes?ticket_type_id=${ticketType}&ticket_id=${ticketId}`
      fetch(href, {
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
        },
      })
        .then((r) => r.text())
        .then((html) => Turbo.renderStreamMessage(html))
    }
  }
}
