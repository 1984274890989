import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['labelBox', 'labelBoxLogoAddress', 'addressLogoCheckbox',
    'verticalLabelCheckbox', 'labelBoxWrapper', 'labelWidth',
    'labelHeight', 'infoVerticalAddress', 'infoVertical', 'infoHorizontalAddress',
    'infoHorizontal', 'qrCodeDescription']

  connect() {
    this.redrawLabelDisplay()
  }

  redrawLabelDisplay() {
    if (this.addressLogoCheckboxTarget.checked) {
      this.labelBoxLogoAddressTarget.style.display = 'flex'
    } else {
      this.labelBoxLogoAddressTarget.style.display = 'none'
    }

    if (this.verticalLabelCheckboxTarget.checked) {
      this.labelBoxTarget.style.flexDirection = 'column'
      this.labelBoxLogoAddressTarget.style.flexDirection = 'column'
    } else {
      this.labelBoxTarget.style.flexDirection = null
      this.labelBoxLogoAddressTarget.style.flexDirection = null
    }

    const elements = document.querySelectorAll('#label-info small')
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none'
    }

    if (this.verticalLabelCheckboxTarget.checked && this.addressLogoCheckboxTarget.checked) {
      this.labelBoxWrapperTarget.style.width = '80mm'
      this.labelWidthTarget.value = 80
      this.labelBoxWrapperTarget.style.height = '100mm'
      this.labelHeightTarget.value = 100
      this.infoVerticalAddressTarget.style.display = 'inline'
    } else if (this.verticalLabelCheckboxTarget.checked) {
      this.labelBoxWrapperTarget.style.width = '80mm'
      this.labelWidthTarget.value = 80
      this.labelBoxWrapperTarget.style.height = '70mm'
      this.labelHeightTarget.value = 70
      this.infoVerticalTarget.style.display = 'inline'
    } else if (this.addressLogoCheckboxTarget.checked) {
      this.labelBoxWrapperTarget.style.width = '100mm'
      this.labelWidthTarget.value = 100
      this.labelBoxWrapperTarget.style.height = '65mm'
      this.labelHeightTarget.value = 65
      this.infoHorizontalAddressTarget.style.display = 'inline'
    } else {
      this.labelBoxWrapperTarget.style.width = '80mm'
      this.labelWidthTarget.value = 80
      this.labelBoxWrapperTarget.style.height = '32mm'
      this.labelHeightTarget.value = 32
      this.infoHorizontalTarget.style.display = 'inline'
    }
  }

  recalculateScale(event) {
    const inputBoxValue = event.target.value / 100
    const cssTextValue = 'scale(' + inputBoxValue + ',' + inputBoxValue + ')'

    this.labelBoxWrapperTarget.style.transform = cssTextValue
  }

  recalculateFont(event) {
    const inputBoxValue = event.target.value / 100

    this.qrCodeDescriptionTarget.style.fontSize = inputBoxValue + 'em'
    this.labelBoxLogoAddressTarget.style.fontSize = inputBoxValue + 'em'
  }
}
