import { Controller } from 'stimulus'
import Tribute from 'tributejs'
import Trix from 'trix'

const handleTextareaExpand = function(textarea) {
  const containerId = textarea.dataset.containerId
  const container = document.getElementById(containerId)
  if (textarea.value !== '') {
    container.classList.remove('comments--inactive')
  }
  textarea.addEventListener('focus', () => {
    container.classList.remove('comments--inactive')
  })
  textarea.addEventListener('blur', () => {
    if (textarea.value === '') {
      container.classList.add('comments--inactive')
    }
  })
  $(window).on('beforeunload', () => {
    if (textarea && textarea.value !== '') {
      return true
    }
  })
}
export default class extends Controller {
  static targets = ['textArea']
  static values = { employees: Array }

  connect() {
    if (this.employeesValue.length > 0) {
      this.editor = this.textAreaTarget.editor
      this.initialiseTribute()
    }
    const toolbarId = this.textAreaTarget.getAttribute('toolbar')
    const toolbar = document.getElementById(toolbarId)
    toolbar.hidden = true
    handleTextareaExpand(this.textAreaTarget)
  }

  initialiseTribute() {
    this.tribute = new Tribute({
      values: this.employeesValue,
      lookup: 'name',
      allowSpaces: true,
    })
    const inputArea = this.textAreaTarget
    this.tribute.attach(inputArea)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    inputArea.addEventListener('tribute-replaced', this.replaced)
  }

  disconnect() {
    this.tribute.detach(this.textAreaTarget)
    $(window).off('beforeunload')
  }

  replaced(event) {
    const mention = event.detail.item.original
    const attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: `<span>${mention.name}</span>`,
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(' ')
  }

  clean(event) {
    event.preventDefault()
    this.editor.setSelectedRange([0, 5000])
    this.editor.deleteInDirection('forward')
    this.textAreaTarget.blur()
  }

  _pasteHtml(_html, startPos, endPos) {
    const position = this.editor.getPosition()
    this.editor.setSelectedRange([position - (endPos - startPos), position])
    this.editor.deleteInDirection('backward')
  }
}
