import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="checkoutable-resource"
export default class extends Controller {
  static targets = ['insert', 'resource', 'checkoutableType']
  static values = {
    insert: String,
    url: String,
  }
  connect() {
  }

  load() {
    const url = this.urlValue
    const selectBox = this.resourceTarget
    const checkoutableType = selectBox.options[selectBox.options['selectedIndex']].dataset['type']
    const data = { checkoutable_resource_type: checkoutableType, checkoutable_resource_id: selectBox.value }
    this.checkoutableTypeTarget.value = checkoutableType
    Rails.ajax({
      url: url,
      dataType: 'script',
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        options.data = JSON.stringify(data)

        return true
      },
      type: 'POST',
      success: function(data) {
        if (this.hasInsertTarget) {
          this.insertTarget.innerHTML = data.body.innerHTML
        } else {
          this.element.innerHTML = data.body.innerHTML
        }
      }.bind(this),
    })
  }

  removeScheduledRecord(e) {
    const fieldName = e.detail.fieldName
    const scheduledRecordId = e.detail.scheduledRecord
    const elements = document.getElementsByName(fieldName)
    elements.forEach((element) => {
      if (element.value == scheduledRecordId) {
        element.remove()
        document.getElementById(scheduledRecordId).remove()
      }
    })
  }
}
