import { Controller } from 'stimulus'
import Chart from 'chart.js'
import moment from 'moment'

// data-controller="line-chart"

// define where the canvas should be placed
// <canvas style="max-height: 400px" data-line-chart-target="canvas"></canvas>

// define the y-axis label name
// data-line-chart-y-axis-label-value="YOUR LABEL NAME"

// to pass in X/Y values
// either use the ValueAPI of Stimulus, or if displayed in the view, add a target
// VALUE API:    data-x-value='[5, 10, 20]'        ||    data-y-value='[5, 10, 20]'
// TARGET:       data-line-chart-target='xValue'   ||    data-line-chart-target='yValue'

// to pass in the values for the yAxis
// either use the ValueAPI of Stimulus
// data-y-value='[5, 10, 20]'
// of if display in the view, define a target on them
// data-line-chart-target='yValue'

export default class extends Controller {
    static targets = ['xValue', 'yValue', 'averageValue', 'canvas']

    static values = {
      yAxisLabel: String,
      averageLabel: String,
      secondYAxisLabel: String,
      average: Number,
      x: Array,
      y: Array,
    }

    connect() {
      if (this.hasCanvasTarget) {
        new Chart(this.canvasTarget.getContext('2d'), {
          data: this.lineData(),
          type: 'line',
          options: this.chartOptions(),
        })
      }
    }

    lineData() {
      const lineData = {
        datasets: [{
          label: this.yAxisLabelValue,
          backgroundColor: 'rgb(54, 162, 235, 0.1)',
          borderColor: 'rgb(54, 162, 235)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointBorderWidth: '3',
          pointRadius: 4,
          data: this.dataset,
          spanGaps: true,
        }, {
          label: this.averageLabelValue,
          backgroundColor: 'rgb(101, 101, 101, 0.1)',
          borderColor: 'rgb(101, 101, 101)',
          pointBackgroundColor: 'rgb(101, 101, 101)',
          pointBorderColor: '#fff',
          pointBorderWidth: '3',
          pointRadius: 4,
          fill: 'false',
          data: this.averageLineDataset,
          spanGaps: true,
          borderDash: [6, 3],
          borderWidth: 2,
        }],
      }

      return lineData
    }

    chartOptions() {
      return {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            time: { unit: this.xAxesTime() },
          }],
          yAxes: [{
            position: 'left',
            scaleLabel: {
              labelString: this.yAxisLabelValue,
              display: true,
            },
          }],
        },
      }
    }

    xAxesTime() {
      const day1 = this.xValue[0]
      const day2 = this.xValue[this.xValue.length -1]
      const months = moment(day2).diff((day1), 'months', true)

      return months >= 24 ? 'year' : (months >= 2 ? 'month' : 'day')
    }

    get xValues() {
      if (this.xValue.length == 0 && this.hasXValueTarget) {
        this.xValue = this.xValueTargets.map((el) => el.textContent.trim())
      }
      return this.xValue
    }

    get yValues() {
      if (this.yValue.length == 0 && this.hasYValueTarget) {
        this.yValue = this.yValueTargets.map((el) => el.textContent.trim())
      }
      return this.yValue
    }

    get dataset() {
      const dataset = []
      const xValues = this.xValues
      const yValues = this.yValues
      for (let i = 0; i < xValues.length; i++) {
        dataset.push({ x: xValues[i], y: yValues[i] })
      }
      return dataset
    }

    get averageLineDataset() {
      return [
        { x: this.xValues[0], y: this.averageValue },
        { x: this.xValues[this.xValues.length - 1], y: this.averageValue },
      ]
    }
}
