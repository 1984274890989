import { findIndex, isEmpty } from 'lodash'

const DEFAULT_DISPLAY_CLASS = 'd-none'
const NON_VISIBLE_DISPLAY = 'none'

const fetchOptionValue = function(option) {
  return option.value || option.value == ''
}
const toggleDisplayClass = function(target, displayClass = DEFAULT_DISPLAY_CLASS) {
  target.classList.toggle(displayClass)
}

const findSelectedOptionIndex = function(target, value, finder) {
  if (!!finder) {
    return findIndex(target, finder)
  } else {
    return findIndex(target, function(option) {
      return option.text == value
    })
  }
}

const toggleElementVisibility = (target, displayOpt = 'block') => {
  const targetVisible = (target.style.display === displayOpt) || isEmpty(target.style.display)
  targetVisible ?
    target.style.display = NON_VISIBLE_DISPLAY :
    target.style.display = displayOpt
}

const showElement = function(target, displayOpt = 'block', displayClass) {
  if (displayClass) {
    target.classList.remove(displayClass)
  } else {
    target.style.display = displayOpt
  }
}

const hideElement = function(target, displayClass) {
  if (displayClass) {
    target.classList.add(displayClass)
  } else {
    target.style.display = NON_VISIBLE_DISPLAY
  }
}

const setElementCssText = function(target, cssRuleText) {
  target.style.cssText = cssRuleText
}

const appendElementCssText = function(target, cssRuleText) {
  target.style.cssText += cssRuleText
}

const isValueSelected = function(selectTarget, value) {
  return selectTarget.options[selectTarget.selectedIndex].value == value
}

const setValueSelectTag = function(selectTarget, value) {
  const index = findSelectedOptionIndex(selectTarget, value, function(option) {
    return option.value == value
  })

  if (index === -1) return console.error('Could not set value for select tag name=' + selectTarget.name)

  selectTarget.selectedIndex = index
}

export {
  fetchOptionValue,
  toggleDisplayClass,
  findSelectedOptionIndex,
  toggleElementVisibility,
  showElement,
  hideElement,
  setElementCssText,
  appendElementCssText,
  isValueSelected,
  setValueSelectTag,
}

