import { Controller } from '@hotwired/stimulus'

const getTabName = function(location) {
  const match = location.search.match('tab=(.*)')

  if (match) return match[1]
}
const DELAY_TAB_RENDERING = 300

// Connects to data-controller="navigation-show-tab"
// Usage: Wrap container like ul/div with controller and controller init values.
// Example: data-navigation-show-tab-turbo-frame-name-value="item_show_tab"
// Name all the links IDs to match url tab parameter to be rendered.
// Example persons/49?tab=skills
// Link should have the following DOM ID 'skills-nav-item'
// Then attach action that will mark link as active
// Example: data-action: "navigation-show-tab:render@window->active-link#updateActiveLink"
export default class extends Controller {
  static values = {
    turboFrameName: String,
  }

  connect() {
    const _this = this

    setTimeout(function() {
      const tabLink = _this.findTabLink()

      if (!tabLink) return

      _this.renderTabUpdateActiveLink(tabLink)
    }, DELAY_TAB_RENDERING)
  }

  findTabLink() {
    const tabName = getTabName(window.location)

    return document.getElementById(tabName + '-nav-item')
  }

  renderTabUpdateActiveLink({ href: tabLinkUrl }) {
    if (!tabLinkUrl) return

    Turbo.visit(tabLinkUrl, { frame: this.turboFrameNameValue })
    window.dispatchEvent(new CustomEvent('navigation-show-tab:render', { detail: { href: tabLinkUrl } }))
  }
}
