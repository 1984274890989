import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['param', 'spinner']
    static values = {
      url: String,
      loadUponPageLoad: Boolean,
      method: { type: String, default: 'GET' },
    }

    // Controller to grab a script async

    // Add data-controller="async-script-loader"
    // data-async-script-loader-url-value="## URL ##"
    // data-async-script-loader-method-value="## METHOD ##"     if not provided -> DEFAULT: GET

    // add 'data-async-script-loader-load-upon-page-load-value' as datamap to call load for page load --> load_async

    // to execute the action:
    // data-action="click->async-script-loader#load"

    // to add parameters to the url, add to a input or select field
    // data-async-script-loader-target="param".
    // It will send the following as param: input[name]: value
    // to define a different attrribute name for submission, define own value like this
    // data-async-script-loader-param-name='#YOUR NAME'

    connect() {
      // if statement to prevent loading upon connect
      if (this.loadUponPageLoadValue) {
        this.load()
      }
    }

    load() {
      this.addSpinner()

      const params = {}
      let inputElValue
      for (let i = 0; i < this.paramTargets.length; i++ ) {
        if (this.paramTargets[i].getAttribute('type') == 'checkbox') {
          inputElValue = this.paramTargets[i].checked
        } else {
          inputElValue = this.paramTargets[i].value
        }

        if (this.paramTargets[i].dataset.asyncScriptLoaderParamName != undefined) {
          // if data attribute data-async-script-loader-param-name is defined
          params[this.paramTargets[i].dataset.asyncScriptLoaderParamName] = inputElValue
        } else {
          params[this.paramTargets[i].getAttribute('name')] = inputElValue
        }
      }

      window.$.ajax({
        url: this.urlValue,
        method: this.methodValue.toUpperCase(),
        data: params,
        dataType: 'script',
        headers: { 'X-CSRF-Token': this.authenticityToken },
      })
    }

    addSpinner() {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.add('whirl', 'double-up')
      }
    }

    get authenticityToken() {
      return document.querySelector('meta[name=\'csrf-token\']').getAttribute('content')
    }
}
