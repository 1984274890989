import { find, map } from 'lodash'

import { fetchOptionValue } from '../utils/html_element_helpers'
import OverviewController from './shared/overview_controller'

export default class extends OverviewController {
  static targets = [
    'moreFilters', 'responsibleSelect',
    'timePeriodSelect', 'expiringSkillsCheckbox',
    'formOptions', 'clearAllFilters',
  ]

  static values = {
    selectedAssignableIds: Array,
    url: { type: String, default: '/overview' },
  }

  updateAssignableIds(e) {
    this.selectedAssignableIdsValue = map(e.detail.selectedOptions, fetchOptionValue)
    this.updateOverview()
  }

  changePage(e) {
    e.preventDefault()
    const href = e.currentTarget.href
    const page = href.match(/page=(\d+)/)[1]
    this.updateOverview({ page: page })
  }

  buildRequestData(params) {
    const selectedResponsibleId = find(this.responsibleSelectTarget.selectedOptions, fetchOptionValue).value
    const selectedTimePeriodId = find(this.timePeriodSelectTarget.selectedOptions, fetchOptionValue).value

    if (!params) {
      params = {}
    }

    return Object.assign({
      assignable_ids: this.selectedAssignableIdsValue,
      responsible_id: selectedResponsibleId,
      period: selectedTimePeriodId,
      location_ids: this.fetchSelectedLocationIds(),
      reduce_view: this.expiringSkillsCheckboxTarget.checked,
      authenticity_token: Rails.csrfToken(),
    }, params)
  }
}
