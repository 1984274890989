import { Controller } from 'stimulus'

/*
 * set button data-link-simulator-url-param to some value
 * example: data-link-simulator-url-param="<%= item_dup_path(@item)%>"
 * once pressed user will be redirected to the given page
 */
export default class extends Controller {
  goToUrl(evt) {
    evt.currentTarget.setAttribute('disabled', true)
    window.location = evt.params.url
  }
}
