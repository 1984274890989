import { Controller } from 'stimulus'

import { hideElement, showElement } from '../utils/html_element_helpers'

const ITEM_TYPES = {
  blueprint: 'Blueprint',
  container: 'item_container',
  default: 'Item',
  itemStock: 'ItemStock',
}
const DISPLAY_CLASS = 'd-none'

export default class extends Controller {
  static targets = [
    'itemForm',
    'stockItemForm', 'stockNotificationEmail',
    'barcodeExtraDiv', 'barcodeInputField',
    'assignedConsumeableWrapper', 'withVatPriceLabel', 'withoutVatPriceLabel',
  ]

  submitForm(e) {
    e.preventDefault()
    e.stopPropagation()
    if (!this.itemFormTarget.checkValidity()) {
      return this.itemFormTarget.reportValidity()
    }
    this.itemFormTarget.classList.add('whirl')
    this.itemFormTarget.classList.add('double-up')
    this.itemFormTarget.submit()
  }

  toggleConsumable() {
    this.assignedConsumeableWrapperTarget.classList.toggle('d-none')
    const checkbox = this.assignedConsumeableWrapperTarget.querySelector('input[type="checkbox"]')
    if (checkbox) {
      checkbox.checked = false
    }
  }

  updateSelect(event) {
    const detail = event.detail
    switch (detail.name) {
      case 'item[type]':
        this.processChangeItemType(detail.value)
        break
    }
  }

  processChangeItemType(value) {
    switch (value) {
      case ITEM_TYPES.itemStock:
        this.addStockItemForm()
        this.showAnyMandatoryLabelIndicators()
        this.updatePriceAttributeLabel(true)
        break
      case ITEM_TYPES.blueprint:
        this.removeBarcodeScannerField()
        this.removeStockItemForm()
        this.hideAnyMandatoryLabelIndicators()
        this.clearBarcodeField()
        this.updatePriceAttributeLabel(false)
        break
      case ITEM_TYPES.container:
      case ITEM_TYPES.default:
        this.showBarcodeScannerField()
        this.removeStockItemForm()
        this.showAnyMandatoryLabelIndicators()
        this.updatePriceAttributeLabel(false)
        break
      default:
        this.removeStockItemForm()
        this.showAnyMandatoryLabelIndicators()
        this.updatePriceAttributeLabel(false)
    }
  }

  addStockItemForm() {
    const _this = this
    Rails.ajax({
      url: '/stock_items/new',
      type: 'GET',
      success: function(result) {
        _this.stockItemFormTarget.innerHTML = result.body.innerHTML
        showElement(_this.stockItemFormTarget, '', DISPLAY_CLASS)
      },
    })
  }

  removeStockItemForm() {
    this.stockItemFormTarget.innerHTML = ''

    hideElement(this.stockItemFormTarget, DISPLAY_CLASS)
  }

  clearBarcodeField() {
    this.barcodeInputFieldTarget.value = ''
  }

  removeBarcodeScannerField() {
    this.barcodeInputFieldTarget.readOnly = true
    hideElement(this.barcodeExtraDivTarget)
  }

  showBarcodeScannerField() {
    this.barcodeInputFieldTarget.readOnly = false
    showElement(this.barcodeExtraDivTarget)
  }

  hideAnyMandatoryLabelIndicators() {
    const mandatoryLabels = document.querySelectorAll('#custom-attribute-on-root-category .mandatory')
    mandatoryLabels.forEach((mandatoryLabel) => {
      mandatoryLabel.classList.remove('mandatory')
      mandatoryLabel.classList.add('hidden-mandatory')
    })
  }

  showAnyMandatoryLabelIndicators() {
    const mandatoryLabels = document.querySelectorAll('#custom-attribute-on-root-category .hidden-mandatory')
    mandatoryLabels.forEach((mandatoryLabel) => {
      mandatoryLabel.classList.remove('hidden-mandatory')
      mandatoryLabel.classList.add('mandatory')
    })
  }

  updatePriceAttributeLabel(perPiece) {
    let withVatText = window.I18n.t('activerecord.attributes.item.price_with_vat')
    let withoutVatText = window.I18n.t('activerecord.attributes.item.price_without_vat')
    if (perPiece) {
      withVatText = window.I18n.t('activerecord.attributes.item.price_with_vat_stock_item')
      withoutVatText = window.I18n.t('activerecord.attributes.item.price_without_vat_stock_item')
    }
    if (this.hasWithVatPriceLabelTarget) {
      this.withVatPriceLabelTarget.innerHTML = withVatText
    }
    if (this.hasWithoutVatPriceLabelTarget) {
      this.withoutVatPriceLabelTarget.innerHTML = withoutVatText
    }
  }
}
