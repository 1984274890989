import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'counter']
  static values = {
    wrappingElement: { type: String, default: '[]' },
  }

  // Controller to calculate number of checkboxes and how many are checked

  // Add data-controller="checkbox-counter"

  // Add data-checkbox-counter-target='counter' where you want the counter to be placed

  // add 'data-async-script-loader-load-upon-page-load-value' as datamap to call load for page load --> load_async
  // add to checkbox element: data: { 'checkbox-counter-target': 'checkbox', action: 'checkbox-counter#setCounter' }
  // this identifies the checkboxes, and the action will trigger a recount as soon as a checkbox is checked / unchecked

  connect() {
    this.setCounter()
  }

  setCounter() {
    let checkedCounter = 0

    this.checkboxTargets.forEach((element) => {
      if (element.checked) checkedCounter++
    })
    let counterElement = checkedCounter + ' / ' + this.checkboxTargets.length

    if (this.wrappingElementValue.length >= 2) {
      counterElement = this.wrappingElementValue[0] + counterElement + this.wrappingElementValue.at(-1)
    }
    this.counterTarget.innerHTML = counterElement
  }
}
