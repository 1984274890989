import { Controller } from 'stimulus'
import loadImage from 'blueimp-load-image'

export default class extends Controller {
  // to add controller
  // data-controller="load-image"

  // add action on file_filed
  // data-action='change->load-image#readFile'

  // add div where preview should be placed
  // <div class="pb-3 rounded border-0" data-load-image-target='imagePreview' id="photo-preview"></div>

    static targets = ['imagePreview'];

    readFile() {
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader()
        const previewEl = this.imagePreviewTarget
        reader.onload = function(e) {
          loadImage(
            e.target.result,
            function(img, data) {
              if (img.type === 'error') {
                console.error('Error loading image ' + imageUrl)
              } else {
                const photoPreview = previewEl
                photoPreview.innerHTML = ''

                const pElement = document.createElement('p')
                pElement.innerHTML = window.I18n.t('js.preview')
                pElement.className = 'preview-text'
                photoPreview.appendChild(pElement)

                photoPreview.setAttribute('class', 'mt-4')
              }
            },
            {
              orientation: true,
              canvas: true,
              maxHeight: 300,
            },
          )
        }
        reader.readAsDataURL(event.target.files[0])
      }
    }
}
