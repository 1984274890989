import { Controller } from 'stimulus'

// check a form if all required fields have content
// add data-controller='form-readinesss' to the form
// add HTML attribute required to the input fields whch are required
// add data-action='blur->formReadiness#checkFormReadiness'

export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.checkFormReadiness()
  }

  checkFormReadiness() {
    const inputs = this.element.querySelectorAll('input[required]')

    let ready = 0
    inputs.forEach(function(input) {
      if (input.value.trim().length > 0) { ready += 1 }
    })
    if (ready == inputs.length) { this.enableStartNowButton() } else { this.disableStartNowButton() }
  }

  enableStartNowButton() {
    this.submitTarget.removeAttribute('disabled')
    this.submitTarget.classList.remove('disabled')
  }

  disableStartNowButton() {
    this.submitTarget.setAttribute('disabled', true)
    this.submitTarget.classList.add('disabled')
  }
}
