import { Controller } from 'stimulus'
import Swal from 'sweetalert2'
import SwalStyles from '../utils/swal_styles'

export default class extends Controller {
  // data-controller='nested-form'

  /* eslint-disable */
  // If you always want to show a empty record, add the following on the level of data-controller
  // data-controller='nested-form' data-nested-form-new-record-on-load-value='true'
  // data-nested-form-insert-position-value": "beforeend" --> to alter the insert position for 'insertAdjacentHTML'
  // Default: beforebegin, alternative allowed values: 'afterbegin', 'beforeend', 'afterend'

  // Add a template. This is the blueprint, which will be copied in case of action addAssociation
  // <template data-nested-form-target="template"> %>
  //     <%#= f.fields_for :time_series, @resource.time_series.new(time_series_type: time_serie), child_index: "NEW_RECORD" do |ff| %>
  //         <%#= render partial: 'inventory/items/partials/form_usage_data', locals: {time_serie_type: time_serie, f: ff,} %>
  //     <%# end %>
  // </template>

  // The nested fields must be in a wrapper. Add this to the wrapper %>
  // <div class="nested-fields" data-new-record="<#%= f.object.new_record? %>"">...</div> %>

  // Add a link to add associations
  // <%#= link_to "Neuer Eintrag", "#", data: {action: "click->nested-form#addAssociation"} %>

  // Add data-nested-form-target: "insert" where you want the item inserted %>

  // Add a link to remove an association %>
  // <%#= link_to "<em class='fa-1x ml-2 fa-light fa-trash-can'></em>".html_safe, "#", data: { action: "click->nested-form#removeAssociation" } %>
  /* eslint-enable */

  static targets = ['insert', 'template', 'form', 'emptyMessage', 'accountNumber']
  static values = {
    newRecordOnLoad: Boolean,
    executeJs: String,
    insertPosition: { type: String, default: 'beforebegin' },
  }
  connect() {
    if (this.newRecordOnLoadValue) {
      this.addAssociation(null)
    }
  }

  addAssociation(event) {
    if (event instanceof Event) {
      event.preventDefault()
    };

    if (this.hasEmptyMessageTarget) {
      this.emptyMessageTarget.classList.add('d-none')
    }

    if (this.hasAccountNumberTarget) {
      this.accountNumberTarget.classList.remove('d-none')
    }

    // set default insert position to 'beforebegin'
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.insertTarget.insertAdjacentHTML(this.insertPositionValue, content)
    // If it is required, that after loading an association some
    // javascript needs to be executed (e.g. for a flatpickr)
  }

  removeAssociation(event) {
    event.preventDefault()
    const wrapper = event.target.closest('.nested-fields')
    console.log(wrapper.children)
    const destroyInput = wrapper.querySelector('input[name*=\'_destroy\']')
    // JSONB fields don't require a name*='_destroy field.
    // If field is not set, just remove wrapper
    if (destroyInput && wrapper.dataset.newRecord != 'true') {
      destroyInput.value = 1
      wrapper.style.opacity = 0
      wrapper.dataset.itemId = ''
    } else {
      wrapper.remove()
    }
  }

  removeAssociationWConfirm(event) {
    self = this
    event.preventDefault()
    Swal.fire({
      title: window.I18n.t('js.are_you_sure'),
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: window.I18n.t('js.abort'),
      icon: 'question',
      iconHtml: '<div class="tw-text-3xl">?</div>',
      customClass: SwalStyles,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        self.removeAssociation(event)
      }
    })
  }

  removeAsociationWConfirmAndSubmit(event) {
    self = this
    event.preventDefault()
    Swal.fire({
      title: window.I18n.t('js.are_you_sure'),
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'abbrechen',
      icon: 'question',
      iconHtml: '<div class="tw-text-3xl">?</div>',
      customClass: SwalStyles,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        self.removeAssociation(event)
        Rails.fire(self.formTarget, 'submit')
      }
    })
  }
}
