import { Controller } from 'stimulus'
import { Swal } from 'sweetalert2/dist/sweetalert2.js'
import SwalStyles from '../utils/swal_styles'
export default class extends Controller {
  static targets = ['show', 'form', 'warnMessage']
  static values = {
    showForm: Boolean,
    warnMessage: String,
  }
  // data-controller="toggle-input" to initialize the data controller
  // <div data-toggle-input-target="show"> for the value display / no input box

  // Mark the form as follows. The form will be hidden automatically --> class: 'd-none'
  // data-toggle-input-target="form" on the input toggle box.

  // Element to switch from show to the form
  // data-action="click->toggle-input#toggle"

  // data-toggle-input-show-form="true/false"

  // to provide a warn message on clicking toggle, extend the data-action with "click->toggle-input#warn:once"
  // sample: data-action="click->toggle-input#toggle click->toggle-input#warn:once"
  // You then must provide a message using the value API of stimulus as such:
  // data-toggle-input-warn-mnessage-value='Add your warn message here'

  connect() {
    if (!this.hasFormTarget) return

    if (this.hasShowTarget) {
      this.formTarget.classList.add('d-none')
    } else {
      this.formTarget.classList.remove('d-none')
    }
  }


  toggle(event) {
    let i
    event.preventDefault()

    if (this.showFormValue) {
      this.showFormValue = false
      for (i = 0; i < this.showTargets.length; i++) {
        this.showTargets[i].classList.remove('d-none')
      }

      if (this.hasFormTarget) {
        this.formTarget.classList.add('d-none')
      };
    } else {
      this.showFormValue = true
      for (i = 0; i < this.showTargets.length; i++) {
        $
        this.showTargets[i].classList.add('d-none')
      }
      if (this.hasFormTarget) {
        this.formTarget.classList.remove('d-none')
      }
    }
  }

  toggleFromCheckbox(event) {
    if (this.checkboxTarget.checked == true) {
      this.formTarget.classList.remove('d-none')
      this.formTarget.focus()
    } else {
      this.formTarget.classList.add('d-none')
    }
  }

  warn() {
    if (!this.hasWarnMessageValue) throw new Error('No Warn Message provided')

    Swal.fire({
      title: this.warnMessageValue,
      icon: 'warning',
      iconHtml: '<div class="tw-text-3xl">!</div>',
      customClass: SwalStyles,
    })
  }
}
