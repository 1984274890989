import { Controller } from 'stimulus'

export default class extends Controller {
  // data-controller='selected'

  // Add the following to the elements, which can be selected
  // data-selected-selected-class-value='#CSS-CLASS-FOR-SELECTED-ELEMENT#'
  // data-selected-target="option"

  // data-action="click->selected#changeSelected"


    static targets = ['option']

    static values = {
      selectedClass: String, // css class to add to selected element
    }

    changeSelected(event) {
      this.optionTargets.forEach(function(element) {
        if (element.classList.contains(this.selectedClassValue)) {
          element.classList.remove(this.selectedClassValue)
        }
      }.bind(this))
      event.currentTarget.classList.add(this.selectedClassValue)
    }
}
