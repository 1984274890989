import { Controller } from 'stimulus'

const { IPINFO_API_KEY } = window

export default class extends Controller {
  static targets = ['countryCodeSelect', 'countrySelect', 'countryCodeInput', 'phoneInput']

  connect() {
    this.fetchUserIP()
  }

  fetchUserIP() {
    const url = 'https://ipinfo.io?token=' + IPINFO_API_KEY
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const selectElement = document.getElementById('country_code_helper__country_code')
        selectElement.value = data.country
        selectElement.dispatchEvent(new Event('change'))
        document.getElementById('onboarding_user_ip').value = data.ip
      })
  }

  changeValueOfCountryCodeSelect(event) {
    if (this.countrySelectTarget.value != '') {
      this.countryCodeSelectTarget.value = this.countrySelectTarget.value
      this.countryCodeSelectionChange()
    }
  }

  countryCodeSelectionChange() {
    const selectedOptionText = this.countryCodeSelectTarget.options[this.countryCodeSelectTarget.selectedIndex]

    if (this.countrySelectTarget.value == '') { this.countrySelectTarget.value = selectedOptionText.value }

    const regexResult = selectedOptionText.text.match(/\+\s*\d+/)
    if (regexResult) { this.countryCodeInputTarget.value = regexResult[0] }
  }

  // if area_code helper field is empty, open up select box
  // if country_code has already been selected, set focus on phone input field
  redirectFocus() {
    this.phoneInputTarget.focus()
  }
}
