import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['multiplier', 'result', 'requiredField']

    multiplierChange() {
      const multiplier1 = this.multiplierTargets[0].value
      const multiplier2 = this.multiplierTargets[1].value
      const result = this.resultTarget.value

      if (this.isNumeric(multiplier1) && this.isNumeric(multiplier2)) {
        this.resultTarget.value = multiplier1 * multiplier2
      } else if (this.isNumeric(multiplier1) && this.isNumeric(result)) {
        this.multiplierTargets[1].value = result / multiplier1
      } else if (this.isNumeric(this.multiplierTargets[1].value) && this.isNumeric(result)) {
        this.multiplierTargets[0].value = result / multiplier2
      }
      this.requiredFields()
    }

    resultChange() {
      const multiplier1 = this.multiplierTargets[0].value
      const multiplier2 = this.multiplierTargets[1].value
      if (this.isNumeric(multiplier1) && this.isNumeric(multiplier2)) {
        this.multiplierTargets[0].value = this.multiplierTargets[1].value = ''
        this.requiredFields()
      } else {
        this.multiplierChange()
      }
    }

    requiredFields() {
      if (this.hasRequiredFieldTarget) {
        for (let i = 0; i < this.requiredFieldTargets.length; i++) {
          if (this.requiredFieldTargets[i].value == '') {
            this.requiredFieldTargets[i].setAttribute('style', 'background-color: yellow')
          } else {
            this.requiredFieldTargets[i].setAttribute('style', 'background-color: inherit')
          }
        }
      }
    }

    isNumeric(num) {
      return (!isNaN(num) && num != '')
    }
}
