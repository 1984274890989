const SwalStyles = {
  header: '!tw-flex !tw-flex-row !tw-justify-center sm:!tw-justify-start',
  content: `!tw-mt-2 !tw-text-center sm:!tw-text-left !tw-text-sm !tw-text-slate-500
    !tw-pl-4 sm:!tw-pl-[68px] !tw-pr-4 !tw-pb-4 sm:!tw-pr-6 sm:!tw-pb-4 sm:!tw-ml-4
    !tw-col-start-1 sm:!tw-col-start-2 !tw-col-end-3`,
  popup: `!tw-relative !tw-transform !tw-overflow-hidden !tw-rounded-lg !tw-bg-white !tw-text-left 
    !tw-shadow-xl !tw-transition-all sm:!tw-my-8 sm:!tw-w-full sm:!tw-max-w-lg !tw-p-0 !tw-grid-cols-none`,
  icon: `!tw-m-0 !tw-flex !tw-h-12 !tw-w-12 !tw-flex-shrink-0 !tw-items-center !tw-justify-center
    !tw-rounded-full !tw-border-0 !tw-bg-red-100 sm:!tw-h-10 sm:!tw-w-10 !tw-mt-5 !tw-sm!mt-6 !tw-col-start-1
    !tw-col-end-3 sm:!tw-col-end-2`,
  title: `tw-p-0 !tw-pt-6 !tw-text-center sm:!tw-text-left !tw-text-base !tw-font-semibold
    !tw-leading-6 !tw-text-slate-900 !tw-pl-4 !tw-pr-4 sm:!tw-pr-6 sm:!tw-pl-0 sm:!tw-ml-4
    !tw-col-start-1 sm:!tw-col-start-2 !tw-col-end-3`,
  actions: `tw-bg-gray-50 !tw-px-4 !tw-py-3 sm:!tw-flex sm:!tw-flex-row sm:!tw-px-6 !tw-w-full
    !tw-justify-end !tw-mt-0 !tw-col-start-1 !tw-col-end-3`,
  confirmButton: `tw-inline-flex !tw-w-full !tw-justify-center !tw-rounded-md !tw-bg-red-600 !tw-px-3 !tw-py-2
    !tw-text-sm !tw-font-semibold !tw-text-white !tw-shadow-sm hover:!tw-bg-red-500 !tw-mt-2 sm:!tw-mt-0
    sm:!tw-ml-3 sm:!tw-w-auto`,
  cancelButton: `tw-mt-3 !tw-inline-flex !tw-w-full !tw-justify-center !tw-rounded-md !tw-bg-white !tw-px-3
    !tw-py-2 !tw-text-sm !tw-font-semibold !tw-text-slate-900 !tw-shadow-sm !tw-ring-1 !tw-ring-inset !tw-ring-gray-300
    hover:!tw-bg-gray-50 sm:!tw-mt-0 sm:!tw-w-auto`,
}

export default SwalStyles
