import { Controller } from 'stimulus'

// Add the following to outer div
// data-controller="backend-search"
// Add the following to search form
// data-backend-search-target='searchForm'
// Add the following to inputs which should trigger form reload
// data-action='input->backend-search#changeInputField'
export default class extends Controller {
  static targets = ['searchForm']

  changeInputField(_e) {
    clearTimeout(this.inputTimeout)
    this.inputTimeout = setTimeout(() => {
      this.search()
    }, 500)
  }

  search() {
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => {
      Rails.fire(this.searchFormTarget, 'submit')
    }, 500)
  }
}
