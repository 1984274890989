import { Controller } from 'stimulus'
import swal from 'sweetalert2'
import { each, upperFirst, camelCase } from 'lodash'
import SwalStyles from '../utils/swal_styles'

import { setValueSelectTag } from '../utils/html_element_helpers'

const ACCESS_PERMISSION = {
  admin: 'admin',
  none: '',
}

const setAndDisableSelectElement = function(target, value, disabled) {
  setValueSelectTag(target, value)

  target.disabled = disabled
}

const setPermissionHiddenInput = function(target, value) {
  target.value = value
}

export default class extends Controller {
  static targets = ['userTypeCheckbox', 'inventoryModuleSelect', 'personnelModuleSelect',
    'inventoryModuleHidden', 'personnelModuleHidden']
  static values = { personnelPrevstate: String, inventoryPrevstate: String, changeOwnSettings: Boolean }

  notifyChangingOwnSettingsPermission() {
    if (!this.changeOwnSettingsValue) return

    swal.fire({
      title: window.I18n.t('js.are_you_sure'),
      icon: 'warning',
      iconHtml: '<div class="tw-text-3xl">!</div>',
      text: window.I18n.t('js.own_permission_change_msg'),
      customClass: SwalStyles,
    })
  }

  toggleAdminPermission() {
    if (!this.hasUserTypeCheckboxTarget) return

    const _this = this
    const selectTargetNames = ['inventoryModule', 'personnelModule']

    if (this.userTypeCheckboxTarget.checked) {
      each(selectTargetNames, function(targetName) {
        if (_this['has' + upperFirst(camelCase(targetName)) + 'SelectTarget']) {
          setAndDisableSelectElement(_this[targetName + 'SelectTarget'], ACCESS_PERMISSION['admin'], true)
          setPermissionHiddenInput(_this[targetName + 'HiddenTarget'], ACCESS_PERMISSION['admin'])
        }
      })
    } else {
      each(selectTargetNames, function(targetName) {
        const moduleName = targetName.match(/(.*)Module/)[1]
        if (_this['has' + upperFirst(camelCase(targetName)) + 'SelectTarget']) {
          setAndDisableSelectElement(_this[targetName + 'SelectTarget'], _this[moduleName + 'PrevstateValue'], false)
          setPermissionHiddenInput(_this[targetName + 'HiddenTarget'], _this[moduleName + 'PrevstateValue'])
        }
      })
    }
  }
}
