import { Controller } from 'stimulus'

export default class extends Controller {
  // on the designated input field add the following to the input

  // FOR INTEGER
  // data: { controller: 'number-input-validation',
  //         action: 'keypress->number-input-validation#validIntegerInput'}

  // FOR FLOAT
  // data: { controller: 'number-input-validation',
  //         action: 'keypress->number-input-validation#validFloatInput'}

  validIntegerInput(event) {
    const charCode = (event.which) ? event.which : event.keyCode

    // check if any character is or character 0 (carcode 48) or value 9 (charcode 57)
    // or is value of value '-' if it's the first character
    if (this.numbers(charCode) || this.minusAsFirstCharacter(charCode)) return


    event.preventDefault()
  }

  validFloatInput(event) {
    if (this.element.value.includes('.') || this.element.value.includes(',') ) {
      this.validIntegerInput(event)
    } else {
      const charCode = (event.which) ? event.which : event.keyCode
      // check if any character is or character 0 (carcode 48) or value 9 (charcode 57)
      // or is value of value '-' if it's the first character
      if (this.numbers(charCode) || this.minusAsFirstCharacter(charCode) ||
             ((charCode == 46 || charCode == 44) && event.target.value.length > 0)) {
        return
      }

      event.preventDefault()
    }
  }

  validPhoneNumberInput(event) {
    const charCode = (event.which) ? event.which : event.keyCode
    if (this.numbers(charCode) ||
         (this.whiteSpace(charCode) && event.target.value.length > 0) ||
         this.plusSign(charCode) && event.target.value.length == 0) {
      return
    }

    event.preventDefault()
  }

  whiteSpace(charCode) {
    return charCode == 32
  }

  plusSign(charCode) {
    return charCode == 43
  }

  numbers(charCode) {
    return charCode >= 48 && charCode <= 57
  }

  minusAsFirstCharacter(charCode) {
    return charCode == 45 && event.target.value.length == 0
  }
}
