import { Controller } from 'stimulus'
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = [
    'searchInput',
    'resourceIdField',
    'resourceTypeField',
    'selectedItems',
    'submitButton',
    'canvas',
    'hashSignature',
    'statusField',
    'assignedFilterText',
    'fromTicket',
  ]

  connect() {
    if (this.hasCanvasTarget) {
      this.initCanvas()
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeCanvas)
  }

  initCanvas() {
    this.resizeCanvas()

    window.addEventListener(
      'resize',
      function() {
        this.resizeCanvas()
      }.bind(this),
    )

    this.signaturePad = new SignaturePad(this.canvasTarget, {
      // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
      backgroundColor: 'rgb(255, 255, 255)',
    })
    this.signaturePad.addEventListener('afterUpdateStroke', () => {
      this.enableButtons()
    })
  }

  enableButtons() {
    document.getElementById('signature_reset_btn').disabled = false
    document.getElementById('signature_submit_btn').disabled = false
    document.getElementById('signature_submit_btn_2').disabled = false
  }

  disableButtons() {
    document.getElementById('signature_reset_btn').disabled = true
    document.getElementById('signature_submit_btn').disabled = true
    document.getElementById('signature_submit_btn_2').disabled = true
  }

  cleanCanvas(e) {
    e.preventDefault()
    this.signaturePad.clear()
    this.disableButtons()
  }

  saveSignature(e) {
    e.preventDefault()
    if (this.signaturePad.isEmpty()) {
      return
    }
    const data = this.signaturePad.toDataURL('image/png')
    this.hashSignatureTarget.value = data
    if (this.hasFromTicketTarget) {
      document.getElementById('ticket_complete').value = 1
      document.getElementById('signature_image').value = data
    }
    document.getElementById('signature_update_form').requestSubmit()
  }

  rejectEmailSignature() {
    this.statusFieldTarget.value = 'rejected'
    document.getElementById('signature_update_form').requestSubmit()
  }

  saveEmailSignature() {
    document.getElementById('signature_update_form').requestSubmit()
  }

  resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio = Math.max(window.devicePixelRatio || 2, 2)
    this.canvasTarget.width = Math.min(
      500,
      this.canvasTarget.offsetWidth * ratio,
    )
    this.canvasTarget.height = Math.min(
      330,
      this.canvasTarget.offsetHeight * ratio,
    )
    this.canvasTarget.getContext('2d').scale(1, 1)
  }

  changeInputField(e) {
    clearTimeout(this.inputTimeout)
    this.inputTimeout = setTimeout(() => {
      this.search()
    }, 500)
  }

  setAssignFilter(e) {
    e.preventDefault()
    if (this.filterType !== e.params.filterType) {
      this.filterType = e.params.filterType
      this.assignedFilterTextTarget.innerText = e.params.filterTypeTitle
      this.search()
    }
  }

  toggleSelectCheckoutableItem(e) {
    const size = this.selectedItemsTargets.filter((element) => {
      return element.checked
    }).length
    const responsibleInput = document.querySelector(
      '#signature_responsible_to_sign',
    )
    if (responsibleInput) {
      this.submitButtonTarget.disabled =
        size === 0 || responsibleInput.value.trim() === ''
    } else {
      this.submitButtonTarget.disabled = size === 0
    }
  }

  toggleSelectAll(e) {
    const target = e.currentTarget
    this.selectedItemsTargets.forEach((element) => {
      element.checked = target.checked
    })

    const responsibleInput = document.querySelector(
      '#signature_responsible_to_sign',
    )
    if (responsibleInput) {
      this.submitButtonTarget.disabled =
        !target.checked || responsibleInput.value.trim() === ''
    } else {
      this.submitButtonTarget.disabled = !target.checked
    }
  }

  search() {
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => {
      const params = {
        resource_id: this.resourceIdFieldTarget.value,
        resource_type: this.resourceTypeFieldTarget.value,
        q: this.searchInputTarget.value,
        filter_type: this.filterType,
      }
      const queryString = Object.keys(params)
        .map(function(key) {
          return key + '=' + params[key]
        })
        .join('&')
      const url = '/signatures/search_checkoutable_items?' + queryString
      Turbo.visit(url, { frame: 'signature_checkoutable_items' })
    }, 500)
  }
}
