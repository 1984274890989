import { Controller } from 'stimulus'
import { createPopup } from '@picmo/popup-picker'

export default class extends Controller {
  static targets = ['textArea', 'pickerContainer']

  connect() {
    this.editor = this.textAreaTarget.editor
    this.picker = createPopup(
      {
        rootElement: this.pickerContainerTarget,
        className: 'z-1',
      },
      {
        triggerElement: this.textAreaTarget,
        referenceElement: this.textAreaTarget,
      },
    )

    this.picker.addEventListener('emoji:select', (event) => {
      this.textAreaTarget.editor.insertString(event.emoji)
    })

    this.textAreaTarget.addEventListener('trix-change', this.activateEmojiPicker.bind(this))
  }

  activateEmojiPicker(event) {
    const commentString = event.target.value
    // emoji is activated when the user writes a colon :
    // it also checks that the colon has no letter before it like word:
    // Due to active text ending all content with </div> we have this weird offset -8, -6
    // We also delete the colon before inserting the emoji
    const currentText = commentString.slice(-8, -6)
    const position = this.editor.getPosition()
    if (currentText == ' :' || currentText == '>:') {
      this.editor.setSelectedRange([position - 1, position])
      this.editor.deleteInDirection('backward')
      this.picker.toggle()
    }
  }

  disconnect() {
    this.picker.destroy()
    this.textAreaTarget.removeEventListener('trix-change', this.activateEmojiPicker)
  }
}
