import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import Papa from 'papaparse'
import swal from 'sweetalert2'
import SwalStyles from '../utils/swal_styles'

export default class extends Controller {
  static targets = ['dropzone', 'uploadElement', 'dropzoneArea',
    'tabledIcon', 'fileNameElement', 'fileUploadIcon', 'importObjectField',
    'mappingTable', 'fileUploadButton', 'importFinished',
  ]
  rowNumbers = 3

  dropzoneTargetConnected(element) {
    this.dropzone = new Dropzone(element, {
      autoQueue: false, // <- this is important!
      autoProcessQueue: false,
      url: '/',
      clickable: true,
      uploadMultiple: false,
      previewsContainer: false,
      addedfile: (file) => {
        this.validateAndParseFile(file)
      },
      dragstart: (event) => {
        this.dropzoneAreaTarget.classList.remove('tm-hidden')
      },
      dragover: (event) => {
        this.dropzoneAreaTarget.classList.remove('tw-hidden')
      },
      dragleave: (event) => {
        this.dropzoneAreaTarget.classList.add('tw-hidden')
      },
      drop: (event) => {
        event.preventDefault()
        this.dropzoneAreaTarget.classList.add('tw-hidden')
      },
    })
  }

  dropzoneTargetDisconnected(element) {
    if (this.dropzone) {
      this.dropzone.destroy()
    }
  }

  uploadFile(event) {
    const file = event.srcElement.files[0]
    this.validateAndParseFile(file)
  }

  validateAndParseFile(file) {
    const acceptedTypes = ['text/csv']
    if (!acceptedTypes.includes(file.type)) {
      swal.fire({
        title: window.I18n.t('js.information'),
        icon: 'warning',
        iconHtml: '<div class="tw-text-3xl">!</div>',
        text: window.I18n.t('js.support_csv_file_only'),
        customClass: SwalStyles,
      })
    } else {
      this.parseCsvFile(file)
    }
  }

  parseCsvFile(file) {
    this.originFile = file
    Papa.parse(file, { header: false, skipEmptyLines: true, complete: (results) => {
      const rows = results.data
      const headers = rows[0] // Extracting headers from the first row
      const data = rows.slice(1) // Row's data now starts from the second line

      const customResults = {
        data: data,
        meta: {
          delimiter: results.meta.delimiter,
          linebreak: results.meta.linebreak,
          fields: headers,
        },
      }
      this.showFileData(file, customResults)
    } })
  }

  dropResults(e) {
    this.parseResults = undefined
    if (this.fetchInterval) { clearInterval(this.fetchInterval) }
    this.showContent('1')
  }

  populateTable(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.fetchInterval) { clearInterval(this.fetchInterval) }

    if (this.hasDropzoneAreaTarget) {
      if (!this.parseResults) { return this.showNotification('no_file_was_uploaded', 'danger') }

      if (this.parseResults.errors && this.parseResults.errors.length > 0) {
        return this.showNotification('cannot_parse_file', 'danger')
      }
    }

    this.sendTableData()
  }

  sendTableData() {
    if (this.hasDropzoneAreaTarget) {
      const importObjectValue = this.importObjectFieldTarget.value
      const parseResults = this.parseResults
      const parsedData = parseResults.data.slice(0, this.rowNumbers)

      const formData = new FormData()

      formData.append('import_object', importObjectValue)
      formData.append('data', JSON.stringify(parsedData))
      formData.append('meta', JSON.stringify(parseResults.meta))
      formData.append('file', this.originFile)
      formData.append('rows', this.parseResults.data.length)

      const href = '/csv_imports/data_mapping?step=2'

      fetch(href, {
        method: 'post',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
        },
        body: formData,
      })
        .then((r) => r.text())
        .then((html) => Turbo.renderStreamMessage(html))
    } else {
      this.showContent('2')
    }
  }

  showFileData(file, results) {
    this.showFileName(file)
    this.parseResults = results
  }

  showFileName(file) {
    this.fileUploadIconTarget.classList.add('d-none')
    this.fileNameElementTarget.textContent = file.name
    this.fileNameElementTarget.classList.remove('d-none')
    this.tabledIconTarget.classList.remove('d-none')
    this.fileUploadButtonTarget.textContent = window.I18n.t('js.csv_imports.upload_another')
  }

  showContent(step) {
    const href = `/csv_imports/show_content?step=${step}`

    fetch(href, {
      method: 'get',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  nextStep(e) {
    const currentStep = parseInt(e.target.dataset.currentStep)

    const progressLinks = document.querySelectorAll('nav[aria-label="Progress"] a')

    if ( currentStep == 1 ) {
      if (!this.parseResults) { return this.showNotification('no_file_was_uploaded', 'danger') }
    } else if ( currentStep == 4 ) {
      return window.location.href = '/items'
    }
    progressLinks[currentStep].click()
  }

  backStep(e) {
    const currentStep = parseInt(e.target.dataset.currentStep)

    const progressLinks = document.querySelectorAll('nav[aria-label="Progress"] a')
    progressLinks[currentStep - 2].click()
  }

  async storeMappingTableData(e) {
    if (e.currentTarget.dataset.disabled == 'true') {
      e.preventDefault()
      return
    }

    if (this.hasMappingTableTarget) {
      const formData = new FormData(this.mappingTableTarget)
      formData.append('step', '3')
      formData.delete('format')

      const response = await this.validateForm(formData)
      const jsonResponse = await response.json()

      if (!jsonResponse.valid) {
        window.$.notify(jsonResponse.errors, 'danger')
        return
      }

      this.showNotification('dry_run', 'info')

      const href = this.mappingTableTarget.action

      fetch(href, {
        method: 'post',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
        },
        body: formData,
      })
        .then((r) => r.text())
        .then((html) => {
          Turbo.renderStreamMessage(html)
          this.fetchResults('/csv_imports/dry_run_results')
        })
    } else {
      this.showContent('3')
    }
  }

  async validateForm(data) {
    const href = '/csv_imports/validate'

    return fetch(href, {
      method: 'post',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: data,
    })
  }

  fetchResults(href) {
    if (this.fetchInterval) { clearInterval(this.fetchInterval) }

    this.fetchInterval = setInterval(() => {
      fetch(href, {
        method: 'GET',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
        } })
        .then((r) => r.text())
        .then((html) => {
          if (html !== '') {
            Turbo.renderStreamMessage(html)
            if (this.hasImportFinishedTarget) { clearInterval(this.fetchInterval) }
          }
        })
    }, 2000)
  }

  showConfirmation(e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.currentTarget.dataset.disabled == 'true') { return }

    swal.fire({
      title: window.I18n.t('js.warning'),
      html: window.I18n.t('js.csv_imports.confirm_import'),
      icon: 'warning',
      iconHtml: '<div class="tw-text-3xl">!</div>',
      showCancelButton: true,
      reverseButtons: true,
      buttonsStyling: false,
      customClass: SwalStyles,
    }).then((result) => {
      if (result.isConfirmed) {
        this.startImport()
      }
    })
  }

  startImport() {
    const href = '/csv_imports/start_import?step=4'

    fetch(href, {
      method: 'post',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      redirect: 'manual',
    })
      .then((response) => {
        if (response.status === 0) {
          window.location.href = '/csv_imports'
        }
        return response.text()
      })
      .then((html) => {
        Turbo.renderStreamMessage(html)
        this.fetchResults('/csv_imports/import_results')
      })
  }

  showNotification(action, type) {
    window.$.notify(window.I18n.t(`js.csv_imports.${action}`), type)
  }
}
