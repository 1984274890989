import { Controller } from 'stimulus'
import swal from 'sweetalert2'
import SwalStyles from '../utils/swal_styles'

// Connects to data-controller="fluke"
export default class extends Controller {
  static targets = ['fileUploadIcon', 'tabledIcon', 'fileNameElement',
    'fileUploadButton', 'fileInput', 'resultsPage',
  ]

  validateFile(e) {
    e.preventDefault()
    e.stopPropagation()

    if (!this.hasFileInputTarget) { return this.showContent(e) }

    const ticketTypeElements = document.querySelectorAll('input[name="ticket_type[ids][]"]')
    const ticketTypeIds = Array.from(ticketTypeElements).map((element) => element.value)
    const file = this.fileInputTarget.files[0]

    if (ticketTypeIds.length < 1 || !file) {
      return this.showNotification('not_enough_data', 'danger')
    }
    this.submitFile(ticketTypeIds, file)
  }

  submitFile(ids, file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('step', '2')
    ids.forEach((id) => formData.append('ticket_type_ids[]', id))

    const href = '/fluke'

    fetch(href, {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: formData,
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  showContent(e) {
    const href = e.target.closest('a').href

    fetch(href, {
      method: 'GET',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  importFile(e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.currentTarget.dataset.disabled == 'true') { return }
    if (this.hasResultsPageTarget) { return }

    swal.fire({
      title: window.I18n.t('js.warning'),
      html: window.I18n.t('js.csv_imports.confirm_import'),
      icon: 'warning',
      iconHtml: '<div class="tw-text-3xl">!</div>',
      showCancelButton: true,
      reverseButtons: true,
      buttonsStyling: false,
      customClass: SwalStyles,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('HOOORAY CONFIRMED')
        this.startImport()
      }
    })
  }

  nextStep(e) {
    const currentStep = parseInt(e.target.dataset.currentStep)

    const progressLinks = document.querySelectorAll('nav[aria-label="Progress"] a')

    progressLinks[currentStep].click()
  }

  backStep(e) {
    const currentStep = parseInt(e.target.dataset.currentStep)

    const progressLinks = document.querySelectorAll('nav[aria-label="Progress"] a')
    progressLinks[currentStep - 2].click()
  }

  startImport() {
    const formData = new FormData()
    formData.append('step', '3')

    const href = '/fluke/import'

    fetch(href, {
      method: 'POST',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: formData,
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  uploadFile(e) {
    this.showFileName(e.srcElement.files[0])
  }

  showFileName(file) {
    this.fileUploadIconTarget.classList.add('d-none')
    this.fileNameElementTarget.textContent = file.name
    this.fileNameElementTarget.classList.remove('d-none')
    this.tabledIconTarget.classList.remove('d-none')
    this.fileUploadButtonTarget.textContent = window.I18n.t('js.csv_imports.upload_another')
  }

  showNotification(action, type) {
    window.$.notify(window.I18n.t(`js.fluke.${action}`), type)
  }
}
