import { Controller } from 'stimulus'
import { ScreenNotification } from '../library/screen_notification'

const UNCONFIRMED = 'unconfirmed'
const DONT_HAVE = 'dont_have'
export default class extends Controller {
  static targets = ['commentField', 'status', 'stockQuantityField', 'changeQuantity']

  static values = {
    status: String,
    inventoryTakeId: Number,
    leftForReturnQuantity: Number,
    isGeneralInventory: Boolean,
  }

  connect() {
    document.addEventListener('keydown', function(e) {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    })
    this.screenNotification = new ScreenNotification()
  }

  confirmMissingTakeItem(e) {
    e.preventDefault()
    e.stopPropagation()
    const currentTarget = e.currentTarget
    const type = currentTarget.dataset['actionType']
    if ([UNCONFIRMED, DONT_HAVE].includes(type)) {
      if (this.hasCommentFieldTarget && !this.commentFieldTarget.checkValidity()) {
        return this.commentFieldTarget.reportValidity()
      }
    }
    this.statusTarget.value = type
    Rails.fire(e.currentTarget.closest('form'), 'submit')
  }

  confirmStockQuantity(e) {
    e.preventDefault()
    e.stopPropagation()
    const currentTarget = e.currentTarget
    const type = currentTarget.dataset['actionType']

    if (this.stockQuantityFieldTarget && this.stockQuantityFieldTarget.value.includes('-')) {
      this.screenNotification.notify(window.I18n.t('no_negative_numbers_for_stocks'), 'error')
      this.stockQuantityFieldTarget.style.border = '2px solid red'
      setTimeout(() => {
        this.stockQuantityFieldTarget.style.border = ''
      }, 5000)
      return
    }

    if (this.stockQuantityFieldTarget && !this.stockQuantityFieldTarget.checkValidity()) {
      return this.stockQuantityFieldTarget.reportValidity()
    }

    if (this.hasCommentFieldTarget && !this.commentFieldTarget.checkValidity()) {
      return this.commentFieldTarget.reportValidity()
    }

    this.statusTarget.value = type
    currentTarget.disabled = true
    $.notify(window.I18n.t('saved_successfully'), 'success')
    Rails.fire(e.currentTarget.closest('form'), 'submit')
  }

  disablePaste(event) {
    event.preventDefault()
  }

  calculateQuantityDifference(event) {
    if (this.hasChangeQuantityTarget) {
      const differenceInQuantity = event.target.value - this.leftForReturnQuantityValue

      this.commentFieldTarget.required = true

      if (differenceInQuantity > 0) {
        this.changeQuantityTarget.value = `+${differenceInQuantity}`
        this.changeQuantityTarget.classList.add('text-success')
      } else if (differenceInQuantity < 0) {
        this.changeQuantityTarget.value = differenceInQuantity
        this.changeQuantityTarget.classList.add('text-danger')
      } else {
        this.commentFieldTarget.required = false
        this.changeQuantityTarget.value = 0
        this.changeQuantityTarget.classList.remove('text-success', 'text-danger')
      }
    }
  }

  fetchExpectedQuantityForStockTaking(event) {
    const inventoryTakeItemId = event.target.value // Get selected item from dropdown

    const selectedOption = event.target.options[event.target.selectedIndex]
    const inventoryTakeId = selectedOption.getAttribute('data-inventory-take-id')

    const generalInventory = this.isGeneralInventoryValue

    const baseUrl = '/inventory_take_item_details/stock_item_stocktaking_form'

    const inventoryTakeIdText = `inventory_take_id=${inventoryTakeId}`
    const inventoryTakeItemIdText = `inventory_take_item_id=${inventoryTakeItemId}`
    const generalInventoryParam = `general_inventory=${generalInventory}`
    const url = `${baseUrl}?${inventoryTakeIdText}&${inventoryTakeItemIdText}&${generalInventoryParam}`

    fetch(url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  openBarcodeScanner(event) {
    const url = event.currentTarget.dataset.url

    Rails.ajax({
      url: url,
      type: 'get',
    })
  }
}
