import { Controller } from 'stimulus'
import { setCookie, getCookie } from '../utils/cookies_helper'
export default class extends Controller {
  static targets = ['email', 'form', 'passwordInput', 'rememberMeBtn',
    'forgotPasswordBtn', 'loginLink',
  ]

  static values = {
    loginType: String,
  }

  connect() {
    this.hideOrShowFields()
  }

  hideOrShowFields() {
    const url = new URL(window.location.href)
    const useOtp = url.searchParams.get('useOtp')
    if (getCookie('sso') === 'true' || useOtp) {
      this.ssoFields()
    } else {
      this.defaultLoginFields()
    }
  }

  idpDiscoveryRequest() {
    if (this.validEmail(this.emailTarget.value)) {
      const _this = this
      const data = { user: { email: this.emailTarget.value } }
      Rails.ajax({
        url: '/auth/sso/idp_discovery',
        type: 'post',
        headers: { 'Content-Type': 'application/json' },
        beforeSend(xhr, options) {
          xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
          /* eslint-disable */
            // Workaround: add options.data late to avoid Content-Type header to already being set in stone
            // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
            /* eslint-enable */
          options.data = JSON.stringify(data)
          return true
        },
        success: function(data) {
          if (data.tenant_id) {
            _this.ssoFields()
            setCookie('sso', true, 30)
          } else {
            _this.defaultLoginFields()
            setCookie('sso', '', 0)
          }
        },
      })
    }
  }

  idpDiscovery() {
    clearTimeout(this.idpDiscoveryRequestTimeout)
    this.idpDiscoveryRequestTimeout = setTimeout(() => {
      this.idpDiscoveryRequest()
    }, 600)
  }

  ssoFields() {
    this.formTarget.method = 'GET'
    this.formTarget.action = '/auth/sso'
    this.forgotPasswordBtnTarget.classList.remove('d-block')
    this.passwordInputTarget.classList.add('d-none')
    this.rememberMeBtnTarget.classList.add('d-none')
    this.forgotPasswordBtnTarget.classList.add('d-none')
    this.loginLinkTarget.innerHTML = window.I18n.t('js.login_with_password')
    this.loginTypeValue = 'sso'
  }

  defaultLoginFields() {
    this.formTarget.method = 'POST'
    this.formTarget.action = '/login'
    this.passwordInputTarget.classList.remove('d-none')
    this.rememberMeBtnTarget.classList.remove('d-none')
    this.forgotPasswordBtnTarget.classList.remove('d-none')
    this.forgotPasswordBtnTarget.classList.add('d-block')
    this.loginLinkTarget.innerHTML = window.I18n.t('js.continue_with_microsoft_sso')
    this.loginTypeValue = ''
  }

  switchForm() {
    if (this.loginTypeValue == 'sso') {
      setCookie('sso', '', 0)
      this.defaultLoginFields()
    } else {
      this.ssoFields()
    }
  }

  validEmail(email) {
    return email.match(/\S+@\S+\.\S+/)
  }
}
