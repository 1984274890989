import { Controller } from '@hotwired/stimulus'

const FLASH_DELAY = 5 * 1000 // 5s delay

// Connects to data-controller="flash-notification"
export default class extends Controller {
  connect() {
    this.scheduleFlashRemoval()
  }

  close() {
    this.element.remove()
  }

  scheduleFlashRemoval() {
    const _this = this

    setTimeout(function() { _this.element.remove() }, FLASH_DELAY)
  }
}
