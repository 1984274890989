import { LocaleManager } from '@bryntum/scheduler/scheduler.module.min.js'

const Pl = {
  localeName: 'Pl',
  localeDesc: 'Polish',
  localeCode: 'pl',

  Object: {
    Yes: 'Tak',
    No: 'Nie',
    Cancel: 'Anuluj',
    Ok: 'OK',
    Week: 'Tydzień',
    newEvent: 'Nowe wydarzenie',
  },

  Field: {
    badInput: 'Nieprawidłowa wartość pola',
    patternMismatch: 'Wartość musi pasować do określonego wzorca',
    rangeOverflow: function(e) {
      return 'Wartość musi być mniejsza lub równa '.concat(e.max)
    },
    rangeUnderflow: function(e) {
      return 'Wartość musi być większa lub równa '.concat(e.min)
    },
    fieldRequired: 'To pole jest wymagane',
    invalidValue: 'Nieprawidłowa wartość pola',
    maximumValueViolation: 'Przekroczenie maksymalnej wartości',
    minimumValueViolation: 'Przekroczenie minimalnej wartości',
    stepMismatch: 'Wartość musi odpowiadać krokowi',
    tooLong: 'Wartość powinna być krótsza',
    tooShort: 'Wartość powinna być dłuższa',
    typeMismatch: 'Wymagany jest specjalny format wartości',
    validateFilter: 'Wartość musi być wybrana z listy',
    valueMissing: 'To pole jest wymagane',
  },

  Scheduler: {
    assignedTo: 'Przypisane do',
    checkin_item_first: 'Najpierw zakończ proces zwrotu.',
    dragValidation: 'Nie można planować przeszłych dat',
    split: 'Podziel',
    barcode: 'Kod kreskowy',
    category: 'Kategoria',
    checkin: 'Zwrot',
    checkout: 'Przydziel',
    remarks: 'Uwagi',
    responsible: 'Zarejestrowani odpowiedzialni',
    scheduled_by: 'Zarządzane przez',
    serial_number: 'Numer seryjny',
    time: 'Czas',
  },

  ColumnPicker: {
    column: 'Kolumna',
    columnsMenu: 'Kolumny',
    hideColumn: 'Ukryj kolumnę',
    hideColumnShort: 'Ukryj',
    newColumns: 'Nowe kolumny',
  },

  Sort: {
    addSortAscending: 'Dodaj sortowanie rosnące',
    addSortAscendingShort: '+ Rosnąco',
    addSortDescending: 'Dodaj sortowanie malejące',
    addSortDescendingShort: '+ Malejąco',
    multiSort: 'Sortowanie wielokrotne',
    removeSorter: 'Usuń sortowanie',
    removeSorterShort: 'Usuń',
    sort: 'Sortuj',
    sortAscending: 'Sortuj rosnąco',
    sortAscendingShort: 'Rosnąco',
    sortDescending: 'Sortuj malejąco',
    sortDescendingShort: 'Malejąco',
    toggleSortAscending: 'Zmień na rosnąco',
    toggleSortDescending: 'Zmień na malejąco',
  },

  EventEdit: {
    'Name': 'Nazwa',
    'Resource': 'Zasób',
    'Start': 'Początek',
    'End': 'Koniec',
    'Save': 'Zapisz',
    'Delete': 'Usuń',
    'Cancel': 'Anuluj',
    'Edit event': 'Edytuj wydarzenie',
    'Repeat': 'Powtórz',
  },

  SchedulerBase: {
    'Add event': 'Dodaj wydarzenie',
    'Delete event': 'Usuń wydarzenie',
    'Unassign event': 'Usuń przypisanie wydarzenia',
  },

  TimeAxisHeaderMenu: {
    pickZoomLevel: 'Powiększenie',
    activeDateRange: 'Zakres dat',
    startText: 'Data rozpoczęcia',
    endText: 'Data zakończenia',
    todayText: 'Dziś',
  },

  EventCopyPaste: {
    copyEvent: 'Kopiuj wydarzenie',
    cutEvent: 'Wytnij wydarzenie',
    pasteEvent: 'Wklej wydarzenie',
  },

  Group: {
    group: 'Grupa',
    groupAscending: 'Grupuj rosnąco',
    groupAscendingShort: 'Rosnąco',
    groupDescending: 'Grupuj malejąco',
    groupDescendingShort: 'Malejąco',
    stopGrouping: 'Zatrzymaj grupowanie',
    stopGroupingShort: 'Stop',
  },

  GridBase: {
    loadFailedMessage: 'Ładowanie danych nie powiodło się!',
    moveColumnLeft: 'Przenieś do sekcji po lewej',
    moveColumnRight: 'Przenieś do sekcji po prawej',
    networkFailure: 'Błąd sieci',
    noRows: 'Brak obiektów do zaplanowania do wyświetlenia',
    parseFailure: 'Nie można przetworzyć odpowiedzi serwera',
    serverResponse: 'Odpowiedź serwera:',
    syncFailedMessage: 'Synchronizacja danych nie powiodła się!',
    unspecifiedFailure: 'Niesprecyzowany błąd',
    moveColumnTo: function(e) {
      return 'Przenieś kolumnę do '.concat(e)
    },
  },

  EventFilter: {
    filterEvents: 'Filtruj wydarzenia',
    byName: 'Po nazwie',
  },

  TimeRanges: {
    showCurrentTimeLine: 'Pokaż aktualną linię czasową',
  },

}

LocaleManager.registerLocale('Pl', { locale: Pl })
