import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['count', 'selectedRecord', 'selectedIds', 'selectableCheckBox', 'submitButton'];

  static values = {
    selectedIds: Object,
  }

  // copied from here: https://boringrails.com/articles/better-stimulus-controllers/

  // data-controller="checkbox-list" to initialize the data controller
  // <div data-target="checkbox-list.count"> for the count of input value

  // action to check all the checkbox.
  // data-action="click->checkbox-list#checkAll"

  // action to uncheck all the checkbox.
  // data-action="click->checkbox-list#checkNone"


  connect() {
    this.setCount()
    this.selectedLabel()
    this.enableDisableSubmitButton()
  }

  checkAll() {
    this.setAllCheckboxes(true)
    this.setCount()
    this.selectedLabel()
    this.enableDisableSubmitButton()
  }

  checkNone() {
    this.setAllCheckboxes(false)
    this.setCount()
    this.selectedLabel()
    this.enableDisableSubmitButton()
  }

  onChecked(e) {
    this.setCount()
    const target = e.currentTarget.querySelector('input[type=checkbox]')
    target.checked ? this.addSelectedValue(target.dataset.name, target.id) : this.removeSelectedValue(target.id)
    this.selectedLabel()
    this.enableDisableSubmitButton()
  }

  removeCheckedValue(e) {
    const id = e.currentTarget.dataset.id
    if (document.getElementById(id)) {
      document.getElementById(id).checked = false
    }
    this.removeSelectedValue(id)
    this.selectedLabel()
    this.enableDisableSubmitButton()
  }

  addSelectedValue(name, id) {
    if (this.hasSelectedIdsValue) {
      const values = this.selectedIdsValue
      values[id] = name
      this.selectedIdsValue = values
    }
  }

  removeSelectedValue(id) {
    if (this.hasSelectedIdsValue) {
      const values = this.selectedIdsValue
      delete values[id]
      this.selectedIdsValue = values
    }
  }

  setAllCheckboxes(checked) {
    this.checkboxes.forEach((el) => {
      const checkbox = el

      if (!checkbox.disabled) {
        checkbox.checked = checked
      }
    })
  }

  selectAllRecords(e) {
    const detail = e.detail
    this.setAllCheckboxes(detail.checked)

    this.selectedIdsValue = e.detail.records
    this.selectedLabel()
    this.enableDisableSubmitButton()
  }

  selectedLabel() {
    if (this.hasSelectedRecordTarget) {
      const selected = this.selectedRecordTarget
      selected.textContent = ''
      const style = this.selectedCheckboxes.length > 0 ?
        'border: 1px solid #E2E8F0; border-radius: 0.25rem; max-height: 100px;' :
        ''
      selected.setAttribute('style', style)
      const selectedIds = []
      const _this = this
      if (this.hasSelectedIdsValue) {
        for (const [key, value] of Object.entries(this.selectedIdsValue)) {
          selectedIds.push(key)
          selected.appendChild(_this.createLabel(value, key))
        }
      } else {
        this.selectedCheckboxes.forEach(function(item, index) {
          if (item.dataset.name) {
            selectedIds.push(item.dataset.id)
            selected.appendChild(_this.createLabel(item.dataset.name, item.id))
          }
        })
      }

      this.createHiddenField(selectedIds)
    }
  }

  createHiddenField(selectedIds) {
    if (this.hasSelectedIdsTarget) {
      const selectedIdsVal = this.selectedIdsTarget
      selectedIdsVal.textContent = ''
      selectedIds.forEach(function(val, index) {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('name', selectedIdsVal.dataset.name)
        hiddenField.setAttribute('multiple', 'true')
        hiddenField.setAttribute('value', val)
        selectedIdsVal.appendChild(hiddenField)
      })
    }
  }

  createLabel(name, htmlId) {
    const label = document.createElement('label')
    label.setAttribute('class', 'badge badge-primary mr-2')
    label.setAttribute('style', 'margin: 5px')
    label.appendChild(this.createSpan(name, htmlId))
    return label
  }

  createSpan(name, htmlId) {
    const span = document.createElement('span')
    span.innerHTML = name
    span.appendChild(this.createIcon(htmlId))
    return span
  }

  createIcon(htmlId) {
    const i = document.createElement('i')
    i.setAttribute('class', 'fa fa-times ml-2 text-primary bg-light clickable')
    i.setAttribute('style', 'padding: 2px 4px; border-radius: 50%; margin-left: 5px')
    i.setAttribute('data-action', 'click->checkbox-list#removeCheckedValue')
    i.setAttribute('data-id', htmlId)
    return i
  }

  setCount() {
    if (this.hasCountTarget) {
      const count = this.selectedCheckboxes.length
      this.countTarget.innerHTML = count + ' ' + I18n.t('js.selected')
    }
  }

  enableDisableSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      if (this.selectedCheckboxes.length > 0) {
        this.submitButtonTarget.removeAttribute('disabled')
      } else {
        this.submitButtonTarget.setAttribute('disabled', true)
      }
    }
  }
  get selectedCheckboxes() {
    return this.checkboxes.filter((c) => c.checked)
  }

  get checkboxes() {
    if (this.hasSelectableCheckBoxTarget) {
      return this.selectableCheckBoxTargets
    } else {
      return new Array(...this.element.querySelectorAll('input[type=checkbox]'))
    }
  }
}
