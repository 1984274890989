import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['fullScreen']

  connect() {
  }

  turnOnFullScreen() {
    this.fullScreenTarget.classList.add('table-fullscreen')
    this.fullScreenTarget.classList.add('nav-toggled')
  }

  turnOffFullScreen() {
    this.fullScreenTarget.classList.remove('table-fullscreen')
    this.fullScreenTarget.classList.remove('nav-toggled')
  }

  toggleFullScreen(e) {
    e.preventDefault()
    if (this.fullScreenTarget.classList.contains('table-fullscreen')) {
      this.turnOffFullScreen()
    } else {
      this.turnOnFullScreen()
    }
  }
}
