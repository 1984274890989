import { Controller } from 'stimulus'
import { showElement, hideElement } from '../utils/html_element_helpers'

export default class extends Controller {
  static targets = [
    'responsibleSelect', 'alertDeadline', 'deadlineCheckbox',
    'dateInput', 'dateGroup', 'itemList',
  ]

  toggleLocationInput() {
    document.getElementById('ticket-items-list').innerHTML = ''
  }

  toggleDeadlineCheckbox(_event) {
    if (this.deadlineCheckboxTarget.checked) {
      this.dateInputTarget.value = this.dateInputTarget.dataset.recommendation
      this.dateGroupTarget.children[1].focus()

      hideElement(this.alertDeadlineTarget)
    } else {
      this.dateInputTarget.value = ''

      showElement(this.alertDeadlineTarget)
    }
  }
}
