import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'status', 'spinner']
  static values = {
    duration: { type: Number, default: 0 },
    saveOnLoad: Boolean,
  }
  // to add autosave
  // data-controller='autosave'

  // Form element. If data-controller is defined on the form,
  // the target doesn't needs to be explicitly defined (can be omitted)
  // data-autosave-target="form"
  //
  // Element where to output the autosave status. If not defined. No submission status will be displayed.
  // This makes the most sense in case of a Trix Editor Auto Save / Rich Text Fiels (Action Text)
  // data-autosave-target="status"

  // for status messages, add the following data-action to the form (--Actions to trigger the status update)
  // data-action: 'ajax:success->autosave#success ajax:error->autosave#error'

  // element to autosave upon event
  // "data-action": "keyup->autosave#save"
  // "data-action": "change->autosave#save"

  // to add a spinner to an element. for it to work, it's required that this controller can react to ajax
  // ajax:success / ajax:error ACTION is required to have the spinner removed again.
  // data-autosave-target='spinner'

  // If autoasave should wait some time before submitting, add this option
  // For a autosaving trix editor, choose 2000, for seachform submit 600-1000
  // data-autosave-duration-value='1000'
  // data-save-on-load-value='true'

  // To define a min char-length for a text field, call the action
  // data-action: 'keyup->autosave#saveTextLength3'

  connect() {
    this.timeout = null
    if (this.saveOnLoadValue) {
      this.save()
    }
  }

  save() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      for (let i = 0; i < this.statusTargets.length; i++) {
        this.updateStatus(window.I18n.t('js.saving'))
      }
      this.addSpinner()
      // if has a formTarget --> submit that. Else default behaviour is to
      // submit element where data-controller is defined.
      Rails.fire(this.hasFormTarget ? this.formTarget : this.element, 'submit')
    }, this.durationValue)
  }

  saveTextLength3() {
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      this.save()
    }
  }

  success() {
    this.updateStatus(window.I18n.t('js.saved'), { add: 'text-success' })
    this.removeSpinner()

    setTimeout(() => {
      const d = new Date()
      this.updateStatus(window.I18n.t('js.auto_saved_at') + d.toLocaleTimeString(), { remove: 'text-success' })
    }, 2000)
  }

  error() {
    this.updateStatus(window.I18n.t('js.unable_to_save'), { add: 'text-danger' })
    this.removeSpinner()

    setTimeout(() => {
      this.updateStatus('', { remove: 'text-danger' })
    }, 2000)
  }

  updateStatus(statusText, classList = {}) {
    for (let i = 0; i < this.statusTargets.length; i++) {
      this.statusTargets[i].textContent = statusText
      if ('add' in classList) {
        this.statusTargets[i].classList.add(classList['add'])
      } else if ('remove' in classList) {
        this.statusTargets[i].classList.remove(classList['remove'])
      }
    }
  }

  addSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add('whirl', 'double-up')
    }
  }

  removeSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('whirl', 'double-up')
    }
  }
}
