import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['btn', 'btnProgress', 'btnReady']

  connect() {
    const key = this.btnTarget.dataset['readyKey']

    if (!this.btnTarget.classList.contains('active')) {
      this.btnTarget.classList.add('active')
      this.interval = setInterval(() => { this.isReady(key) }, 1000)
      setTimeout(() => { clearInterval(this.interval) }, 120000)
    }
  }
  async isReady(key) {
    const response = await fetch(`/notifications/ready?key=${key}`)
    const text = await response.text()

    if (JSON.parse(text).ready == '1') {
      clearInterval(this.interval)

      this.btnTarget.remove()
      this.btnReadyTarget.classList.remove('d-none')
    } else { }
  }
}
