import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'languageLiWrapperde',
    'languageDivWrapperde',
    'languageLiWrapperen',
    'languageDivWrapperen',
    'languageLiWrapperfr',
    'languageDivWrapperfr',
  ]

  changeTab(e) {
    e.preventDefault()
    const tabId = e.params.tab
    Array.from(document.getElementsByClassName('language-nav-link')).forEach((el) => { el.classList.remove('active') })
    Array.from(document.getElementsByClassName('language-nav-tab')).forEach((el) => { el.classList.remove('active') })
    e.currentTarget.classList.add('active')
    document.getElementById(tabId).classList.add('active')
  }

  changeLanguage(e) {
    const checked = e.currentTarget.checked
    const language = e.currentTarget.dataset.language
    if (checked) {
      Array.from(document.getElementsByClassName('language-nav-link')).forEach((el) => {
        el.classList.remove('active')
      })
      Array.from(document.getElementsByClassName('language-nav-tab')).forEach((el) => {
        el.classList.remove('active')
      })
      this.show(this['languageLiWrapper' + language + 'Target'])
      this.show(this['languageDivWrapper' + language + 'Target'])
    } else {
      this.hide(this['languageLiWrapper' + language + 'Target'])
      this.hide(this['languageDivWrapper' + language + 'Target'])
      const checkedInput = Array.from(document.getElementsByClassName('language-hidden-input')).find((el) => el.checked)
      if (checkedInput) {
        const language = checkedInput.dataset.language
        this.show(this['languageLiWrapper' + language + 'Target'])
        this.show(this['languageDivWrapper' + language + 'Target'])
      }
    }
  }

  hide(target) {
    target.classList.add('d-none')
  }

  show(target) {
    target.classList.remove('d-none')
    target.classList.add('active')
    if (target.getElementsByTagName('a')[0]) {
      target.getElementsByTagName('a')[0].classList.add('active')
    }
  }
}
