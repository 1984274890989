import { LocaleManager } from '@bryntum/scheduler/scheduler.module.min.js'

const Es = {
  localeName: 'Es',
  localeDesc: 'Español',
  localeCode: 'es',

  Object: {
    Yes: 'Sí',
    No: 'No',
    Cancel: 'Cancelar',
    Ok: 'OK',
    Week: 'Semana',
    newEvent: 'Nuevo evento',
  },

  Field: {
    badInput: 'Valor de campo inválido',
    patternMismatch: 'El valor debe coincidir con un patrón específico',
    rangeOverflow: function(e) {
      return 'Value must be less than or equal to '.concat(e.max)
    },
    rangeUnderflow: function(e) {
      return 'Value must be greater than or equal to '.concat(e.min)
    },
    fieldRequired: 'Este campo es obligatorio',
    invalidValue: 'Valor de campo inválido',
    maximumValueViolation: 'Violación del valor máximo',
    minimumValueViolation: 'Violación del valor mínimo',
    stepMismatch: 'El valor debe ajustarse al paso',
    tooLong: 'El valor debe ser más corto',
    tooShort: 'El valor debe ser más largo',
    typeMismatch: 'Se requiere que el valor esté en un formato especial',
    validateFilter: 'El valor debe ser seleccionado de la lista',
    valueMissing: 'Este campo es obligatorio',
  },
  Scheduler: {
    assignedTo: 'Asignado a',
    checkin_item_first: 'Primero complete el proceso de devolución.',
    dragValidation: 'No se pueden planificar fechas pasadas',
    split: 'Compartir',
    barcode: 'Código de barras',
    category: 'Categoría',
    checkin: 'Devolver',
    checkout: 'Asignar',
    remarks: 'Comentarios',
    responsible: 'Responsables registrados',
    scheduled_by: 'Dispuesto por',
    serial_number: 'Número de serie',
    time: 'Tiempo',
  },
  ColumnPicker: {
    column: 'Columna',
    columnsMenu: 'Columnas',
    hideColumn: 'Ocultar columna',
    hideColumnShort: 'Ocultar',
    newColumns: 'Nuevas columnas',
  },
  Sort: {
    addSortAscending: 'Agregar orden ascendente',
    addSortAscendingShort: '+ Ascendente',
    addSortDescending: 'Agregar orden descendente',
    addSortDescendingShort: '+ Descendente',
    multiSort: 'Ordenación múltiple',
    removeSorter: 'Eliminar ordenador',
    removeSorterShort: 'Eliminar',
    sort: 'Ordenar',
    sortAscending: 'Ordenar ascendente',
    sortAscendingShort: 'Ascendente',
    sortDescending: 'Ordenar descendente',
    sortDescendingShort: 'Descendente',
    toggleSortAscending: 'Cambiar a ascendente',
    toggleSortDescending: 'Cambiar a descendente',
  },
  EventEdit: {
    'Name': 'Nombre',
    'Resource': 'Recurso',
    'Start': 'Inicio',
    'End': 'Finalización',
    'Save': 'Guardar',
    'Delete': 'Eliminar',
    'Cancel': 'Cancelar',
    'Edit event': 'Editar evento',
    'Repeat': 'Repetir',
  },

  SchedulerBase: {
    'Add event': 'Añadir evento',
    'Delete event': 'Eliminar evento',
    'Unassign event': 'Desasignar evento',
  },

  TimeAxisHeaderMenu: {
    pickZoomLevel: 'Zoom',
    activeDateRange: 'Rango de fechas',
    startText: 'Fecha de inicio',
    endText: 'Fecha de finalización',
    todayText: 'Hoy',
  },

  EventCopyPaste: {
    copyEvent: 'Copiar evento',
    cutEvent: 'Cortar evento',
    pasteEvent: 'Pegar evento',
  },

  Group: {
    group: 'Grupo',
    groupAscending: 'Grupo ascendente',
    groupAscendingShort: 'Ascendente',
    groupDescending: 'Grupo descendente',
    groupDescendingShort: 'Descendente',
    stopGrouping: 'Detenga la agrupación',
    stopGroupingShort: 'Alto',
  },

  GridBase: {
    loadFailedMessage: '¡Falló la carga de datos!',
    moveColumnLeft: 'Cambiar a la sección izquierda',
    moveColumnRight: 'Cambiar a la sección derecha',
    networkFailure: 'Error de red',
    noRows: 'No hay objetos planificables para mostrar',
    parseFailure: 'No se pudo analizar la respuesta del servidor',
    serverResponse: 'Respuesta del servidor:',
    syncFailedMessage: '¡Falló la sincronización de datos!',
    unspecifiedFailure: 'Error no especificado',
    moveColumnTo: function(e) {
      return 'Spalte verschieben nach '.concat(e)
    },
  },

  EventFilter: {
    filterEvents: 'Filtrar tareas',
    byName: 'Por nombre',
  },

  TimeRanges: {
    showCurrentTimeLine: 'Mostrar línea temporal actual',
  },

}

LocaleManager.registerLocale('Es', { locale: Es })
