import { Controller } from 'stimulus'
import { each } from 'lodash'

const ICON_BOX_CLASS = 'icon-box tw-border-slate-200'
const ACTIVE_CLASS = 'active'

export default class extends Controller {
  static targets = ['iconInput', 'otherAccountNumber']

  updateClassIconBox(target) {
    if (target.className === ICON_BOX_CLASS) {
      target.classList.add(ACTIVE_CLASS)
    } else {
      target.parentElement.classList.add(ACTIVE_CLASS)
    }
  }

  setActive({ params: { fontAwesome: fontAwesomeParam }, target: target }) {
    each(document.getElementsByClassName(ICON_BOX_CLASS), function(iconBox) {
      iconBox.classList.remove(ACTIVE_CLASS)
    })

    this.updateClassIconBox(target)
    this.iconInputTarget.value = fontAwesomeParam
  }

  showResponsibleFields(e) {
    const fields = document.getElementById('responsibles_per_location')
    fields.classList.remove('d-none')
    e.target.classList.add('d-none')
  }

  updateOtherAccountNumber(e) {
    const accountNumber = e.currentTarget.value
    for (let j = 0; j < this.otherAccountNumberTargets.length; j++) {
      this.otherAccountNumberTargets[j].value = accountNumber
    }
  }
}
