import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['message']

  update() {
    const items = document.getElementsByClassName('select2-selection__rendered')[1].children.length
    if (items > 1) {
      this.messageTarget.classList.add('d-none')
    } else {
      this.messageTarget.classList.remove('d-none')
    }
  }
}
