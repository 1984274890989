import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pages--ticket-types"
export default class extends Controller {
  static values = { ticketPro: Boolean, cid: String }
  static targets = ['query', 'requiredOnSave', 'requiredOnComplete']

  connect() {
    this.saveElement = `save_${this.cidValue}`
    this.completeElement = `complete_${this.cidValue}`
    this.updateVisibility()
  }

  update(_event) {
    this.queryTarget.requestSubmit()
    this.updateVisibility()
  }

  updateVisibility() {
    if (this.ticketProValue) {
      document.getElementById(this.saveElement).classList.remove('tw-hidden')
      if (this.requiredOnSaveTarget.checked) {
        document.getElementById(this.completeElement).classList.add('tw-hidden')
      } else {
        document
          .getElementById(this.completeElement)
          .classList.remove('tw-hidden')
      }
    } else {
      document.getElementById(this.saveElement).classList.add('tw-hidden')
      document
        .getElementById(this.completeElement)
        .classList.remove('tw-hidden')
    }
  }
}
