import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['nextButton', 'searchForm']
  static values = {
    resultsUrl: String,
    startElasticFetchUrl: String,
    startImportUrl: String,
    finishImportUrl: String,
  }

  connect() {
    this.updateSelectAllCheckbox()
  }

  launchChangeStepActions(e) {
    const step = parseInt(e.detail.step)

    if (step == 3) {
      this.showLoadingNotification('load', 'info')
      this.fetchResults()
    }
    this.changeTopButtons(this.currentStep, false)
  }

  launchInitialEvents(e) {
    const step = parseInt(e.detail.step)

    if (step == 3) {
      const itemTable = document.getElementById('itemsSearchTable')
      if (!itemTable) { this.showLoadingNotification('load', 'info') }
      this.fetchResults()
    }
    this.changeTopButtons(step, false)
    window.dispatchEvent(new CustomEvent('update-step', {}))
  }

  backStepActions(e) {
    const step = parseInt(e.detail.step)
    if (step == 3) {
      this.changeTopButtons(step, false)
      this.fetchResults()
    }
    window.dispatchEvent(new CustomEvent('update-step', {}))
  }

  nextStepActions(e) {
    const currentStep = parseInt(e.detail.step)
    const steps = {
      1: 'api-next-button',
      2: 'locations-form',
      3: 'categories-form',
    }

    if (currentStep >= 0 && steps[currentStep]) {
      if (steps[currentStep] == 'api-next-button') {
        document.getElementById(steps[currentStep]).click()
        return
      }
      Rails.fire(document.getElementById(steps[currentStep]), 'submit')
    }
    this.performAdditionalActions(currentStep)
    window.dispatchEvent(new CustomEvent('update-step', {}))
  }

  changePerPage(e) {
    e.preventDefault()
    Rails.fire(this.searchFormTarget, 'submit')
  }

  fetchResults() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval)
    }

    this.fetchInterval = setInterval(() => {
      fetch(this.resultsUrlValue, { method: 'GET' })
        .then((response) => response.text())
        .then((html) => {
          if (html !== '') {
            clearInterval(this.fetchInterval)
            document.getElementById('results-table').innerHTML = html
          }
        })
    }, 2000)
  }

  startImport() {
    const resultsTable = document.getElementById('import-items-result-box')
    resultsTable.innerHTML = '<div class="whirl double-up mt-5"></div>'

    fetch(this.startImportUrlValue, { method: 'GET' })
      .then((response) => response.text())
      .then((json) => {
        const jsonObject = JSON.parse(json)
        window.$.notify(jsonObject.message, jsonObject.type)
      })
  }

  finishImport() {
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval)
    }

    this.fetchInterval = setInterval(() => {
      fetch(this.finishImportUrlValue, { method: 'GET' })
        .then((response) => response.text())
        .then((html) => {
          if (html !== '') {
            clearInterval(this.fetchInterval)
            this.nextButtonTarget.disabled = false
            document.getElementById('import-items-result-box').innerHTML = html
          }
        })
    }, 2000)
  }

  async performAdditionalActions(step) {
    if (step == 3) {
      this.showLoadingNotification('fetch', 'success')
      this.startElasticFetch()
      this.fetchResults()
    } else if (step == 4) {
      this.changeTopButtons(step, true)
      this.startImport()
      this.finishImport()
    }
  }

  updateTableResults() {
    this.showLoadingNotification('fetch', 'success')
    this.startElasticFetch()
    this.fetchResults()
  }

  startElasticFetch() {
    const resultsTable = document.getElementById('tailored-table')
    if (resultsTable) { resultsTable.classList.add('whirl', 'double-up') }
    fetch(this.startElasticFetchUrlValue, { method: 'GET' })
      .then((response) => response.text())
  }

  changeTopButtons(step, disable) {
    if (step == '4') {
      this.nextButtonTarget.textContent = window.I18n.t('finish')
      this.nextButtonTarget.disabled = disable
    } else {
      this.nextButtonTarget.textContent = window.I18n.t('next')
      this.nextButtonTarget.disabled = false
    }
  }

  showLoadingNotification(action, type) {
    window.$.notify(window.I18n.t(`js.lansweeper.${action}_items`), type)
  }

  updateSelectAllCheckbox() {
    const table = document.getElementById('itemsSearchTable')
    if (!table) { return }

    const checkboxes = Array.from(
      table.querySelectorAll('input[type="checkbox"]'),
    ).filter((checkbox) => checkbox.id !== 'imported_items_all')

    const allChecked = checkboxes.every((checkbox) => checkbox.checked)
    const selectAllCheckbox = this.element.querySelector('#imported_items_all')
    if (selectAllCheckbox) { selectAllCheckbox.checked = allChecked }
  }

  markItemForImport(e) {
    this.updateSelectAllCheckbox()
    const lansweeperId = e.target.value
    const url = '/lansweeper/' + lansweeperId + '/mark_item_for_import' + '?checked=' + e.target.checked
    fetch(url, { method: 'POST' })
      .then((response) => response.text())
  }

  selectUnselectAll(e) {
    const table = document.getElementById('itemsSearchTable')

    const checkboxes = Array.from(
      table.querySelectorAll('input[type="checkbox"]'),
    ).filter((checkbox) => checkbox.id !== e.target.id)

    checkboxes.forEach((checkbox) => {
      checkbox.checked = e.target.checked

      const lansweeperId = checkbox.value
      const url = '/lansweeper/' + lansweeperId + '/mark_item_for_import' + '?checked=' + e.target.checked
      fetch(url, { method: 'POST' })
        .then((response) => response.text())
    })
  }
}
