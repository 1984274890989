import { Controller } from 'stimulus'

export default class extends Controller {
  // ensures that always at least the first bootstrap tab is active
  // at least if none is selected
  // to connect data-controller="ensure-active-tab"

  connect() {
    let hasActiveClass = false
    const tabNavLinks = this.element.querySelectorAll('a.nav-link')

    for (let i = 0; i < tabNavLinks.length; i++) {
      if (tabNavLinks[i].classList.contains('active')) {
        hasActiveClass = true
      }
    }

    if (tabNavLinks.length > 0 && hasActiveClass === false) {
      tabNavLinks[0].classList.add('active')
    }

    const tabPane = this.element.querySelectorAll('div.tab-pane')

    if (tabPane.length > 0 && hasActiveClass === false) {
      tabPane[0].classList.add('active')
    }
  }
}
