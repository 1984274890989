import { Controller } from 'stimulus'

export default class extends Controller {
  returnTabDefault() {
    const backButton = document.getElementById('accounts-overview')
    if (backButton) {
      backButton.click()
    }
  }
}
