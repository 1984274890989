import { Controller } from 'stimulus'
import Cropper from 'cropperjs'
require('cropperjs/dist/cropper.min.css')

export default class extends Controller {
    static targets = ['image', 'controls', 'controlsStart']

    // CORS Settings have to be set on the server: https://docs.digitalocean.com/products/spaces/how-to/configure-cors/
    // on img tag, crossorigin="anonymous" must be set

    connect() {
      // this.toggleControls('inactive')
      this.startCropper()
    }

    startCropper() {
      this.cropper = new Cropper(this.imageTarget, {
        viewMode: 3,
        aspectRatio: 520 / 450,
        checkCrossOrigin: false,
        checkOrientation: false,
      })
      this.toggleControls('active')
    }

    crop() {
      this.imageTarget.src = this.cropper.getCroppedCanvas().toDataURL()
      this.destroy()
    }

    reset() {
      this.cropper.reset()
    }

    destroy() {
      this.toggleControls('inactive')
      this.cropper.destroy()
    }

    toggleControls(status) {
      const controlStatus = { 'active': ['block', 'none'],
        'inactive': ['none', 'block'] }
      if (this.hasControlsTarget) {
        this.controlsTarget.style.display = controlStatus[status][0]
      }
      if (this.hasControlsStartTarget) {
        this.controlsStartTarget.style.display = controlStatus[status][1]
      }
    }
}

