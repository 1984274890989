import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['stepTitle', 'stepContent']

  static values = {
    fetchKeyUrl: String,
    updateKeyUrl: String,
  }

  initialize() {
    this.stepTitleTargets.forEach((el, i) => {
      el.addEventListener('click', () => {
        this.data.set('currentStep', i)
        this.showCurrentStep()
        this.dispatchCustomEvent('initialize-step', this.currentStep)
      })
    })
  }

  connect() {
    this.setInitialCurrentStep()
      .then(() => {
        this.showCurrentStep()
        this.dispatchCustomEvent('change-step', this.currentStep)
      })
  }

  backStep() {
    if ( this.currentStep == 0 ) { return }
    this.data.set('currentStep', this.currentStep - 1)
    this.dispatchCustomEvent('back-step', this.currentStep)
  }

  nextStep() {
    if ( this.currentStep == 4 ) {
      this.data.set('currentStep', 0)
      this.updateStepCount(this.currentStep)
      window.location.href = '/items'
      return
    }
    this.data.set('currentStep', this.currentStep + 1)
    this.dispatchCustomEvent('next-step', this.currentStep)
  }

  updateStep() {
    this.updateStepCount(this.currentStep)
    this.showCurrentStep()
  }

  showCurrentStep() {
    this.stepContentTargets.forEach((el, i) => {
      if (i == this.currentStep) {
        el.classList.remove('d-none')
      } else {
        el.classList.add('d-none')
      }
    })
    this.stepTitleTargets.forEach((el, i) => {
      if (i < this.currentStep) {
        el.classList.remove('active')
        el.classList.add('completed')
        return
      }
      if (i === this.currentStep) {
        el.classList.remove('completed')
        el.classList.add('active')
      } else {
        el.classList.remove('active')
        el.classList.remove('completed')
      }
    })
  }

  async setInitialCurrentStep() {
    const step = await this.fetchStepCount()
    this.data.set('currentStep', step)
  }

  async updateStepCount(value) {
    const url = this.updateKeyUrlValue + '?key=' + value
    fetch(url, { method: 'POST' })
      .then((response) => response.text())
  }

  async fetchStepCount() {
    try {
      const response = await fetch(this.fetchKeyUrlValue, { method: 'GET' })
      const text = await response.json()
      return text.key
    } catch (error) {}
  }

  dispatchCustomEvent(eventName, count) {
    window.dispatchEvent(new CustomEvent(eventName, { detail: { step: count } }))
  }

  get currentStep() {
    return parseInt(this.data.get('currentStep'))
  }
}
