import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['companyId']

  static values = {
    companyIds: Array,
    itemId: Number,
  }

  selectCompanyId(e) {
    const ids = this.companyIdsValue
    const val = e.target.value
    if (e.target.checked) {
      ids.push(val)
    } else {
      const index = ids.indexOf(val)
      if (index > -1) {
        ids.splice(index, 1)
      }
    }
    this.companyIdsValue = ids
  }

  submitForm() {
    let params = '?id=' + this.itemIdValue
    this.companyIdsValue.forEach(function(id) {
      params += '&service_company_ids[]=' + id
    })
    window.location.href = '/items/' + this.itemIdValue + '/update_service_company' + params
  }

  updateServiceIds() {
    for (let i = 0; i < this.companyIdTargets.length; i++) {
      this.companyIdTargets[i].checked = this.companyIdsValue.includes(this.companyIdTargets[i]['value'])
    }
  }
}
