import { Controller } from 'stimulus'
import { each } from 'lodash'

import { showElement } from '../utils/html_element_helpers'

const DEFAULT_FONT_SIZE = 11
export default class extends Controller {
  static targets = ['fontSize', 'fontControlSliderWrapper', 'showTextData',
    'listGroupItem', 'reorderValues', 'fontSizeValue', 'fontSizeDetailValue']

  connect() {
    if (this.hasFontSizeTarget) {
      this.renderFontSize(DEFAULT_FONT_SIZE)
      showElement(this.fontControlSliderWrapperTarget, '')
    }
  }

  rerenderFontSizeChanges({ target: { value: fontValue } } ) {
    this.renderFontSize(fontValue)
  }

  renderFontSize(fontValue) {
    each(this.fontSizeTargets, function(target) {
      target.style.fontSize = fontValue + 'px'
    })
  }

  updateFontSize() {
    this.renderFontSize(this.fontSizeValueTarget.value)
  }

  resize(event) {
    const textarea = event.target
    textarea.style.height = 'auto'
    textarea.style.height = textarea.scrollHeight + 'px'
    this.showTextDataTarget.value = event.target.value
  }

  reorderSelection() {
    const dataIds = {}

    each(this.listGroupItemTargets, function(item) {
      const dataId = item.dataset.id
      if (dataId) {
        dataIds[dataId] = item.dataset.name
      }
    })

    this.reorderValuesTarget.value = JSON.stringify(dataIds)
    const event = new CustomEvent('reorderselection', {
      bubbles: true,
      cancelable: true,
    })

    window.dispatchEvent(event)
  }
}
