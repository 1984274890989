/** =========================================================
 * Module: trigger-resize.js
 * Triggers a window resize event from any element
 =========================================================*/

import $ from 'jquery'

function initTriggerResize() {
  const element = $('[data-trigger-resize]')
  const value = element.data('triggerResize')
  element.on('click', function() {
    setTimeout(function() {
      // all IE friendly dispatchEvent
      const evt = document.createEvent('UIEvents')
      evt.initUIEvent('resize', true, false, window, 0)
      window.dispatchEvent(evt)
      // modern dispatchEvent way
      // window.dispatchEvent(new Event('resize'));
    }, value || 300)
  })
}

export default initTriggerResize
