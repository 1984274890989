import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['gpsNotification']

  static values = {
    trackGpsLocation: Boolean,
    itemId: String,
  }

  connect() {
    if (this.trackGpsLocationValue === true && navigator.geolocation) {
      // Set the user notification
      this.userNotification('<span class=\'blink\'>' + window.I18n.t('js.detecting_gps_position') + '</span>')

      // uncomment if mocking on local dev is required
      // this.submitPosition({coords: { latitude: '48.333376N', longitude: '9.568832E', accuracy: 63}})

      navigator.geolocation.getCurrentPosition(this.submitPosition.bind(this), this.showError.bind(this), {
        enableHighAccuracy: true,
      })
    } else {
      this.userNotification('<i class="far fa-times-circle mr-1"></i>' +
       window.I18n.t('js.geolocation_is_not_supported_by_this_browser'),
      'text-danger',
      '4000')
    }
  }

  showError(error) {
    let errorMessage
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage = window.I18n.t('js.activate_gps_browser')
        break
      case error.POSITION_UNAVAILABLE:
        errorMessage = window.I18n.t('js.location_info_not_available')
        break
      case error.TIMEOUT:
        errorMessage = window.I18n.t('js.location_info_timedout')
        break
      case error.UNKNOWN_ERROR:
        errorMessage = window.I18n.t('js.unknown_error')
        break
    }
    this.userNotification(errorMessage, 'text-danger', 4000)
  }

  submitPosition(position) {
    this.userNotification('<i class="far fa-location-dot mr-1"></i>' + window.I18n.t('js.gps_position_detected'))
    const data = {
      authenticity_token: $('[name="csrf-token"]')[0].content,
    }
    if (position) {
      const coords = position.coords
      data.gps_position = {
        longitude: coords.longitude,
        latitude: coords.latitude,
        accuracy: coords.accuracy,
      }
    }


    fetch('/items/' + this.itemIdValue + '/save_gps_location', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
      body: JSON.stringify(data),
    }).then(function(response) {
      if (!response.ok) {
        return
      } else {
        //
      }

      response.json().then( (data) => {
        if (data.status == 'success') {
          this.updateGPSCounter()
          this.userNotification(
            '<i class="far fa-check-circle mr-1"></i>' + window.I18n.t('js.new_gps_position_saved'),
            'text-success',
            '4000',
          )
        } else {
          const errorType = { gps_position_blank: window.I18n.t('js.error_gps_position_blank'),
            insufficient_accuracy: window.I18n.t('js.error_insufficient_accuracy'),
            same_day_position: window.I18n.t('js.error_same_day_position') }
          this.userNotification(errorType[data.details], 'text-warning', '4000')
        }
      })
    }.bind(this))
  }

  userNotification(message = null, className = '', removeAfter = null) {
    if (this.hasGpsNotificationTarget) {
      this.gpsNotificationTarget.className = 'alert alert-primary py-1 ' + className
      if (message) {
        const dismissableButton = '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true">&times;</span></button>'
        this.gpsNotificationTarget.innerHTML = message + dismissableButton
      }
      if (message === null || removeAfter != null) {
        // if message is empty, remove immediately
        setTimeout(function() {
          this.gpsNotificationTarget.remove()
        }.bind(this), removeAfter || 0)
      }
    }
  }

  updateGPSCounter() {
    const locationCounterEl = document.querySelectorAll('[role*="tab"][href*="positions"]')[0]
    if (locationCounterEl === undefined) {
      return
    }

    let currentCount = locationCounterEl.innerText.match(/\d+/g)
    if (currentCount.length == 0) {
      return
    }

    currentCount = parseInt(currentCount[0])
    if (isNaN(currentCount)) {
      return
    }

    locationCounterEl.innerText = locationCounterEl.innerText.replace(/\d+/g, currentCount + 1)
  }
}
