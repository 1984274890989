import { Controller } from 'stimulus'
import swal from 'sweetalert2'
import SwalStyles from '../utils/swal_styles'

export default class extends Controller {
  // HOW IT WORKS //
  // add data controller and action on link
  // <a href="#" data-controller="swal-confirm" data-action='swal-confirm#confirmAction'></a>

  // Default texts are already available in this controller for
  // back / cancel --> will trigger if hyperlink innerText contains anything related to cancel, back, zurück, etc.
  // delete        --> will be pulled if link contains data method="delete"

  // for own messages: title & text
  // data-swal-confirm-title-value="Your own message"
  // data-swal-confirm-text-value="Your own message"
  // Only a title is required, a text is not.

  static targets = ['form']
  static values = {
    title: String,
    text: { type: String, default: '' },
    type: { type: String, default: 'warning' },
    showCancelButton: { type: Boolean, default: true },
    confirmButtonText: String,
    cancelButtonText: String,
    outsideClick: Boolean,
    actionConfirmed: { type: Boolean, default: false },
  }

  submitForm(event) {
    if (!this.hasTargetForm) {
      return
    }
    swal
      .fire({
        title: this.titleValue,
        html: this.textValue,
        icon: this.typeValue,
        reverseButtons: true,
        showCancelButton: this.showCancelButtonValue,
        confirmButtonText: this.confirmButtonTextValue || window.I18n.t('ok'),
        cancelButtonText: this.cancelButtonTextValue || window.I18n.t('cancel'),
      })
      .then(
        function({ isConfirmed: confirmValue }) {
          this.actionConfirmedValue = confirmValue

          if (confirmValue) {
            Rails.fire(self.formTarget, 'submit')
          }
        }.bind(this),
      )
  }

  confirmAction(event) {
    if (this.actionConfirmedValue) {
      if (
        event.currentTarget &&
        event.currentTarget.dataset['globalEventName']
      ) {
        const eventName = event.currentTarget.dataset['globalEventName']
        const globalEvent = new CustomEvent(eventName, {
          detail: {},
        })
        return window.dispatchEvent(globalEvent)
      }

      return event.target.click()
    }

    event.preventDefault()
    event.stopImmediatePropagation()

    this.setDefaultText(event.target.dataset.value)

    const iconHtml =
      this.typeValue == 'question' ? '<div class="tw-text-3xl">?</div>' : '<div class="tw-text-3xl">!</div>'

    const confirmText = event.target.dataset.swalConfirmDoubleTextValue || ''

    swal
      .fire({
        title: this.titleValue,
        html: this.textValue,
        icon: this.typeValue,
        reverseButtons: true,
        showCancelButton: this.showCancelButtonValue,
        confirmButtonText: this.confirmButtonTextValue || window.I18n.t('ok'),
        cancelButtonText: this.cancelButtonTextValue || window.I18n.t('cancel'),
        iconHtml: iconHtml,
        customClass: SwalStyles,
      })
      .then(
        function({ isConfirmed: confirmValue }) {
          this.actionConfirmedValue = confirmValue
          // added this confirmText to allow for a double confirmation popup
          if (confirmValue && confirmText.length > 0) {
            this.confirmDeleteWithTyping(event, confirmText)
          } else if (confirmValue) {
            this.confirmAction(event)
          }
        }.bind(this),
      )
  }

  confirmDeleteWithTyping(event, confirmText) {
    const iconHtml =
      this.typeValue == 'question' ? '<div class="tw-text-3xl">?</div>' : '<div class="tw-text-3xl">!</div>'

    const deleteText = window.I18n.t('confirmation_delete_text')

    swal
      .fire({
        title: this.titleValue,
        html: confirmText,
        icon: this.typeValue,
        input: 'text',
        inputPlaceholder: deleteText,
        showCancelButton: true,
        confirmButtonText: window.I18n.t('delete'),
        cancelButtonText: window.I18n.t('cancel'),
        iconHtml: iconHtml,
        customClass: SwalStyles,
        preConfirm: (inputValue) => {
          if (inputValue !== deleteText) {
            const input = swal.getInput()
            input.value = ''
            input.style.borderColor = 'red'
            input.focus()
            return false
          }
          return true
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.actionConfirmedValue = true
          this.sendTurboRequest(event)
        } else if (result.dismiss === swal.DismissReason.cancel) {
          // Reopen the first confirmation box if the cancel button is clicked
          this.actionConfirmedValue = false
          this.confirmAction(event)
        }
      })
  }

  sendTurboRequest(event) {
    const url = event.target.href
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Accept': 'text/vnd.turbo-stream.html',
      },
      credentials: 'same-origin',
    })
      .then((response) => {
        if (response.ok) {
          return response.text()
        }
        throw new Error('Network response was not ok.')
      })
      .then((html) => {
        Turbo.renderStreamMessage(html)
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error)
      })
  }

  setDefaultText(dataValue) {
    if (this.titleValue.length > 0) return this.titleValue

    const hasDeleteMethod =
      (this.element.hasAttribute('data-method') &&
        this.element.dataset.method.toLowerCase() == 'delete') ||
      (event.currentTarget.hasAttribute('data-method') &&
        this.element.dataset.method.toLowerCase() == 'delete')
    const regexForCancelAndBack = new RegExp(
      window.I18n.t('back') + '|' + window.I18n.t('cancel'),
      'gi',
    )

    if (hasDeleteMethod || dataValue == 'delete') {
      // If link is for deleting a record.
      this.titleValue = window.I18n.t('js.are_you_sure_delete')
      if (this.textValue.length == 0) {
        this.textValue = window.I18n.t('js.data_irrevocably_deleted')
      }
    } else if (event.currentTarget.innerText.match(regexForCancelAndBack)) {
      // When item is a back or cancel button
      this.titleValue = window.I18n.t('are_you_sure')
      if (this.textValue.length == 0) {
        this.textValue = window.I18n.t('js.any_changes_will_be_lost')
      }
    } else {
      this.titleValue = window.I18n.t('are_you_sure')
    }
  }
}
