import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mainAttachmentId', 'selectedAttachmentId']
  static values = { id: String }

  connect() {
    if (this.idValue) {
      document.getElementById('fileId-' + this.idValue).remove()
    }
  }

  assignMainAttachment(event) {
    if (this.hasSelectedAttachmentIdTarget) {
      this.selectedAttachmentIdTargets.forEach(function(item, index) {
        if (item != event.currentTarget) {
          item.checked = false
        }
      })
      if (this.hasMainAttachmentIdTarget) {
        this.mainAttachmentIdTarget.value = event.currentTarget.value
      }
    }
  }
}
