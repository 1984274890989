import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="permission"
export default class extends Controller {
  static targets = ['form', 'personnelRole', 'inventoryRole']
  connect() {}

  submitForm() {
    Rails.fire(this.formTarget, 'submit')
  }

  showRoleNames(e) {
    const dataSet = e.currentTarget.dataset
    e.preventDefault()
    e.stopPropagation()
    if (dataSet.mod === 'personnel') {
      this.personnelRoleTarget.style.display = ''
      this.inventoryRoleTarget.style.display = 'none'
    } else if (dataSet.mod === 'inventory') {
      this.personnelRoleTarget.style.display = 'none'
      this.inventoryRoleTarget.style.display = ''
    }
  }
}
