import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    itemType: String,
  }

  goToLocationsTab(event) {
    event.preventDefault()
    document.getElementById('gps-nav-item').click()
  }

  itemStock() {
    return this.itemTypeValue === 'item_stock'
  }
}
