export function initNotify() {
  const Selector = '[data-notify]'
  const autoloadSelector = '[data-onload]'
  const doc = $(document)

  $(Selector).each(function() {
    const $this = $(this)
    const onload = $this.data('onload')

    if (onload !== undefined) {
      setTimeout(function() {
        notifyNow($this)
      }, 200)
    }

    $this.on('click', function(e) {
      e.preventDefault()
      notifyNow($this)
    })
  })
}

export function notifyNow($element, message, options) {
  var message = message || $element.data('message')
  var options = options || $element.data('options')

  if (!message) {
    $.error('Notify: No message specified')
  }

  $.notify(message, options || {})
}


/**
 * Notify Addon definition as jQuery plugin
 * Adapted version to work with Bootstrap classes
 * More information http://getuikit.com/docs/addons_notify.html
 */

(function() {
  const containers = {}
  const messages = {}

  const notify = function(options) {
    if ($.type(options) == 'string') {
      options = { message: options }
    }

    // arguments[1] sets the alert level, which are directly coupled to css classes.
    // arguments[1] is only allowed to be any value from the css classes we have.
    if (arguments[1]) {
      // Argument can be passed as String or Object. Convert arguments[1] to format { status: 'your_status' }
      arguments[1] = $.type(arguments[1]) == 'string' ? { status: arguments[1] } : arguments[1]
      if (['primary', 'info', 'warning', 'danger', 'success', 'error'].includes(arguments[1].status)) {
        options = $.extend(options, arguments[1])
      }
    }

    return (new Message(options)).show()
  }
  const closeAll = function(group, instantly) {
    if (group) {
      for (var id in messages) {
        if (group === messages[id].group) messages[id].close(instantly)
      }
    } else {
      for (var id in messages) {
        messages[id].close(instantly)
      }
    }
  }

  var Message = function(options) {
    const $this = this

    this.options = $.extend({}, Message.defaults, options)

    this.uuid = 'ID' + (new Date().getTime()) + 'RAND' + (Math.ceil(Math.random() * 100000))

    if (options.html) {
      this.element = $(options.html).data('notifyMessage', this)
     }
    else {
      this.element = $([
        `<div angle-notify-message class="tw-font-sans tw-pointer-events-auto tw-cursor-pointer tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-mt-4 tw-group">
          <div class="tw-p-4">
            <div class="tw-flex tw-items-start">
              <div class="tw-flex-shrink-0">
                <svg class="tw-hidden group-[.success]:tw-block tw-h-6 tw-w-6 tw-text-green-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <svg class="tw-hidden group-[.error]:tw-block group-[.danger]:tw-block tw-h-6 tw-w-6 tw-text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <svg class="tw-hidden group-[.warning]:tw-block tw-h-6 tw-w-6 tw-text-orange-300" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                </svg>
                <svg class="tw-hidden group-[.info]:tw-block tw-h-6 tw-w-6 tw-text-blue-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>
              </div>
              <div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
                <p class="tw-text-sm tw-font-medium tw-text-gray-900 tw-mb-0">${this.options.message}</p>
              </div>
              <div class="tw-ml-4 tw-flex tw-flex-shrink-0">
                <a class="tw-inline-flex tw-rounded-md tw-bg-white tw-text-slate-400 hover:tw-text-slate-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-400 focus:tw-ring-offset-2">
                  <span class="tw-sr-only">Close</span>
                  <svg class="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>`,
      ].join('')).data('notifyMessage', this)

    }

    // status
    if (this.options.status) {
      this.element.addClass(this.options.status)
      this.currentstatus = this.options.status
    }

    this.group = this.options.group

    messages[this.uuid] = this

    if (!containers[this.options.pos]) {
      containers[this.options.pos] = $('<div class="angle-notify angle-notify-' + this.options.pos + '"></div>').appendTo('body').on('click', '[angle-notify-message]', function() {
        $(this).data('notifyMessage').close()
      })
    }
  }


  $.extend(Message.prototype, {

    uuid: false,
    element: false,
    timout: false,
    currentstatus: '',
    group: false,

    show: function() {
      if (this.element.is(':visible')) return

      const $this = this

      containers[this.options.pos].show().prepend(this.element)

      const marginbottom = parseInt(this.element.css('margin-bottom'), 10)

      this.element.css({ 'opacity': 0, 'margin-top': -1 * this.element.outerHeight(), 'margin-bottom': 0 }).animate({ 'opacity': 1, 'margin-top': 5, 'margin-bottom': marginbottom }, function() {
        if ($this.options.timeout) {
          const closefn = function() {
            $this.close()
          }

          $this.timeout = setTimeout(closefn, $this.options.timeout)

          $this.element.hover(
              function() {
                clearTimeout($this.timeout)
              },
              function() {
                $this.timeout = setTimeout(closefn, $this.options.timeout)
              },
          )
        }
      })

      return this
    },

    close: function(instantly) {
      const $this = this
      const finalize = function() {
        $this.element.remove()

        if (!containers[$this.options.pos].children().length) {
          containers[$this.options.pos].hide()
        }

        delete messages[$this.uuid]
      }

      if (this.timeout) clearTimeout(this.timeout)

      if (instantly) {
        finalize()
      } else {
        this.element.animate({ 'opacity': 0, 'margin-top': -1 * this.element.outerHeight(), 'margin-bottom': 0 }, function() {
          finalize()
        })
      }
    },

    content: function(html) {
      const container = this.element.find('>div')

      if (!html) {
        return container.html()
      }

      container.html(html)

      return this
    },

    status: function(status) {
      if (!status) {
        return this.currentstatus
      }

      this.element.removeClass(this.currentstatus).addClass(status)

      this.currentstatus = status

      return this
    },
  })

  Message.defaults = {
    message: '',
    status: 'normal',
    timeout: 5000,
    group: null,
    pos: 'top-center',
  }

  $['notify'] = notify
  $['notify'].message = Message
  $['notify'].closeAll = closeAll

  return notify
}())
