import { Controller } from 'stimulus'
import SwalStyles from '../utils/swal_styles'

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    confirmButtonText: String,
    cancelButtonText: String,
  }

  connect() {}

  setTrainingComplete() {
    swal.fire({
      title: this.titleValue,
      text: this.textValue,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextValue,
      cancelButtonText: this.cancelButtonTextValue,
      closeOnConfirm: true,
      reverseButtons: true,
      iconHtml: '<div class="tw-text-3xl">!</div>',
      customClass: SwalStyles,
    }).then((result) => {
      if (result.value) {
        document.getElementById('form-training-complete').submit()
      }
    })
  }
}
