import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['source', 'btn']

    copy(event) {
      event.preventDefault()
      this.sourceTarget.select()

      document.execCommand('copy')

      this.copied(event)
    }

    copied(event) {
      const innerButtonDefaultContent = this.btnTarget.innerHTML
      this.btnTarget.innerHTML = '<i class="fas fa-check"></i>'
      this.btnTarget.classList.add('bg-success')
      this.timeout = setTimeout(() => {
        this.btnTarget.innerHTML = innerButtonDefaultContent
        this.btnTarget.classList.remove('bg-success')
      }, 1000)
    }

    copyTableValue({ params }) {
      const contentToCopy = this.removeExtraText(params)
      navigator.clipboard.writeText(contentToCopy.replace(/( |\n)+/g, ' '))
      window.$.notify(window.I18n.t('copied'), 'info')
    }

    removeExtraText(params) {
      const row = document.querySelector(`[data-id=${params.id}]`)
      const linkInside = row.querySelector('a')
      if (linkInside) { return linkInside.href }

      let contentToCopy = row.innerText

      const extraTexts = [
        window.I18n.t('inventory.stock_items.partials.items_overview_table_entry.stock_minimum_reached'),
        window.I18n.t('inventory.items.partials.overview.download_qr_code'),
      ]

      extraTexts.forEach((extraText) => {
        contentToCopy = contentToCopy.replace(extraText, '')
      })

      return contentToCopy
    }
}
