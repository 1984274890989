import { LocaleManager } from '@bryntum/scheduler/scheduler.module.min.js'

const Fr = {
  localeName: 'Fr',
  localeDesc: 'French',
  DateHelper: {
    locale: 'fr',
  },
  EventEdit: {
    'Name': 'Nom',
    'Resource': 'Ressource',
    'Start': 'Commencer',
    'End': 'Fin',
    'Save': 'Sauvegarder',
    'Delete': 'Effacer',
    'Cancel': 'Annuler',
    'Edit event': 'Modifier l\'événement',
    'Repeat': 'Répéter',
  },
  Column: {
    Name: 'Objet',
    Barcode: 'code à barre',
  },
  Object: {
    Yes: 'Oui',
    No: 'Non',
    Cancel: 'Annuler',
    Ok: 'd\'accord',
    Week: 'La semaine',
    newEvent: 'Nouvel évènement',
  },
  Field: {
    badInput: 'Invalid field value',
    patternMismatch: 'Value should match a specific pattern',
    rangeOverflow: function(e) {
      return 'Value must be less than or equal to '.concat(e.max)
    },
    rangeUnderflow: function(e) {
      return 'Value must be greater than or equal to '.concat(e.min)
    },
    stepMismatch: 'Value should fit the step',
    tooLong: 'Value should be shorter',
    tooShort: 'Value should be longer',
    typeMismatch: 'Value is required to be in a special format',
    valueMissing: 'This field is required',
    invalidValue: 'Invalid field value',
    minimumValueViolation: 'Minimum value violation',
    maximumValueViolation: 'Maximum value violation',
    fieldRequired: 'This field is required',
    validateFilter: 'Value must be selected from the list',
  },
  Scheduler: {
    dragValidation: 'Impossible de programmer pour des dates passées',
    time: 'Temps',
    assignedTo: 'Assigné à',
    remarks: 'Commentaires',
    split: 'Diviser',
    checkin: 'Retourner',
    checkout: 'Assigner',
    checkin_item_first: 'Terminez d\'abord le processus de retour.',
    barcode: 'Code-barres',
    responsible: 'Personnes responsables',
    category: 'Catégorie',
    serial_number: 'Numéro de série',
    scheduled_by: 'Planifié par',
  },
  ColumnPicker: {
    column: 'Colonne',
    columnsMenu: 'Menu de colonnes',
    hideColumn: 'Masquer la colonne',
    hideColumnShort: 'Cacher',
    newColumns: 'Nouvelles colonnes',
  },
  Sort: {
    sort: 'Trier',
    sortAscending: 'Trier par ordre croissant',
    sortDescending: 'Trier par ordre décroissant',
    multiSort: 'Tri multiple',
    removeSorter: 'Supprimer le trieur',
    addSortAscending: 'Ajouter un trieur croissant',
    addSortDescending: 'Ajouter un trieur décroissant',
    toggleSortAscending: 'Passer à l\'ascendant',
    toggleSortDescending: 'Passer à décroissant',
    sortAscendingShort: 'Ascendante',
    sortDescendingShort: 'Descendante',
    removeSorterShort: 'Supprimer',
    addSortAscendingShort: '+ Ascendante',
    addSortDescendingShort: '+ Descendante',
  },
  TimeAxisHeaderMenu: {
    pickZoomLevel: 'Zoom',
    activeDateRange: 'Plage de dates',
    startText: 'Date de début',
    endText: 'Date de fin',
    todayText: 'Aujourd\'hui',
  },
  EventFilter: {
    filterEvents: 'Filtrer les délais',
    byName: 'De nom',
  },
  TimeRanges: {
    showCurrentTimeLine: 'Afficher la chronologie actuelle',
  },
  SchedulerBase: {
    'Add event': 'Ajouter un évènement',
    'Delete event': 'Supprimer l\'événement',
    'Unassign event': 'Désattribuer l\'événement',
  },
  EventCopyPaste: {
    copyEvent: 'Copier l\'événement',
    cutEvent: 'Couper l\'événement',
    pasteEvent: 'Coller l\'événement',
  },
  Group: {
    group: 'Groupe',
    groupAscending: 'Groupe ascendant',
    groupDescending: 'Groupe descendant',
    groupAscendingShort: 'Ascendante',
    groupDescendingShort: 'Descendante',
    stopGrouping: 'Arrêter le regroupement',
    stopGroupingShort: 'Arrêter',
  },
  GridBase: {
    loadFailedMessage: 'Le chargement des données a échoué !',
    syncFailedMessage: 'La synchronisation des données a échoué !',
    unspecifiedFailure: 'Échec non spécifié',
    networkFailure: 'Erreur réseau',
    parseFailure: 'Échec de l\'analyse de la réponse du serveur',
    serverResponse: 'Réponse du serveur :',
    noRows: 'Aucun objet planifiable à afficher',
    moveColumnLeft: 'Déplacer vers la section de gauche',
    moveColumnRight: 'Déplacer vers la section de droite',
    moveColumnTo: function(e) {
      return 'Déplacer la colonne vers '.concat(e)
    },
  },
}

LocaleManager.registerLocale('Fr', { locale: Fr })
