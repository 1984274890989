import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="validate-password"
const LOWERCASE = /[a-z]/
const UPPERCASE = /[A-Z]/
const NUMBER = /[0-9]/
const SPECIAL_CHAR = /[\W]/
const SUCCESS_CLASS = 'valid-password'

export default class extends Controller {
  static targets = ['strongPasswordList', 'password', 'length', 'lowercase', 'uppercase', 'number', 'special']

  handlePassword() {
    if (this.hasStrongPasswordListTarget && this.hasPasswordTarget) {
      const password = this.passwordTarget.value
      this.validatePassword(password)
      if (password) {
        this.strongPasswordListTarget.style.display = ''
        this.checkIfEightChar(password)
      } else {
        this.strongPasswordListTarget.style.display = 'none'
      }
    }
  }

  validatePassword(password) {
    this.checkIfEightChar(password)
    this.checkIfOneLowercase(password)
    this.checkIfOneUppercase(password)
    this.checkIfOneSpecialChar(password)
    this.checkIfOneDigit(password)
  }

  checkIfEightChar(password) {
    if (password.length >= 8) {
      this.lengthTarget.classList.add(SUCCESS_CLASS)
    } else {
      this.lengthTarget.classList.remove(SUCCESS_CLASS)
    }
  }

  checkIfOneLowercase(password) {
    if (LOWERCASE.test(password)) {
      this.lowercaseTarget.classList.add(SUCCESS_CLASS)
    } else {
      this.lowercaseTarget.classList.remove(SUCCESS_CLASS)
    }
  }

  checkIfOneUppercase(password) {
    if (UPPERCASE.test(password)) {
      this.uppercaseTarget.classList.add(SUCCESS_CLASS)
    } else {
      this.uppercaseTarget.classList.remove(SUCCESS_CLASS)
    }
  }

  checkIfOneDigit(password) {
    if (NUMBER.test(password)) {
      this.numberTarget.classList.add(SUCCESS_CLASS)
    } else {
      this.numberTarget.classList.remove(SUCCESS_CLASS)
    }
  }

  checkIfOneSpecialChar(password) {
    if (SPECIAL_CHAR.test(password)) {
      this.specialTarget.classList.add(SUCCESS_CLASS)
    } else {
      this.specialTarget.classList.remove(SUCCESS_CLASS)
    }
  }
}
