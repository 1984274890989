import { Controller } from 'stimulus'

const ENTER_KEY_EVENT = 'Enter'
const ESCAPE_KEY_EVENT = 'Escape'
const BACKSPACE_KEY_EVENT = 'Backspace'
const SHIFT_KEY_EVENT = 'Shift'
const CLEAR_KEY_EVENT = 'Clear'

export default class extends Controller {
  connect() {
    this.inputs = []
    window.addEventListener('keydown', this.keyDown, { passive: true })
  }

  disconnect() {
    window.removeEventListener('keydown', this.keyDown, { passive: true })
  }

  keyDown = (e) => {
    const self = this // eslint-disable-line no-invalid-this
    const nodeName = e.target.nodeName
    if (nodeName === 'INPUT' || nodeName === 'TEXTAREA') {
      return
    }
    if ([ESCAPE_KEY_EVENT, BACKSPACE_KEY_EVENT, SHIFT_KEY_EVENT, CLEAR_KEY_EVENT].includes(e.key)) {
      return
    }
    if (e.key === ENTER_KEY_EVENT && self.inputs.length > 0) {
      e.preventDefault()
      e.stopPropagation()
      self.processScannerEvent()
      self.inputs = []
    }
    self.inputs.push(e.key)
  }

  processScannerEvent() {
    const scannedCode = this.inputs.join('')
    console.log(scannedCode)
    const event = new CustomEvent('success-scanned-code', { detail: { code: scannedCode } } )
    window.dispatchEvent(event)
  }
}
