import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['industrySelector', 'next', 'signup', 'hidden']

  connect() {
    if (this.hiddenTarget.value == '') {
      this.signupTarget.hidden = true
      this.industrySelectorTarget.hidden = false
    } else {
      this.industrySelectorTarget.hidden = true
      this.signupTarget.hidden = false
    }
  }

  clear(target) {
    const elements = this.industrySelectorTarget.querySelectorAll('div.image-container')
    let parent = null
    elements.forEach(function(element) {
      if (element.contains(target)) { parent = element }
      element.classList.remove('selected')

      // const unselectedImage = element.querySelector('img:not(.selected)')
      // if (unselectedImage) { unselectedImage.style.display = 'inline' }
      // const selectedImage = element.querySelector('img.selected')
      // if (selectedImage) { selectedImage.style.display = 'none' }
    })

    if (!parent) { parent = target }

    return parent
  }

  selectedImageToggle(element) {
    const unselectedImage = element.querySelector('img:not(.selected)')
    if (unselectedImage) { unselectedImage.style.display = 'none' }
    const selectedImage = element.querySelector('img.selected')
    if (selectedImage) { selectedImage.style.display = 'inline' }
  }

  setSelectedIndustry(element) {
    const search = element.querySelectorAll('div.selected a')[0].search
    const params = new URLSearchParams(search)
    const entries = params.entries()
    const industry = Object.fromEntries(entries)['industry']
    this.hiddenTarget.value = industry
  }

  select(event) {
    event.preventDefault()
    const parent = this.clear(event.target)
    parent.classList.add('selected')
    // this.selectedImageToggle(parent)

    this.setSelectedIndustry(this.element)
    setTimeout(function() { this.hide(event) }.bind(this), 500)
  }

  selectorFormToggle() {
    this.industrySelectorTarget.hidden = !this.industrySelectorTarget.hidden
    this.signupTarget.hidden = !this.signupTarget.hidden
  }

  hide(event) {
    event.preventDefault()

    this.selectorFormToggle()
  }

  back(event) {
    event.preventDefault()

    this.selectorFormToggle()
  }
}
