import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="deadline-templates"
export default class extends Controller {
  static targets = ['expiryDate', 'responsibleUser']

  static values = {
    itemType: String,
  }

  enableAssignButtons() {
    const expiryDate = new Date(this.expiryDateTarget.value)
    if (!isNaN(expiryDate.getTime()) && this.responsibleUserTarget.value) {
      document.querySelectorAll('.disabled').forEach((assignButton) => {
        assignButton.classList.remove('disabled')
      })
    }
  }
  assignTemplateToItem(e) {
    const itemId = e.currentTarget.dataset['itemId']
    const templateId = e.currentTarget.dataset['id']
    const data = { expiry_date: this.expiryDateTarget.value, responsible_id: this.responsibleUserTarget.value }
    Rails.ajax({
      url: '/deadline_templates/'+ templateId +'/assign_template/'+ itemId,
      dataType: 'script',
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        options.data = JSON.stringify(data)

        return true
      },
      type: 'POST',
    })
  }
}
