import { Controller } from 'stimulus'

export default class extends Controller {
  /* eslint-disable */
  // This controller allows you to display information depending the status of the switch-box

  // data-controller='switch-box'

  // Add the following to the switch
  //  <div class="form-row" data-controller="switch-box">
  //      <label class="switch col-lg-2 col-sm-3 col-4" >
  //          <%= f.check_box :active, checked: ActiveModel::Type::Boolean.new.cast(f.object.active_before_type_cast), data: {'switch-box-target': 'switch', action: 'change->switch-box#toggle'} %>
  //          <span class="mr-2"></span>
  //          <strong data-switch-box-target="checkedDisplayValue"><%= Container.human_attribute_name(:active) %></strong>
  //          <strong data-switch-box-target="uncheckedDisplayValue"><%= Container.human_attribute_name(:inactive) %></strong>
  //      </label>
  //      <small class="d-none offset-xl-3 offset-lg-4 col-md-9 col-8" data-switch-box-target="uncheckedExplenationText">TEXT</small
  //  </div>
  /* eslint-enable */

  static targets = [
    'switch', 'checkedDisplayValue', 'uncheckedDisplayValue',
    'checkedExplenationText', 'uncheckedExplenationText',
  ]

  static values = {
    url: String,
    searchbar: Boolean,
    turboFrameName: String,
    resourceId: String,
    resourceType: String,
    attachmentTag: String,
  }

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.switchTarget.checked == true) {
      this.checked()
    } else {
      this.unchecked()
    }
  }

  submitToggleForm() {
    const params = new URLSearchParams({
      sensitive: this.switchTarget.checked,
      searchbar: this.searchbarValue,
      resource_id: this.resourceIdValue,
      resource_type: this.resourceTypeValue,
      attachment_tag: this.attachmentTagValue,
      turbo_frame: this.turboFrameNameValue,
    })
    const urlWithQuery = `${this.urlValue}?${params}`

    fetch(urlWithQuery, {
      method: 'GET',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
      },
    })
  }

  checked() {
    if (this.hasUncheckedDisplayValueTarget) {
      this.uncheckedDisplayValueTarget.classList.add('d-none')
    }
    if (this.hasCheckedDisplayValueTarget) {
      this.checkedDisplayValueTarget.classList.remove('d-none')
    }
    if (this.hasCheckedExplenationTextTarget) {
      this.checkedExplenationTextTarget.classList.remove('d-none')
    }
    if (this.hasUncheckedExplenationTextTarget) {
      this.uncheckedExplenationTextTarget.classList.add('d-none')
    }
  }

  unchecked() {
    if (this.hasUncheckedDisplayValueTarget) {
      this.uncheckedDisplayValueTarget.classList.remove('d-none')
    }
    if (this.hasCheckedDisplayValueTarget) {
      this.checkedDisplayValueTarget.classList.add('d-none')
    }
    if (this.hasCheckedExplenationTextTarget) {
      this.checkedExplenationTextTarget.classList.add('d-none')
    }
    if (this.hasUncheckedExplenationTextTarget) {
      this.uncheckedExplenationTextTarget.classList.remove('d-none')
    }
  }
}
