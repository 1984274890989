import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'dropdown', 'searchInput', 'options']

  connect() {
    if (this.hasSelectTarget) {
      this.selectTarget.style.display = 'none'
    }
    this.createCustomSelect()

    // Add click outside listener
    document.addEventListener('click', this.handleClickOutside.bind(this))
  }

  disconnect() {
    // Clean up listener when controller is disconnected
    document.removeEventListener('click', this.handleClickOutside.bind(this))
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.classList.add('tw-hidden')

      // Clear search when closing
      this.searchInputTarget.value = ''
      Array.from(this.optionsTarget.children).forEach((option) => {
        option.style.display = 'block'
      })
    }
  }

  toggleDropdown(event) {
    // Stop event from bubbling to document
    event.stopPropagation()

    const dropdown = this.dropdownTarget
    const isHidden = dropdown.classList.contains('tw-hidden')

    // Close all other dropdowns first
    document.querySelectorAll('[data-select-fe-search-target="dropdown"]').forEach((el) => {
      el.classList.add('tw-hidden')
    })

    if (isHidden) {
      dropdown.classList.remove('tw-hidden')
      this.searchInputTarget.focus()
    } else {
      dropdown.classList.add('tw-hidden')
    }
  }

  createCustomSelect() {
    const wrapper = document.createElement('div')
    wrapper.className = 'tw-relative tw-w-full'

    // Create selected display
    const selected = document.createElement('div')
    selected.className = `tw-relative tw-flex tw-items-center tw-justify-between tw-py-1.5 tw-px-3 tw-w-full tw-cursor-default
    tw-rounded-md tw-bg-white tw-text-left tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-slate-300
    focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500 tw-text-base sm:tw-text-sm tw-leading-[30px]
    sm:tw-leading-[26px]`

    const selectedText = document.createElement('span')
    selectedText.className = 'tw-block tw-truncate'
    selectedText.textContent = this.selectTarget.options[this.selectTarget.selectedIndex].text

    const icon = document.createElement('span')
    icon.className = 'tw-pointer-events-none tw-ml-2'
    icon.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="tw-h-5 tw-w-5 tw-text-gray-400"
      viewBox="0 0 20 20" aria-hidden="true">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 8l4 4 4-4"></path>
      </svg>
    `

    selected.appendChild(selectedText)
    selected.appendChild(icon)
    selected.setAttribute('data-action', 'click->select-fe-search#toggleDropdown')

    // Create dropdown
    const dropdown = document.createElement('div')
    dropdown.className = 'tw-hidden tw-absolute tw-z-10 tw-w-full tw-mt-1 tw-bg-white tw-rounded-lg tw-shadow-lg'
    dropdown.setAttribute('data-select-fe-search-target', 'dropdown')

    // Create search input
    const searchWrapper = document.createElement('div')
    searchWrapper.className = 'tw-p-2 tw-border-b tw-border-gray-200'

    const search = document.createElement('input')
    search.className = `tw-w-full tw-px-3 tw-py-2 tw-text-sm tw-border tw-border-slate-300 tw-rounded-md
    focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-blue-500 focus:tw-border-blue-500`
    search.placeholder = 'Search...'
    search.setAttribute('data-select-fe-search-target', 'searchInput')
    search.setAttribute('data-action', 'input->select-fe-search#filterOptions')

    searchWrapper.appendChild(search)

    // Create options container
    const optionsContainer = document.createElement('div')
    optionsContainer.className = 'tw-max-h-60 tw-overflow-auto tw-py-1'
    optionsContainer.setAttribute('data-select-fe-search-target', 'options')

    // Add options
    Array.from(this.selectTarget.options).forEach((option) => {
      const optionElement = document.createElement('div')
      optionElement.className = `tw-px-4 tw-py-2 tw-text-sm tw-text-gray-900 hover:tw-bg-slate-100
        tw-cursor-pointer`
      optionElement.textContent = option.text
      optionElement.setAttribute('data-value', option.value)
      optionElement.setAttribute('data-action', 'click->select-fe-search#selectOption')
      optionsContainer.appendChild(optionElement)
    })

    dropdown.appendChild(searchWrapper)
    dropdown.appendChild(optionsContainer)
    wrapper.appendChild(selected)
    wrapper.appendChild(dropdown)

    this.selectTarget.parentNode.insertBefore(wrapper, this.selectTarget)
  }

  toggleDropdown(event) {
    const dropdown = this.dropdownTarget
    const isHidden = dropdown.classList.contains('tw-hidden')

    // Close all other dropdowns first
    document.querySelectorAll('[data-select-fe-search-target="dropdown"]').forEach((el) => {
      el.classList.add('tw-hidden')
    })

    if (isHidden) {
      dropdown.classList.remove('tw-hidden')
      this.searchInputTarget.focus()
    } else {
      dropdown.classList.add('tw-hidden')
    }
  }

  filterOptions(event) {
    const searchTerm = event.target.value.toLowerCase()
    const options = this.optionsTarget.children

    Array.from(options).forEach((option) => {
      const text = option.textContent.toLowerCase()
      option.style.display = text.includes(searchTerm) ? 'block' : 'none'
    })
  }

  selectOption(event) {
    const selectedValue = event.target.dataset.value
    const selectedText = event.target.textContent

    // Update native select
    this.selectTarget.value = selectedValue

    // Update custom select display
    event.target.closest('.tw-relative')
      .querySelector('span').textContent = selectedText

    // Hide dropdown
    this.dropdownTarget.classList.add('tw-hidden')

    // Clear search
    this.searchInputTarget.value = ''
    Array.from(this.optionsTarget.children).forEach((option) => {
      option.style.display = 'block'
    })

    // Dispatch change event
    this.selectTarget.dispatchEvent(new Event('change'))
  }
}
