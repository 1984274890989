import { Controller } from 'stimulus'
import { each } from 'lodash'

import {
  toggleElementVisibility, hideElement, setElementCssText,
  appendElementCssText,
} from '../utils/html_element_helpers'

const LEFT_ADDRESS_POSITION = 'left'

export default class extends Controller {
  static targets = ['privateAddress', 'companyAddress', 'addressLabel', 'placeAndTime', 'logoWrapper']

  connect() {
    hideElement(this.privateAddressTarget)
  }

  toggleAddressTypeVisibility() {
    toggleElementVisibility(this.privateAddressTarget)
    toggleElementVisibility(this.companyAddressTarget)
  }

  toggleAddressPosition({ params: { addressPosition: addressPositionParam } }) {
    if (addressPositionParam == LEFT_ADDRESS_POSITION) {
      each([this.addressLabelTarget, this.privateAddressTarget, this.companyAddressTarget], function(target) {
        appendElementCssText(target, 'margin-left: 0')
      })

      setElementCssText(this.placeAndTimeTarget, 'margin-left: 60%')
      setElementCssText(this.logoWrapperTarget, 'margin-left: 60%; margin-right: 0')
      return
    }

    each([this.addressLabelTarget, this.privateAddressTarget, this.companyAddressTarget], function(target) {
      appendElementCssText(target, 'margin-left: 60%')
    })

    setElementCssText(this.placeAndTimeTarget, 'margin-left: 0')
    setElementCssText(this.logoWrapperTarget, 'margin-left: 0; margin-right: 60%')
  }
}
