import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
    static targets = ['nestedSortable'];

    connect() {
      for (let i = 0; i < this.nestedSortableTargets.length; i++) {
        new Sortable(this.nestedSortableTargets[i], {
          group: 'nested',
          animation: 150,
          fallbackOnBody: true,
          swapThreshold: 0.65,
        })
      }

      Sortable.create(this.element)
    }
}
