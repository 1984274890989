/* !
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.6
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// import $ from 'jquery';
import initSidebar from './sidebar'
import initTriggerResize from './trigger-resize'
import { StateToggler, initToggleState } from './toggle-state'
import { initCardDismiss, initCardCollapse, initCardRefresh } from './card-tools'
import initBootstrap from './bootstrap-start'
import { initNotify } from './notify'
import locationSelectorToggle from './topnavbar'

export default function initApp() {
  // Init modules
  initBootstrap()
  initSidebar()
  initTriggerResize()
  initToggleState()
  initNotify()
  // // card tools
  initCardDismiss()
  initCardCollapse()
  initCardRefresh()
  locationSelectorToggle()
}
