export class SearchSelectService {
  constructor(controller, params) {
    this.controller = controller
    this.params = params
    this.selectedItems = []
  }

  diselectAll(){
    this.controller.selectRowTargets.forEach((row) => {
      row.checked = false
    })
    this.selectedItems = []
    this.hideSelectedMenu()
  }

  allCheckboxesSelected(){
    let total = this.controller.selectRowTargets.length
    let selected = 0
    this.controller.selectRowTargets.forEach((row) => {
      if (row.checked){
        selected = selected + 1
      }
    })
    return total == selected
  }

  selectAllToggle(e){
    this.selectedItems = []
    const checked = e.target.checked
    this.controller.selectRowTargets.forEach((row) => {
      row.checked = checked
      if (checked) {
        this.selectedItems.push(row.dataset.id)
      }
    })
    if (this.selectedItems.length > 0) {
      this.showSelectedMenu()
    }
    else {
      this.hideSelectedMenu()
    }
  }

  selectRowToggle(e){
    const id = e.target.dataset.id
    if (e.target.checked) {
      this.selectedItems.push(id)
      this.showSelectedMenu()
    }
    else {
      this.selectedItems = this.selectedItems.filter((item) => item !== id)
    }
    if (this.selectedItems.length > 0) {
      this.showSelectedMenu()
      if (this.allCheckboxesSelected()){
        this.controller.selectAllRowsTarget.checked = true
      }
      else {
        this.controller.selectAllRowsTarget.indeterminate = true
      }
    }
    else {
      this.hideSelectedMenu()
    }
  }

  batchDeleteShowModal(e){
    e.preventDefault()
    e.stopPropagation()
    const html = window.I18n.t("js.index_delete_confirmation_items", { count: this.selectedItems.length })
    this.controller.batchDeleteTextTarget.innerHTML = html
    this.controller.batchDeleteModalTarget.classList.remove('tw-hidden')
  }

  batchDeleteHideModal(e){
    e.preventDefault()
    this.controller.batchDeleteModalTarget.classList.add('tw-hidden')
  }

  batchCopyShowModal(e){
    e.preventDefault()
    const html = window.I18n.t("js.index_copy_confirmation_items", { count: this.selectedItems.length })
    this.controller.batchCopyTextTarget.innerHTML = html
    this.controller.batchCopyModalTarget.classList.remove('tw-hidden')
  }

  batchCopyHideModal(e){
    e.preventDefault()
    this.controller.batchCopyModalTarget.classList.add('tw-hidden')
  }

  batchDeleteConfirmationModalShow(e){
    e.stopPropagation()
    const html = window.I18n.t("js.index_delete_last_confirmation_items", { count: this.selectedItems.length })
    this.controller.batchDeleteConfirmationTextTarget.innerHTML = html
    this.controller.batchDeleteConfirmationModalTarget.classList.remove('tw-hidden')
  }

  batchDeleteConfirmationModalHide(e){
    this.controller.batchDeleteConfirmationModalTarget.classList.add('tw-hidden')
  }

  batchDeleteItems(e){
    e.preventDefault()
    const input = this.controller.batchDeleteConfirmationModalTarget.querySelector('input[name="item[ids]"]')
    input.value = this.selectedItems.join(',')
    if (this.controller.confirmationDeleteInputTarget.value == this.controller.deleteTextValue) {
      e.target.disabled = true
      this.controller.batchDeleteConfirmationModalTarget.querySelector('form').submit()
    }
    else {
      this.controller.confirmationDeleteInputTarget.classList.add('border-red-500')
      this.controller.confirmationDeleteInputTarget.value = ''
      this.controller.confirmationDeleteInputTarget.focus()
    }
  }

  batchCopyItems(e){
    e.preventDefault()
    const input = this.controller.batchCopyModalTarget.querySelector('input[name="item[ids]"]')
    input.value = this.selectedItems.join(',')
    e.target.disabled = true
    this.controller.batchCopyModalTarget.querySelector('form').submit()
  }

  massEditShowModal(e){
    e.preventDefault()
    let url = this.controller.batchEditUrlValue
    const delimiter = url.includes('?') ? '&' : '?';
    url += delimiter + 'ids=' + this.selectedItems.join(',')

    fetch(url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  massEditCloseModalOutside(e){
    if (e.target.attributes['modal-close']){
      e.preventDefault()
      this.controller.massEditModalTarget.classList.add('tw-hidden')
    }
  }
  massEditCloseModal(e){
    e.preventDefault()
    this.controller.massEditModalTarget.classList.add('tw-hidden')
  }

  massUpdate(e){
    e.preventDefault()
    e.target.disabled = true
    this.controller.editBatchFormTarget.submit()
  }

  showSelectedMenu(){
    let count = this.selectedItems.length
    const text = window.I18n.t("js.index_selected_items", { count: count })
    this.controller.selectedItemsCountTarget.innerText = text
    this.controller.selectedActionsTarget.classList.remove('tw-hidden')
  }

  hideSelectedMenu(){
    if (this.controller.hasSelectedActionsTarget) {
      this.controller.selectedActionsTarget.classList.add('tw-hidden')
    }
  }
}
