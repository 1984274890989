import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['names', 'select', 'form']
  static values = { searchId: String }

  toggle(event) {
    event.stopPropagation()
    this.namesTarget.classList.toggle('tw-hidden')
    this.selectTarget.classList.toggle('tw-hidden')
  }

  close(event) {
    const contentId = `responsible-inline-container-${this.searchIdValue}`
    const content = document.getElementById(contentId)

    if (!content.contains(event.target)) {
      this.namesTarget.classList.remove('tw-hidden')
      this.selectTarget.classList.add('tw-hidden')
    }
  }

  submit(event, data) {
    event.stopPropagation()

    this.formTarget.requestSubmit()
  }
}
