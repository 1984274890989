import { LocaleManager } from '@bryntum/scheduler/scheduler.module.min.js'

const Lt = {
  localeName: 'Lt',
  localeDesc: 'Lithuanian',
  localeCode: 'lt',

  Object: {
    Yes: 'Taip',
    No: 'Ne',
    Cancel: 'Atšaukti',
    Ok: 'Gerai',
    Week: 'Savaitė',
    newEvent: 'Naujas įvykis',
  },

  Field: {
    badInput: 'Netinkama lauko vertė',
    patternMismatch: 'Vertė turi atitikti konkretų šabloną',
    rangeOverflow: function(e) {
      return 'Vertė turi būti mažesnė arba lygi '.concat(e.max)
    },
    rangeUnderflow: function(e) {
      return 'Vertė turi būti didesnė arba lygi '.concat(e.min)
    },
    fieldRequired: 'Šis laukas yra privalomas',
    invalidValue: 'Netinkama lauko vertė',
    maximumValueViolation: 'Didžiausios vertės pažeidimas',
    minimumValueViolation: 'Mažiausios vertės pažeidimas',
    stepMismatch: 'Vertė turi atitikti žingsnį',
    tooLong: 'Vertė turi būti trumpesnė',
    tooShort: 'Vertė turi būti ilgesnė',
    typeMismatch: 'Reikalinga specialaus formato vertė',
    validateFilter: 'Vertė turi būti pasirinkta iš sąrašo',
    valueMissing: 'Šis laukas yra privalomas',
  },

  Scheduler: {
    assignedTo: 'Priskirta',
    checkin_item_first: 'Pirmiausia užbaikite grąžinimo procesą.',
    dragValidation: 'Negalima planuoti praėjusių datų',
    split: 'Dalinti',
    barcode: 'Brūkšninis kodas',
    category: 'Kategorija',
    checkin: 'Grąžinti',
    checkout: 'Priskirti',
    remarks: 'Komentarai',
    responsible: 'Registruoti atsakingi asmenys',
    scheduled_by: 'Sukūrė',
    serial_number: 'Serijos numeris',
    time: 'Laikas',
  },

  ColumnPicker: {
    column: 'Stulpelis',
    columnsMenu: 'Stulpeliai',
    hideColumn: 'Slėpti stulpelį',
    hideColumnShort: 'Slėpti',
    newColumns: 'Nauji stulpeliai',
  },

  Sort: {
    addSortAscending: 'Pridėti didėjantį rikiavimą',
    addSortAscendingShort: '+ Didėjantis',
    addSortDescending: 'Pridėti mažėjantį rikiavimą',
    addSortDescendingShort: '+ Mažėjantis',
    multiSort: 'Kelių rikiavimas',
    removeSorter: 'Pašalinti rikiuotoją',
    removeSorterShort: 'Pašalinti',
    sort: 'Rikiuoti',
    sortAscending: 'Rikiuoti didėjančia tvarka',
    sortAscendingShort: 'Didėjantis',
    sortDescending: 'Rikiuoti mažėjančia tvarka',
    sortDescendingShort: 'Mažėjantis',
    toggleSortAscending: 'Perjungti į didėjantį',
    toggleSortDescending: 'Perjungti į mažėjantį',
  },

  EventEdit: {
    'Name': 'Pavadinimas',
    'Resource': 'Išteklius',
    'Start': 'Pradžia',
    'End': 'Pabaiga',
    'Save': 'Išsaugoti',
    'Delete': 'Ištrinti',
    'Cancel': 'Atšaukti',
    'Edit event': 'Redaguoti įvykį',
    'Repeat': 'Kartoti',
  },

  SchedulerBase: {
    'Add event': 'Pridėti įvykį',
    'Delete event': 'Ištrinti įvykį',
    'Unassign event': 'Panaikinti įvykio priskyrimą',
  },

  TimeAxisHeaderMenu: {
    pickZoomLevel: 'Priartinimas',
    activeDateRange: 'Datos diapazonas',
    startText: 'Pradžios data',
    endText: 'Pabaigos data',
    todayText: 'Šiandien',
  },

  EventCopyPaste: {
    copyEvent: 'Kopijuoti įvykį',
    cutEvent: 'Iškirpti įvykį',
    pasteEvent: 'Įklijuoti įvykį',
  },

  Group: {
    group: 'Grupė',
    groupAscending: 'Grupės didėjimas',
    groupAscendingShort: 'Didėjantis',
    groupDescending: 'Grupės mažėjimas',
    groupDescendingShort: 'Mažėjantis',
    stopGrouping: 'Sustabdyti grupavimą',
    stopGroupingShort: 'Sustabdyti',
  },

  GridBase: {
    loadFailedMessage: 'Nepavyko įkelti duomenų!',
    moveColumnLeft: 'Perkelti į kairę',
    moveColumnRight: 'Perkelti į dešinę',
    networkFailure: 'Tinklo klaida',
    noRows: 'Nėra planuojamų objektų, kuriuos būtų galima parodyti',
    parseFailure: 'Nepavyko išanalizuoti serverio atsakymo',
    serverResponse: 'Serverio atsakymas:',
    syncFailedMessage: 'Nepavyko sinchronizuoti duomenų!',
    unspecifiedFailure: 'Nenurodyta klaida',
    moveColumnTo: function(e) {
      return 'Perkelti stulpelį į '.concat(e)
    },
  },

  EventFilter: {
    filterEvents: 'Filtruoti užduotis',
    byName: 'Pagal pavadinimą',
  },

  TimeRanges: {
    showCurrentTimeLine: 'Rodyti dabartinę laiko juostą',
  },
}

LocaleManager.registerLocale('Lt', { locale: Lt })
