// TOGGLE STATE of sidebar
// -----------------------------------

import Storages from 'js-storage'

function initToggleState() {
  const $body = $('body')
  const toggle = new StateToggler()

  $('[data-toggle-state]')
      .on('click', function(e) {
        // e.preventDefault();
        e.stopPropagation()
        const element = $(this)
        const classname = element.data('toggleState')
        const target = element.data('target')
        const noPersist = !!element.attr('data-no-persist')

        // Specify a target selector to toggle classname
        // use body by default
        const $target = target ? $(target) : $body

        if (classname) {
          if ($target.hasClass(classname)) {
            $target.removeClass(classname)
            if (!noPersist) {
              toggle.removeState(classname)
            }
          } else {
            $target.addClass(classname)
            if (!noPersist) {
              toggle.addState(classname)
            }
          }
        }

        // some elements may need this when toggled class change the content size
        if (typeof(Event) === 'function') { // modern browsers
          window.dispatchEvent(new Event('resize'))
        } else { // old browsers and IE
          const resizeEvent = window.document.createEvent('UIEvents')
          resizeEvent.initUIEvent('resize', true, false, window, 0)
          window.dispatchEvent(resizeEvent)
        }
      })
}

// Handle states to/from localstorage
function StateToggler() {
  const STORAGE_KEY_NAME = 'jq-toggleState'

  /** Add a state to the browser storage to be restored later */
  this.addState = function(classname) {
    let data = Storages.localStorage.get(STORAGE_KEY_NAME)
    if (data instanceof Array) data.push(classname)
    else data = [classname]
    Storages.localStorage.set(STORAGE_KEY_NAME, data)
  }
  /** Remove a state from the browser storage */
  this.removeState = function(classname) {
    const data = Storages.localStorage.get(STORAGE_KEY_NAME)
    if (data) {
      const index = data.indexOf(classname)
      if (index !== -1) data.splice(index, 1)
      Storages.localStorage.set(STORAGE_KEY_NAME, data)
    }
  }
  /** Load the state string and restore the classlist */
  this.restoreState = function($elem) {
    const data = Storages.localStorage.get(STORAGE_KEY_NAME)
    if (data instanceof Array) {
      $elem.addClass(data.join(' '))
    }
  }
};

export { StateToggler, initToggleState }
