function locationSelectorToggle() {
  let locationSelectorToggleElement

  // Toggle to hidden nav-items and showing the select box
  locationSelectorToggleElement = document.getElementById('location-selector-icon')
  if (locationSelectorToggleElement != null) {
    document.getElementById('location-selector-icon').addEventListener('click', function() {
      let el
      let i

      el = document.querySelectorAll('#navbar-right li.nav-item')
      for (i = 0; i < el.length; i++ ) {
        el[i].style.display = 'none'
      }

      document.getElementById('location-selector-wrapper').classList.remove('d-none')
      document.getElementById('location-selector-cancel-icon').classList.remove('d-md-none')
    })
  }

  // Return to "normal state". Show nav-items and hide the select box (on small devices)
  locationSelectorToggleElement = document.getElementById('location-selector-cancel-icon')
  if (locationSelectorToggleElement != null) {
    document.getElementById('location-selector-cancel-icon').addEventListener('click', function() {
      let el
      let i

      el = document.querySelectorAll('#navbar-right li.nav-item')
      for (i = 0; i < el.length; i++ ) {
        el[i].style.removeProperty('display')
      }

      document.getElementById('location-selector-wrapper').classList.add('d-none')
      document.getElementById('location-selector-cancel-icon').classList.add('d-md-none')
    })
  }
}

export default locationSelectorToggle
