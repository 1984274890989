import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['children', 'parentCheckbox'];

  toggle(event) {
    event.preventDefault()
    const button = event.currentTarget
    const children = this.getChildren(button)

    const isHidden = children.classList.toggle('tw-hidden')
    button.classList.toggle('tw-rotate-90', !isHidden)
  }

  parentChanged(event) {
    event.preventDefault()
    const parentCheckbox = event.currentTarget
    const isChecked = parentCheckbox.checked

    const childCheckboxes = this.getChildCheckboxes(parentCheckbox)
    childCheckboxes.forEach((checkbox) => {
      checkbox.checked = isChecked
      checkbox.indeterminate = false
    })

    if (!event.currentTarget.classList.contains('root-element')) {
      this.updateParents(parentCheckbox)
    }

    const windowEvent = new CustomEvent('tree-changed', {
      detail: {},
    })
    window.dispatchEvent(windowEvent)
  }

  childChanged(event) {
    event.preventDefault()

    const parentCheckbox = event.currentTarget.closest('ul').parentElement.querySelector('input[type="checkbox"]')
    const parentLi = event.currentTarget.closest('ul').parentElement

    if (!event.currentTarget.classList.contains('root-element')) {
      this.updateParentCheckboxState(parentLi)
      this.updateParents(parentCheckbox)
    }

    const windowEvent = new CustomEvent('tree-changed', {
      detail: {},
    })
    window.dispatchEvent(windowEvent)
  }

  parentCheckboxTargetConnected(element) {
    if (element.dataset.indeterminate) {
      element.indeterminate = true
    }
  }

  updateParentCheckboxState(parentLi) {
    const nestedUl = parentLi.querySelector('ul[data-tree-target="children"]')

    const childCheckboxes = nestedUl.querySelectorAll('li > div > input[type="checkbox"]')
    const allChecked = Array.from(childCheckboxes).every((checkbox) => checkbox.checked)
    const someChecked = Array.from(childCheckboxes).some((checkbox) => checkbox.checked)

    const parentCheckbox = this.getParentCheckbox(parentLi)

    parentCheckbox.indeterminate = !allChecked && someChecked
    parentCheckbox.checked = allChecked
  }

  updateParents(checkbox) {
    console.log('updateParents', checkbox)
    let parentLi = this.getParentLi(checkbox)

    while (parentLi) {
      if (parentLi.tagName.toLowerCase() !== 'li') {
        break
      }
      this.updateParentCheckboxState(parentLi)
      parentLi = this.getParentLi(parentLi)
    }
  }

  getChildren(button) {
    return button.closest('li').querySelector('ul')
  }

  getParentLi(element) {
    return element.closest('ul')?.parentElement
  }

  getParentCheckbox(parentLi) {
    return parentLi.querySelector('input[type="checkbox"]')
  }

  getChildCheckboxes(parentCheckbox) {
    return parentCheckbox.closest('li').querySelectorAll('input[type="checkbox"]')
  }
}
