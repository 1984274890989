import { Controller } from 'stimulus'

export default class extends Controller {
  passwordVisibility(event) {
    const element = event.target
    const input = element.parentElement.parentElement.parentElement.querySelector('input')

    this.toggleEye(element)
    input.type = input.type == 'text' ? 'password' : 'text'
  }
  toggleEye(element) {
    if (element.classList.contains('fa-eye')) {
      element.classList.remove('fa-eye')
      element.classList.add('fa-eye-slash')
    } else {
      element.classList.add('fa-eye')
    }
  }
}
