import { ScanditScanner } from '../library/scandit_scanner'
import isMobile from '../utils/is-mobile'
import { setCookie, getCookie } from '../utils/cookies_helper'

const SCANNER_HTML_ELEMENT = 'scandit-barcode-picker'

export class ScannerManager {
  constructor(params) {
    this.selectedCameraText = params.selectedCameraText
    this.manualBarcodeInputField = params.manualBarcodeInputField
    this.gpsNotification = params.gpsNotification
    this.trackGpsLocationValue = params.trackGpsLocationValue
    this.scanResult = params.scanResult
  }

  configure(data) {
    this.manualBarcodeSubmitValue = data.manual_barcode_submit
    this.trackGpsLocationValue = data.track_gps_location
  }

  stopScanner() {
    if (!this.scanner) {
      return
    }
    this.scanner.off('scan')
    this.availableCameras = []

    if (this.scanner) {
      this.scanner.turnOff()
    }
  }

  async startScanner() {
    this.stopScanner()
    const scannerElement = document.getElementById(SCANNER_HTML_ELEMENT)
    this.scanner = new ScanditScanner()
    this.barcodeCapture = await this.scanner.init(scannerElement)
    this.scanner.on('scan', (scanResult) => {
      const result = { text: scanResult.data, symbology: scanResult.symbology }
      this.scannerSuccessfullRead(result)
      this.scanResult.process(result)
    })
    const cameras = await this.scanner.availableCameras()
    this.setAvailableCameras(cameras)

    this.handleManualBarcodeInput()
    this.gpsLocationTracker()
  }

  selectCamera(event) {
    const target = event.currentTarget
    const cameraId = target.dataset['cameraId']
    const camera = this.availableCameras.filter((camera) => camera.deviceId === cameraId)[0]
    this.selectedCameraText.innerHTML = camera.label
    this.scanner.setActiveCamera(camera)
    setCookie('cameraId', cameraId)
  }

  setAvailableCameras(cameras) {
    this.availableCameras = cameras
    let html = ''
    cameras.forEach((camera) => {
      let htmlLink = '<a href=\'javascript:void(0)\' class=\'btn dropdown-item\''
      htmlLink = htmlLink + 'data-action=\'click->barcode-scanner#selectCamera\''
      html = html + htmlLink + 'data-camera-id=\'' + camera.deviceId + '\'>' + camera.label + '</a>'
    })
    if (this.scanner && this.scanner.camera) {
      this.selectedCameraText.innerHTML = this.scanner.camera.label
    }
    document.getElementById('availableCameras').innerHTML = html
    if (getCookie('cameraId')) {
      this.selectCamera({ currentTarget: { dataset: { cameraId: getCookie('cameraId') } } })
    }
  }

  // method to visually indicate to the user, that the scan was successful
  scannerSuccessfullRead(result = null) {
    if (result) {
      const cssClass = 'bg-success'
      document.querySelectorAll('.qr-shaded-region div').forEach((flashBar) => {
        flashBar.classList.add(cssClass)
      })
      clearTimeout(this.flashBarTimeout)

      this.flashBarTimeout = setTimeout(function() {
        document.querySelectorAll('.qr-shaded-region div').forEach((flashBar) => {
          flashBar.classList.remove(cssClass)
        })
      }, 400)
    }
  }

  handleManualBarcodeInput() {
    if (isMobile()) {
      return
    }
    // show field for manualBarcodeInput
    if (this.manualBarcodeInputField) {
      setTimeout(() => {
        this.manualBarcodeInputField.focus()
      }, 500)
      if (!this.disableAutoFocus) {
        this.manualBarcodeInputField.focus()
      }
    }
  }

  gpsLocationTracker() {
    // Only launch the GPS tracker, if item is redirect / inventory scanning,
    if (this.trackGpsLocationValue === true && navigator.geolocation) {
      navigator.geolocation.watchPosition(this.getPosition.bind(this), this.showGPSError.bind(this), {
        enableHighAccuracy: true,
      })
      if (this.gpsNotification) {
        this.gpsNotification.innerHTML = window.I18n.t('js.detecting_gps_position')
        this.gpsNotification.className = 'text-muted blink'
      }
    }
  }

  getPosition(position) {
    this.gpsPosition = position.coords
    if (this.gpsNotification) {
      this.gpsNotification.innerHTML = '<i class="far fa-location-dot mr-1"></i>' +
        window.I18n.t('js.gps_position_detected')
      this.gpsNotification.className = ('text-success')
      this.scanResult.updateGpsPosition(this.gpsPosition)
    }
  }

  showGPSError(error) {
    let errorMessage
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage = window.I18n.t('js.activate_gps_browser')
        break
      case error.POSITION_UNAVAILABLE:
        errorMessage = window.I18n.t('js.location_info_not_available')
        break
      case error.TIMEOUT:
        errorMessage = window.I18n.t('js.location_info_timedout')
        break
      case error.UNKNOWN_ERROR:
        errorMessage = window.I18n.t('js.unknown_error')
        break
    }
    this.gpsNotification.innerHTML = '<i class="fas fa-times-circle mr-2"></i>' + errorMessage
    this.gpsNotification.className = 'text-danger'
  }
}
