import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['locationInput', 'responsibleUserInput']

  static values = {
    responsiblesMap: Object,
    ticketId: Number,
    ticketTypeId: Number,
  }

  updateResponsibleUserInput() {
    const selectedLocation = this.locationInputTarget.value
    if (
      !this.responsiblesMapValue[selectedLocation] &&
      !this.responsiblesMapValue['global_responsible']
    ) {
      this.updateBlankOption('')
      return
    } else if (
      !this.responsiblesMapValue[selectedLocation] &&
      this.responsiblesMapValue['global_responsible']
    ) {
      const userName = this.responsiblesMapValue['global_responsible']
      const nexTextValue = window.I18n.t('js.default_responsible', {
        user_name: userName,
      })

      this.updateBlankOption(nexTextValue)
    } else {
      const userName = this.responsiblesMapValue[selectedLocation]

      const nexTextValue = window.I18n.t('js.default_responsible', {
        user_name: userName,
      })
      this.updateBlankOption(nexTextValue)
    }
  }

  updateBlankOption(userName) {
    const selectedOptionValue = this.responsibleUserInputTarget.value
    // Delete the blank option
    this.responsibleUserInputTarget.options[0].remove()
    // Create a new blank option with edited text
    const newBlankOption = document.createElement('option')
    newBlankOption.value = ''
    newBlankOption.text = userName
    // Append the new blank option at the beginning
    this.responsibleUserInputTarget.insertBefore(
      newBlankOption,
      this.responsibleUserInputTarget.firstChild,
    )
    // Select the new blank option
    this.responsibleUserInputTarget.value = selectedOptionValue
  }

  updateLocation(e) {
    if (e.detail.mapped_values) {
      this.responsiblesMapValue = JSON.parse(
        e.detail.mapped_values.replace(/&quot;/g, '"'),
      )
    } else {
      this.responsiblesMapValue = {}
    }
    this.updateSelectedResponsible()
  }
}
