import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="price-calculator"
export default class extends Controller {
  static targets = [
    'priceWithVat',
    'priceWithoutVat',
    'vat',
    'vatAmount',
    'currency',
  ];

  static values = {
    itemId: String,
  };

  connect() {
    this.timeout = null
    this.startVat = parseFloat(this.vatTarget.value) || null
  }

  calculatePriceVat(event) {
    // Clear the previous timeout
    clearTimeout(this.timeout)

    // Set a new timeout
    this.timeout = setTimeout(() => {
      // Do not calculate when character is '.' or ','
      const lastChar = event.target.value.slice(-1)
      if (lastChar === '.' || lastChar === ',') {
        return
      }

      const priceWithVat = this.priceWithVatTarget.value
      const priceWithoutVat = this.priceWithoutVatTarget.value
      const vat = this.vatTarget.value
      let changedAttribute = ''

      const valuesPresent = [priceWithVat, priceWithoutVat, vat].filter((value) => value !== '').length

      // Only proceed if at least two values are present
      if (valuesPresent < 2) {
        return
      }

      if (event.target === this.priceWithVatTarget) {
        changedAttribute = 'price_with_vat'
      } else if (event.target === this.priceWithoutVatTarget) {
        changedAttribute = 'price_without_vat'
      } else if (event.target === this.vatTarget) {
        changedAttribute = 'vat'
      }

      const href = '/items/calculate_price_vat'
      const formData = new FormData()

      formData.append('item[changed_attribute]', changedAttribute)
      formData.append('item[price_with_vat]', priceWithVat)
      formData.append('item[price_without_vat]', priceWithoutVat)
      formData.append('item[vat]', vat)

      fetch(href, {
        method: 'POST',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (changedAttribute !== 'price_with_vat') {
            this.priceWithVatTarget.value = data.price_with_vat
          }

          if (changedAttribute !== 'price_without_vat') {
            this.priceWithoutVatTarget.value = data.price_without_vat
          }

          if (changedAttribute !== 'vat') {
            this.vatTarget.value = data.vat
          }

          this.vatAmountTarget.value = data.vat_amount
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }, 500) // Delay in milliseconds (e.g., 500ms)
  }

  updateCurrency() {
    const selectBox = this.currencyTarget
    Array.from(document.getElementsByClassName('symbol')).forEach((el) => {
      el.innerHTML = selectBox.options[selectBox.options['selectedIndex']].dataset['symbol']
    })
    Array.from(document.getElementsByClassName('currency')).forEach((el) => {
      el.innerHTML = this.currencyTarget.value
    })
  }
}
