import German from '@uppy/locales/lib/de_DE'
import French from '@uppy/locales/lib/fr_FR'
import English from '@uppy/locales/lib/en_US'
import Spanish from '@uppy/locales/lib/es_ES'
import { merge } from 'lodash'

const setLocale = function() {
  updatedTranslation()
  const locale = { 'de': German, 'en': English, 'fr': French, 'es': Spanish }
  return locale[window.I18n.locale]
}

const fileTypeMessage = function(fileTypes) {
  return window.I18n.t('js.allowed_file_type') + fileTypes.join(', ')
}

const createHiddenInputElement = function(element, file, fieldName, targetName, allowedMultipleUpload) {
  const hiddenField = document.createElement('input')
  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', fieldName)
  hiddenField.setAttribute('data-pending-upload', true)
  hiddenField.setAttribute(targetName, 'fileInput')
  if (allowedMultipleUpload) {
    hiddenField.setAttribute('multiple', 'multiple')
  }
  hiddenField.setAttribute('value', file.response.signed_id)
  return element.appendChild(hiddenField)
}

const updatedTranslation = function() {
  merge(German.strings, { takePictureBtn: 'Foto aufnehmen' })
  merge(French.strings, { takePictureBtn: 'Prendre une photo' })
  merge(German.strings, { dropPasteImportFiles: 'Dateien hier ablegen, Dateien durchsuchen oder importieren von' })
}

export {
  setLocale,
  fileTypeMessage,
  createHiddenInputElement,
}

