import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['expiryDate', 'form', 'expiryInfo']
    // add the value as follows: data-skill-details-employee-id-value="Employee ID"
    // add the value as follows: data-skill-details-employee-skill-id-value="Employee Skill ID"
    static values = {
      employeeId: Number,
      employeeSkillId: Number,
    }

    connect() {
      this.employeeId = this.employeeIdValue
      this.employeeSkillId = this.employeeSkillIdValue
    }


    toggle() {
      if (this.formTarget.classList.contains('d-none')) {
        this.formTarget.classList.remove('d-none')
        this.expiryInfoTarget.classList.remove('d-flex')
        this.expiryInfoTarget.classList.add('d-none')
      } else {
        this.formTarget.classList.add('d-none')
        this.expiryInfoTarget.classList.remove('d-none')
        this.expiryInfoTarget.classList.add('d-flex')
      }
    }

    submit() {
      const url = '/employees/' + this.employeeId + '/employee_skills/' + this.employeeSkillId
      const data = { employee_skill: { expiry_date: this.expiryDateTarget.value } }
      window.$.ajax({
        context: this,
        url: url,
        type: 'PATCH',
        data: data,
        dataType: 'html',
        success: function(result) {
          $('#employee_id_' + this.employeeId + ' td:last-child').html(result)
        },
      })
    }
}
