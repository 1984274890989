import { Controller } from 'stimulus'

import { ScreenNotification } from '../library/screen_notification'
import { ScannerManager } from '../library/scanner_manager'
import { ScanResult } from '../library/scan_result'

export default class extends Controller {
  // Library: https://www.npmjs.com/package/scandit-sdk
  // Documentation: https://docs.scandit.com/stable/web/modules.html

  // Add data-controller="barcode-scanner" for allowing a barcode scanner

  static targets = ['scanner', 'scanType', 'lastScanTableBody', 'nothingScannedTableNotice',
    'manualBarcodeInputWrapper', 'inventoryToggleWrapper',
    'inventoryTypeWrapper', 'checkoutScannerTextWrapper',
    'checkoutScannerContainerTextWrapper',
    'forcedToggleButton', 'forceCheckOutWrapper',
    'manuelBarcodeInputField', 'gpsNotification',
    'inScreenNotification', 'forceCheckOut', 'remainingItemCount',
    'inventory', 'labelInventoryScanning', 'lastScanTable',
    'selectedCameraText', 'checkoutScannerContainerSuccessTextWrapper',
  ];

  static values = {
    trackGpsLocation: Boolean,
    processMethod: String,
    active: Boolean,
    forwardUrl: String,
    defaultCameraTextValue: String,
    stockItemSpecialCheckoutFormUrl: String,
    responsibleUser: String,
    expiryDate: String,
    checkoutDocument: Array,
    scheduleCheckoutParams: Object,
    useSameDate: { type: Boolean, default: false },
  }

  connect() {
    this.disableAutoFocus = false
    this.screenNotification = new ScreenNotification({
      inScreenNotification: this.inScreenNotificationTarget,
    })
    this.scanResult = new ScanResult({
      useSameDate: this.useSameDateValue,
      checkoutDocumen: this.checkoutDocumentValue,
      scheduleCheckoutParams: this.scheduleCheckoutParamsValue,
      remainingItemCount: this.remainingItemCountTarget,
      nothingScannedTableNotice: this.nothingScannedTableNoticeTarget,
      lastScanTableBody: this.lastScanTableBodyTarget,
      screenNotification: this.screenNotification,
      controllerCallback: this.scanResultEvent.bind(this),
      forceCheckOut: true,
    })
    this.scannerManager = new ScannerManager({
      selectedCameraText: this.selectedCameraTextTarget,
      manualBarcodeInputField: this.manuelBarcodeInputFieldTarget,
      trackGpsLocationValue: this.trackGpsLocationValue,
      gpsNotification: this.gpsNotificationTarget,
      scanResult: this.scanResult,
    })
  }

  scanResultEvent(e, type) {
    if (this.processMethodValue === 'checkout_scanner' || this.processMethodValue === 'checkout_scanner_assign_to_me') {
      this.scanResultCheckoutScanningEvent(e, type)
    }
  }

  scanResultCheckoutScanningEvent(e, type) {
    switch (type) {
      case 'set_checkoutable_resource':
        this.checkoutScannerTextWrapperTarget.classList.add('d-none')
        const text = window.I18n.t('js.scanner_checking_to', { name: e.obj_name })
        const html = '<i class="far ' + e.obj_icon + '"></i> ' + text
        this.checkoutScannerContainerTextWrapperTarget.innerHTML = html
        this.screenNotification.show(html, 'info')
        this.checkoutScannerContainerTextWrapperTarget.classList.remove('d-none')
        break

      case 'no_checkoutable_resource_found':
        this.checkoutScannerTextWrapperTarget.classList.remove('text-secondary')
        this.checkoutScannerTextWrapperTarget.classList.add('text-danger')
        this.checkoutScannerTextWrapperTarget.classList.add('blink')
        this.screenNotification.show(this.checkoutScannerTextWrapperTarget.innerHTML, 'error')
        setTimeout(() => {
          this.checkoutScannerTextWrapperTarget.classList.remove('text-danger')
          this.checkoutScannerTextWrapperTarget.classList.remove('blink')
          this.checkoutScannerTextWrapperTarget.classList.add('text-secondary')
        }, 3000)
        break

      case 'success_checkout_to_resource':
        // const element = this.checkoutScannerContainerSuccessTextWrapperTarget
        // element.classList.remove('d-none')
        const assignedHtml = '<i class="far fa-regular fa-check-circle"></i> ' +
          window.I18n.t('js.checkout') + ' ' + e.item_name + ' | ' + e.barcode + ' ' +
          window.I18n.t('js.to') +' ' + (e.checked_out_by || e.status_changed_by_name || e.resource_name)
        // element.innerHTML = html
        this.screenNotification.show(assignedHtml, 'success')
        break
    }
  }

  disconnect() {
    this.scannerManager.stopScanner()
  }

  handleError = (_error) => {
    this.scannerManager.stopScanner() // eslint-disable-line no-invalid-this
  }

  scannedEventRecieved(e) {
    this.tmpProcessMethod = this.processMethodValue
    this.processMethodValue = 'redirect'
    this.scanResult.process({ text: e.detail.code })
    this.processMethodValue = this.tmpProcessMethod
  }

  configure(data) {
    this.scanResult.configure(data)
    this.scannerManager.configure(data)
    this.processMethodValue = data.process_method
    this.trackGpsLocationValue = data.track_gps_location
    this.manualBarcodeSubmitValue = data.manual_barcode_submit
    this.disableAutoFocus = false
    this.forceToggleButtonConfigurate(data.forced_toggle_button)
    this.remainingToScanConfigure(data.total_scanned_count)
    this.setupManualScanner()
    this.inventoryScanningSetup(this.processMethodValue === 'inventory')
    if (this.processMethodValue === 'redirect' || this.processMethodValue === 'inventory') {
      this.inventoryToggleWrapperTarget.classList.remove('d-none')
    } else {
      this.inventoryToggleWrapperTarget.classList.add('d-none')
    }

    this.hideButtonsForInventoryTake()
    let target = document.getElementById('scannerTypeRedirect')
    if (this.processMethodValue === 'inventory') {
      target = document.getElementById('scannerTypeInventory')
    }

    if (this.processMethodValue === 'inventory_take') {
      target = document.getElementById('scannerTypeInventory')
    }
    this.setScanningType({ params: { value: this.processMethodValue }, currentTarget: target })

    if (this.processMethodValue == 'redirect' || this.processMethodValue == 'checkout_scanner') {
      return
    }
    this.inventoryTypeWrapperTargets.forEach((wrapper) => {
      wrapper.classList.add('d-none')
    })
  }

  hideButtonsForInventoryTake() {
    if (this.processMethodValue === 'inventory_take') {
      if (this.hasForceCheckOutWrapperTarget) { this.forceCheckOutWrapperTarget.classList.add('d-none') }
      if (this.hasInventoryToggleWrapperTarget) { this.inventoryToggleWrapperTarget.classList.add('d-none') }
    } else {
      if (this.hasForceCheckOutWrapperTarget) { this.forceCheckOutWrapperTarget.classList.remove('d-none') }
      if (this.hasInventoryToggleWrapperTarget) { this.inventoryToggleWrapperTarget.classList.remove('d-none') }
    }
  }

  selectCamera(event) {
    this.scannerManager.selectCamera(event)
  }

  remainingToScanConfigure(remainingToScan) {
    if (remainingToScan) {
      this.remainingItemCountTarget.innerHTML = remainingToScan
      this.remainingItemCountTarget.classList.remove('d-none')
    } else {
      this.remainingItemCountTarget.classList.add('d-none')
    }
  }

  forceToggleButtonConfigurate(forcedToggleButton) {
    if (forcedToggleButton) {
      this.forcedToggleButtonValue = forcedToggleButton
      this.forcedToggleButtonTarget.classList.remove('d-none')
    } else {
      this.forcedToggleButtonValue = false
      this.forcedToggleButtonTarget.classList.add('d-none')
    }
  }

  setupManualScanner() {
    if (this.manualBarcodeSubmitValue) {
      this.manualBarcodeInputWrapperTarget.classList.remove('d-none')
    } else {
      this.manualBarcodeInputWrapperTarget.classList.add('d-none')
    }
  }

  launch() {
    $('#scanner').modal('show')
    this.scannerManager.startScanner()
  }

  recieveLaunchEvent(e) {
    switch (e.type) {
      case 'launch-scanner':
        const data = e.detail
        this.configure(data)
        this.launch()
        break
    }
  }

  recieveTearDownEvent(e) {
    e.preventDefault()
    switch (e.type) {
      case 'turn-off-scanner':
        this.tearDown(true)
    }
  }

  resetCheckoutParams(e) {
    e.preventDefault()
    this.scheduleCheckoutParamsValue = {}
    this.useSameDateValue = false
  }

  setScanningType(event) {
    this.inventoryTypeWrapperTargets.forEach((wrapper) => {
      wrapper.classList.remove('active')
    })
    event.currentTarget.classList.add('active')
    this.processMethodValue = event.params.value

    if (typeof event.params.manualBarcodeEntry !== 'undefined') {
      this.manualBarcodeSubmitValue = event.params.manualBarcodeEntry
    }

    this.checkoutScanningSetup()
    this.inventoryScanningSetup(this.processMethodValue === 'inventory')
    this.scanResult.updateProcessMethod(this.processMethodValue)
  }

  checkoutScanningSetup() {
    this.scanResult.checkoutObjectResponse = null
    this.checkoutScannerTextWrapperTarget.classList.add('d-none')
    this.checkoutScannerContainerSuccessTextWrapperTarget.classList.add('d-none')
    this.checkoutScannerContainerTextWrapperTarget.classList.add('d-none')
    if (this.processMethodValue === 'checkout_scanner') {
      this.checkoutScannerTextWrapperTarget.classList.remove('d-none')
    } else {
      this.checkoutScannerTextWrapperTarget.classList.add('d-none')
    }

    if (this.processMethodValue === 'checkout_scanner_assign_to_me') {
      const text = window.I18n.t('js.scanner_checking_to', { name: window.USER_NAME })
      this.checkoutScannerContainerTextWrapperTarget.innerHTML = '<i class="far ' + window.USER_ICON + '"></i> ' + text
      this.checkoutScannerContainerTextWrapperTarget.classList.remove('d-none')
    }
  }

  inventoryScanningSetup(turnOn) {
    if (turnOn) {
      this.lastScanTableTarget.classList.remove('d-none')
    } else {
      this.lastScanTableTarget.classList.add('d-none')
    }
    this.setupManualScanner()
  }

  manualBarcodeSubmit(event) {
    if (event.type == 'click' || event.keyCode == 13) {
      if (this.processMethodValue === 'checkout_scanner' ||
        this.processMethodValue === 'checkout_scanner_assign_to_me') {
        this.scanResult.checkoutScanner({ text: this.manuelBarcodeInputFieldTarget.value })
        this.manuelBarcodeInputFieldTarget.value = ''
      } else {
        this.scanResult.process({ text: this.manuelBarcodeInputFieldTarget.value })
        this.manuelBarcodeInputFieldTarget.value = ''
      }
    }
  }

  // method to visually indicate to the user, that the scan was successful
  scannerSuccessfullRead(result = null) {
    if (result) {
      const cssClass = 'bg-success'
      document.querySelectorAll('.qr-shaded-region div').forEach((flashBar) => {
        flashBar.classList.add(cssClass)
      })
      clearTimeout(this.flashBarTimeout)

      this.flashBarTimeout = setTimeout(function() {
        document.querySelectorAll('.qr-shaded-region div').forEach((flashBar) => {
          flashBar.classList.remove(cssClass)
        })
      }, 400)
    }
  }

  stockItemAssigned(e) {
    e.stopPropagation()
    const processedResponse = e.detail
    this.disableAutoFocus = false
    this.screenNotification.show(processedResponse.message, 'success', processedResponse.checked_out_by)
    setTimeout(() => {
      $('body').addClass('modal-open')
    }, 1000)
  }

  closedStockItemWindow(_e) {
    setTimeout(() => {
      $('body').addClass('modal-open')
    }, 1000)
  }

  schedulableCheckoutCreated(e) {
    const response = e.detail
    this.useSameDateValue = response.use_same_checkout_values
    if (this.useSameDateValue == '1') {
      this.scheduleCheckoutParamsValue = {
        remarks: response.remarks,
        start_date: response.start_date,
        start_time: response.start_time,
        planned_check_in_at: response.planned_check_in_at,
        planned_check_in_at_time: response.planned_check_in_at_time,
      }
      this.checkoutDocumentValue = response.check_out_documents
    }
  }

  schedulableResponse(e) {
    this.screenNotification.show(window.I18n.t('js.barcode_item_checked_out') + e.detail.checked_out_by, 'success')
  }

  tearDown(e) {
    this.scanResult.tearDown(true)

    this.inventoryTypeWrapperTargets.forEach((wrapper) => {
      wrapper.classList.remove('d-none')
    })
    this.scannerManager.stopScanner()
  }

  updateForceCheckOut(e) {
    this.scanResult.forceCheckOut = e.currentTarget.checked
  }


  get forceCheckoutSwitch() {
    if (!this.hasForceCheckOutTarget) return false

    // negated. You have to deactivate warning (--> uncheck) to forceCheckout without warning.
    return !this.forceCheckOutTarget.checked
  }
}
