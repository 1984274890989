import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['query', 'searchItem', 'searchText', 'spinner']

    // HOW IT WORKS //
    // data-controller="search"
    // on the input box with the search string
    // data-search-target="query" data-action="keyup->search#filter"

    // to add a spinner to an element
    // data-search-target='spinner'

    // on the item you want filtered
    // data-search-target="searchItem"

    // the data you want to have searched
    // "data-search-target": "searchText"

    connect() {
      this.timeout = null
      this.delaySearch = 400
    }

    delayedFilter() {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.filter()
      }, this.delaySearch)
    }

    filter() {
      this.addSpinner()
      // Declare variables
      let txtValue; let i
      const regex = new RegExp('\\b' + this.queryTarget.value, 'i')
      // Loop through all list items, and hide those who don't match the search query
      for (i = 0; i < this.searchItemTargets.length; i++) {
        txtValue = this.searchTextTargets[i].textContent || this.searchTextTargets[i].innerText
        if (txtValue.match(regex)) {
          this.searchItemTargets[i].style.display = ''
        } else {
          this.searchItemTargets[i].style.display = 'none'
        }
      }
      this.removeSpinner()
    }

    delayedFilterMultiElements() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.filterMultiElements()
      }, this.delaySearch)
    }

    filterMultiElements() {
      this.addSpinner()
      let txtValue; let i; let j

      const factorSearchItemToSearchText = this.searchTextTargets.length / this.searchItemTargets.length
      const filter = this.queryTarget.value.toUpperCase()
      // Loop through all list items, and hide those who don't match the search query
      for (i = 0; i < this.searchItemTargets.length; i++) {
        for (j = 0; j < factorSearchItemToSearchText; j++) {
          txtValue = this.searchTextTargets[i * factorSearchItemToSearchText + j].textContent ||
            this.searchTextTargets[i].innerText
          const matchFilter = (txtValue.toUpperCase().indexOf(filter) > -1)? true : false
          if (matchFilter) {
            break
          }
        }
        if (matchFilter) {
          this.searchItemTargets[i].style.display = ''
        } else {
          this.searchItemTargets[i].style.display = 'none'
        }
      }
      this.removeSpinner()
    }

    addSpinner() {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.add('whirl', 'double-up')
      }
    }

    removeSpinner() {
      if (this.hasSpinnerTarget) {
        this.spinnerTarget.classList.remove('whirl', 'double-up')
      }
    }
}
