import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal']
  static values = { background: Boolean }

  connect() {
    // jquery to be replaced after template with bootstrap 4 has been removed.
    $(this.element).modal('show')

    // ordinary js eventListener doesn't work for bootstrap modals.
    $(this.element).on('shown.bs.modal', function() {
      const autofocusElelment = document.activeElement.querySelector('[autofocus]')

      if (autofocusElelment !== null) {
        autofocusElelment.focus()
      }
      const signatureElement = document.getElementById('signature-pad')
      if (signatureElement !== null) {
        window.dispatchEvent(new CustomEvent('resize-canvas', {}))
        signatureElement.style.removeProperty('width')
        signatureElement.style.removeProperty('height')
      }
    })
  }

  close() {
    $(this.element).modal('hide')
    this.element.remove()
  }

  closeWithEsc(event) {
    if (event.code == 'Escape') {
      this.close()
    }
  }

  closeBackground(event) {
    if (!this.backgroundValue || (event && this.modalTarget != event.target)) {
      return
    }
    this.close()
  }
}
