import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'collapsableArea',
    'checkbox',
    'toggleIndicatorPlaceholder',
    'toggleIndicator',
    'input',
  ]
  static values = { collapse: { type: Boolean, default: true } }

  // HOW TO USE //

  // ADD data-controller="collapse"

  // Optionally add data-collapse-collapse-value="true/false"
  // for setting initial collapse state. If not provided, DEFAULT is collapsed
  // (true = initial state collapsed / false = initial state show)

  // Add data-collapse-target="collapsableArea" to the area, which you want collapsed
  // Add data-action="click->collapse#toggle" to the link, you want to space to uncollapse
  // Add DATAMAP on
  //  Add to href="" like this => href="javascript:void(0)" this will prevent <a> behavior.

  // INDICATOR:: If you want an arrow to indicate to the user the collapse / not collapse status. add
  // -->        data-collapse-target="toggleIndicatorPlaceholder"   <---
  // to the link initiating the toggle

  // COLLAPSE / UNCOLLAPSE WITH INPUT FIELDS / CHECKBOXES
  // INPUT type=text
  // To uncolapse an area upon an input of an INPUT type='text'
  // data-action: 'change->collapse#toggleOnTextfieldInput'
  // if you need to make the uncollapse dependent on multiple input fields, add
  // data-collapse-target='input' to all input fields.
  // As soon as one contains content, the collapsableArea will uncollapse.

  // --> INPUT type=checkbox     e.g. in combination with switches
  // add to all checkboxes
  // 'data-action': 'click->collapse#toggle', 'data-collapse-target': 'checkbox'

  // ADD "collapseInverted" to class of areas, which you want invert collapse behavior.
  // By doing so ONE toggle can uncollapse some areas and collapse some others in parallel.

  connect() {
    // Set the initial state for collapse programatically to determine if collapseable area is hidden or not
    // works for CheckboxTarget
    if ((this.hasCheckboxTarget && this.anyCheckboxeActive)) {
      this.collapseValue = false
    }

    if (this.hasInputTarget) {
      this.allInputBoxWithContent(this.inputTargets) ? this.collapseValue = false : this.collapseValue = true
    }

    if (this.hasCollapsableAreaTarget) {
      for (let j = 0; j < this.collapsableAreaTargets.length; j++) {
        if (this.collapseValue == true ||
            this.collapsableAreaTargets[j].classList.contains('collapseInverted')) {
          this.collapsableAreaTargets[j].hidden = true
        } else {
          this.collapsableAreaTargets[j].hidden = false
        }
      }
    }

    if (this.hasToggleIndicatorPlaceholderTarget) {
      this.toggleIndicatorPlaceholderTarget.innerHTML =
        '<i class="fas fa-caret-right mr-2" data-collapse-target="toggleIndicator"></i>' +
        this.toggleIndicatorPlaceholderTarget.innerHTML
      if (!this.collapseValue) {
        this.toggleIndicatorTarget.classList.remove('fa-caret-right')
        this.toggleIndicatorTarget.classList.add('fa-caret-down')
      }
    }
  }

  toggle(event) {
    // in case of checkboxes, checks if any is already checked.
    // If already one is checked, prevent any action
    if (
      this.hasCheckboxTarget &&
      this.collapseValue == false &&
      this.anyCheckboxeActive
    ) { return }

    if (this.collapseValue) {
      this.uncollapse(event)
    } else {
      this.collapse(event)
    }
  }

  toggleOnTextfieldInput(event) {
    // If multiple input elements define the collapse status, you can set
    // data-collapse-target='input' on multiple input elements. If there is only one
    // input element, also containing this stimulus action, you can omit the data-collapse-target
    const elementsDefiningUncollapse = this.hasInputTarget ? this.inputTargets : [event.currentTarget]
    if (this.allInputBoxWithContent(elementsDefiningUncollapse)) {
      this.uncollapse(event)
    } else {
      this.collapse(event)
    }
  }

  collapse(event) {
    this.collapseValue = true

    this.toggleIndicatorClose()

    for (let j = 0; j < this.collapsableAreaTargets.length; j++) {
      if (
        this.collapsableAreaTargets[j].classList.contains('collapseInverted')
      ) {
        this.collapsableAreaTargets[j].hidden = false
      } else {
        this.collapsableAreaTargets[j].hidden = true
      }
    }
  }

  slideUpDown(e) {
    const attributeType = e.target.value
    const attributeClass = e.target.classList
    const inputElement = this.inputTarget
    if (
      attributeType == 'enum' ||
      attributeType == 'multiselect' ||
      attributeClass.contains('purchase-price-ex')
    ) {
      inputElement.style.maxHeight = inputElement.scrollHeight + 'px'
      inputElement.classList.remove('slide-up')
      inputElement.classList.add('slide-down')
    } else {
      inputElement.style.maxHeight = inputElement.offsetHeight + 'px'
      inputElement.classList.remove('slide-down')
      inputElement.classList.add('slide-up')
      setTimeout(function() {
        inputElement.style.maxHeight = '0'
      }, 50)
    }
  }

  uncollapse(event) {
    this.collapseValue = false

    this.toggleIndicatorOpen()

    for (let j = 0; j < this.collapsableAreaTargets.length; j++) {
      if (
        this.collapsableAreaTargets[j].classList.contains('collapseInverted')
      ) {
        this.collapsableAreaTargets[j].hidden = true
      } else {
        this.collapsableAreaTargets[j].hidden = false
      }
    }
  }

  toggleIndicatorOpen() {
    if (this.hasToggleIndicatorTarget) {
      this.toggleIndicatorTarget.classList.remove('fa-caret-right')
      this.toggleIndicatorTarget.classList.add('fa-caret-down')
    }
  }

  toggleIndicatorClose() {
    if (this.hasToggleIndicatorTarget) {
      this.toggleIndicatorTarget.classList.remove('fa-caret-down')
      this.toggleIndicatorTarget.classList.add('fa-caret-right')
    }
  }

  allInputBoxWithContent(elements) {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].value.length == 0) {
        // as soon as the first element is found without content, false is returned and method is exited.
        return false
      }
    }
    return true
  }

  get anyCheckboxeActive() {
    // loops over multiple checkboxes. Uncollapses if just one is checked
    for (let i = 0; i < this.checkboxTargets.length; i++) {
      if (this.checkboxTargets[i].checked) return true
    }
    return false
  }
}
