import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="task-status"
export default class extends Controller {
  static values = { url: String }
  connect() {}

  update(event) {
    event.preventDefault()
    const url = `${this.urlValue}?ticket_status_id=${event.target.dataset.statusId}`
    fetch(url, {
      method: 'PUT',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0]
          .content,
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}
