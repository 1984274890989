import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="checkoutable-resource-quantities"
export default class extends Controller {
  static targets = ['minimumQuantity', 'checkoutableItemId', 'checkoutableResourceQuantityId', 'stockItemActivityId']

  addQuantity() {
    const val = this.getMinimumQuantityValue() + 1.0
    this.minimumQuantityTarget.value = (val % 1 !== 0) ? val : val.toFixed(1)
    this.submit()
  }

  removeQuantity() {
    let val = this.getMinimumQuantityValue()
    val = val > 0 ? val - 1 : 0
    this.minimumQuantityTarget.value = (val % 1 !== 0) ? val : val.toFixed(1)
    this.submit()
  }
  submit() {
    clearTimeout(this.submitTimeout)
    const formData = new FormData()
    formData.append('checkoutable_resource_quantity[minimum_quantity]', this.minimumQuantityTarget.value)
    formData.append('stock_item_activity_id', this.stockItemActivityIdTarget.value)
    const checkoutableItemId = this.checkoutableItemIdTarget.value
    const checkoutableResourceQuantityId = this.checkoutableResourceQuantityIdTarget.value

    let url = '/checkoutable_items/'+checkoutableItemId+'/checkoutable_resource_quantities/'
    let methodType = 'POST'

    if (checkoutableResourceQuantityId) {
      url = '/checkoutable_items/'+checkoutableItemId+'/checkoutable_resource_quantities/'+checkoutableResourceQuantityId
      methodType = 'PATCH'
    }

    this.submitTimeout = setTimeout(() => {
      fetch(url, {
        method: methodType,
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
        },
        body: formData,
      })
        .then((r) => r.text())
        .then((html) => {
          Turbo.renderStreamMessage(html)
        })
    }, 1000)
  }


  getMinimumQuantityValue() {
    const minimumQuantityValue = parseFloat(this.minimumQuantityTarget.value)
    return isNaN(minimumQuantityValue) ? 0 : minimumQuantityValue
  }
}
