import { Controller } from 'stimulus'
import loadGoogleMapsApi from 'load-google-maps-api'
import { each } from 'lodash'

import { showElement, hideElement, isValueSelected } from '../utils/html_element_helpers'

const { GOOGLE_API_KEY } = window

export default class extends Controller {
  static targets = [
    'customGeolocation', 'ownPositionInput', 'latitudeInput',
    'longtitudeInput', 'accuracyInput', 'positionNotificationText',
    'mapElement', 'errorWrapper',
  ]

  connect() {
    this.getLocation()
  }

  getLocation() {
    const _this = this

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        _this.showPosition(_this, position)
      }, function(error) {
        _this.showError(_this, error)
      })
    } else {
      this.errorWrapperTarget.innerHTML = window.I18n.t('js.geolocation_not_supported')
    }
  }

  showError(_this, error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        _this.errorWrapperTarget.innerHTML = window.I18n.t('js.activate_gps_browser')
        break
      case error.POSITION_UNAVAILABLE:
        _this.errorWrapperTarget.innerHTML = window.I18n.t('js.location_info_not_available')
        break
      case error.TIMEOUT:
        _this.errorWrapperTarget.innerHTML = window.I18n.t('js.location_info_timedout')
        break
      case error.UNKNOWN_ERROR:
        _this.errorWrapperTarget.innerHTML = window.I18n.t('js.unknown_error')
        break
    }
  }

  showPosition(_this, position) {
    _this.latitudeInputTarget.value = position.coords.latitude
    _this.longtitudeInputTarget.value = position.coords.longitude
    _this.accuracyInputTarget.value = position.coords.accuracy

    this.positionNotificationTextTarget.textContent =
      window.I18n.t('js.measurement_accuracy', { accuracy: position.coords.accuracy })

    showElement(document.getElementById('positionFound'))
    showElement(document.getElementById('map-wrapper'))
    showElement(document.getElementById('submitButton'))

    const coordinates = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }
    _this.createMap(coordinates)
    _this.getAddress(_this, coordinates)
  }

  getAddress(_this, coordinates) {
    const url =
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
      coordinates['lat'] +
      ',' +
      coordinates['lng'] +
      '&key=' +
      GOOGLE_API_KEY +
      '&language=' +
      window.I18n.locale

    fetch(url, {
      method: 'GET',
    }).then(function(response) {
      response.json().then(function(json) {
        const addresses = []
        const addressTypeToExclude = ['political']

        each(json, function(addressCollection) {
          each(addressCollection, function(address) {
            const types = address['types']

            if (!types) {
              return
            }

            if (
              !types.includes(addressTypeToExclude[0]) &&
              !addresses.includes(address['formatted_address'])
            ) {
              addresses.push(address['formatted_address'])
            }
          })
        })

        _this.addOptionsToSelect(addresses)
      })
    })
  }


  createMap(coordinates) {
    const _this = this

    loadGoogleMapsApi({ key: GOOGLE_API_KEY, language: window.I18n.locale }).then(function(googleMaps) {
      const map = new googleMaps.Map(_this.mapElementTarget, {
        center: coordinates,
        zoom: 16,
        streetViewControl: false,
      })

      new google.maps.Marker({ position: coordinates, map: map })
    })
  }

  addOptionsToSelect(addresses) {
    const select = document.getElementById('gps_position_geolocation')
    // only run this if select was found
    if (select) {
      addresses.forEach(function(item, index) {
        const option = document.createElement('option')
        option.text = item
        select.add(option)
      })
    };
    const option = document.createElement('option')
    option.text = window.I18n.t('js.own_position')
    option.value = 0
    select.add(option)
    this.addEventListenerToSelect(select)
  }

  addEventListenerToSelect(el = null) {
    const _this = this

    if (el == null) {
      el = document.getElementById('gps_position_geolocation')
    }
    el.addEventListener('change', function() {
      const customGeolocationValue = '0'

      if (isValueSelected(_this, customGeolocationValue)) {
        showElement(_this.customGeolocationTarget)
        _this.ownPositionInputTarget.disabled = false
      } else {
        hideElement(_this.customGeolocationTarget)
        _this.ownPositionInputTarget.disabled = true
      }
    })
  }
}

