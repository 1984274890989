import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  connect() {
    this.updateSearchFilters()
  }

  updateSearchFilters() {
    if (this.hasPageNumberTarget) {
      window.dispatchEvent(
        new CustomEvent('update-items-search', { detail: this.detailInfo() }),
      )
    }
  }

  detailInfo() {
    const detail = {}

    this.formTarget.childNodes.forEach((node) => {
      if (
        node.nodeName === 'INPUT' &&
        node.type === 'hidden' &&
        node.name !== 'authenticity_token'
      ) {
        detail[node.name] = node.value
      }
    })

    return detail
  }
}
