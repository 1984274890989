import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleIndicator'];

  connect() {
    this.collapsableStructure()
  }

  collapsableStructure() {
    this.toggleIndicatorTargets.forEach(function(indicatorEl) {
      indicatorEl.addEventListener('click', function(e) {
        const nextElement = indicatorEl.parentElement.nextElementSibling

        if (indicatorEl.classList.contains('fa-caret-right')) {
          indicatorEl.classList.remove('fa-caret-right')
          indicatorEl.classList.add('fa-caret-down')
          if (nextElement && nextElement.tagName == 'UL') {
            nextElement.style.removeProperty('display')
          }
        } else {
          indicatorEl.classList.remove('fa-caret-down')
          indicatorEl.classList.add('fa-caret-right')
          if (nextElement && nextElement.tagName == 'UL') {
            nextElement.style.display = 'none'
          }
        }
      })
    })
  }
}
