import { Controller } from 'stimulus'
import sortable from 'html5sortable/dist/html5sortable.es.js'

export default class extends Controller {
  static targets = ['dragHandle', 'container', 'positionInputField'];

  static values = {
    url: String,
    launched: { type: Boolean, default: true },
    loadOnStart: Boolean,
    enabled: { type: Boolean, default: true },
  }
  // data-controller="drag" to enable drag functionality
  // data-drag-url-value="validation_category_move_path(':id')" url for transmitting the change. OPTIONAL
  // add params within apostophe.
  // for the URL to work, the record id must be added as attribute to the HTML

  // data-id="<%= root_category.id %>"
  // if not set. it will only sort, but not submit.

  // data-drag-target="dragHandle" To add the three lined bar as drag handle. OPTNIONAL

  // if it should not automatically launch on load add the following
  // data-drag-load-on-start-value="false"

  // if data-controller is not the dragable container itself, add this option to specify
  // the container around the draggable elements OPTIONAL
  // data-drag-target="container"

  // to disable the drag feature set
  // data-drag-enabled="false"

  connect() {
    // Default if loadOnStartValue is absent it should start automatically
    if (this.enabledValue && (!this.hasLoadOnStartValue || this.loadOnStartValue != false)) {
      this.launch()
    }
  }

  launch() {
    const draggableContainer = this.hasContainerTarget ? this.containerTarget : this.element

    sortable(draggableContainer, {
      items: ':not(.no-drag)',
    })

    sortable(draggableContainer)[0].addEventListener('sortupdate', function(e) {
      const event = new CustomEvent('update-sorts', {
        detail: {
          node: draggableContainer.id,
          items: e.detail.destination.items,
          elementIndex: e.detail.destination.elementIndex,
          element: e.detail.item,
        },
      })
      window.dispatchEvent(event)
    })

    if (this.urlValue) {
      const url = decodeURIComponent(this.urlValue)
      sortable(draggableContainer)[0].addEventListener('sortupdate', function(e) {
        const id = e.detail.item.dataset.id
        const data = new FormData()
        const newPosition = e.detail.destination.index
        data.append('position', newPosition + 1)
        // origin_position needed in conjunction with gem 'awesome_nested_set'
        data.append('origin_position', e.detail.origin.index + 1)

        Rails.ajax({
          url: url.replace(':id', id),
          type: 'patch',
          data: data,
        })
      })
    } else if (this.hasPositionInputFieldTarget) {
      sortable(draggableContainer)[0].addEventListener('sortupdate', this.updateInputPositionFields.bind(this))
    }

    if (this.hasDragHandleTarget) {
      const content = `<svg class="tw-mr-1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
      viewBox="0 0 15 15">
      <path fill="currentColor" fill-rule="evenodd" d="M5.5 4.625a1.125 1.125 0 1 0 0-2.25a1.125 1.125 0 0 0 0
      2.25m4 0a1.125 1.125 0 1 0 0-2.25a1.125 1.125 0 0 0 0 2.25M10.625 7.5a1.125 1.125 0 1 1-2.25 0a1.125 1.125
      0 0 1 2.25 0M5.5 8.625a1.125 1.125 0 1 0 0-2.25a1.125 1.125 0 0 0 0 2.25m5.125 2.875a1.125 1.125 0 1 1-2.25
      0a1.125 1.125 0 0 1 2.25 0M5.5 12.625a1.125 1.125 0 1 0 0-2.25a1.125 1.125 0 0 0 0 2.25"
      clip-rule="evenodd"/></svg>`
      for (let i = 0; i < this.dragHandleTargets.length; i++) {
        this.dragHandleTargets[i].insertAdjacentHTML('afterbegin', content)
      }
    }
  }

  tearDown() {
    this.launchedValue = false
    sortable(this.hasContainerTarget ? this.containerTarget : this.element, 'destroy')

    if (this.hasDragHandleTarget) {
      for (let i = 0; i < this.dragHandleTargets.length; i++) {
        this.dragHandleTargets[i].getElementsByTagName('em')[0].remove()
      }
    }
  }

  toggle() {
    // data map launched is set upon launch()
    if (this.launchedValue == true) {
      this.tearDown()
    } else {
      this.launch()
    }
  }

  updateInputPositionFields() {
    for (let i = 0; i < this.positionInputFieldTargets.length; i++) {
      this.positionInputFieldTargets[i].value = i
    }
  }
}
