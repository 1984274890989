import { Controller } from 'stimulus'

export default class extends Controller {
  // add controller
  // data-controller="string-to-badge-splitter"

  // The default seperators is a semicolon (;)
  // You can overwrite the seperator by passing in your own seperator
  // samle for semicolon (;), comma (,) and empty space
  // data-string-to-badge-splitter-seperator-value="[';', ',', " "]"
  // you can even define a default sepeartor in the model and pass it to the simulus controller
  // data-string-to-badge-splitter-seperator-value="<%= SsoConfig::REGEX_SEPERATORS %>"

  // the input element which needs to be split
  //  "data-string-to-badge-splitter-target": 'inputString'

  // place an action on element to trigger action
  // 'data-action': 'keyup->string-to-badge-splitter#convert'

    static targets = ['inputString', 'output'];
    static values = { seperator: { type: Array, default: [';'] } }

    connect() {
      if (this.inputStringTarget.value.length > 0) {
        this.convert()
      }
    }

    convert() {
      const splitterRegex = new RegExp(this.seperatorValue.join('|'))
      const arr = this.inputStringTarget.value.split(splitterRegex).map(function(item) {
        return '<span class="badge badge-secondary !tw-bg-slate-700">' + item.trim() + '</span>'
      })
      this.outputTarget.innerHTML = arr.join(' ')
    }
}
