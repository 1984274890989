const TIMEOUT = 600

export class SortService {
  constructor(controller, params) {
    this.controller = controller
    this.params = params
  }

  updateColumnsSort(e) {
    if (e.detail.node == 'config_columns') {
      this.sortColumns()
    } else if (e.detail.node == 'custom_tabs') {
      this.sortTabs(e)
    } else {
      return
    }
  }

  sortColumns() {
    clearTimeout(this.sortTimeout)
    this.sortTimeout = setTimeout(() => {
      Array.from(document.getElementById('config_columns')
        .getElementsByClassName('list-group-item'))
        .forEach((item, index) => {
          const name = item.dataset['fieldName']
          if (Object.keys(this.controller.columns).indexOf(name) >= 0) {
            this.controller.columns[name] = index
          }
        })
      this.controller.search()
    }, TIMEOUT)
  }

  sortTabs(event) {
    const detail = event.detail
    clearTimeout(this.sortTimeout)
    if (this.controller.hasSortTabPositionTarget) {
      this.controller.sortTabPositionTarget.value = detail.elementIndex + 1
    }
    this.controller.sortTabIdTarget.value = detail.element.dataset.tabId
    if (this.controller.hasTabActionTarget) {
      this.controller.tabActionTarget.value = 'reorder'
    }
    this.sortTimeout = setTimeout(() => {
      this.controller.search()
    }, TIMEOUT)
  }

  applySort(e) {
    e.preventDefault()
    const sortValue = e.target.closest('[data-value]').dataset.value
    let defaultSortDir = 'asc'
    const directionOption = e.target.closest('[data-value]').dataset.sortDirectionOption
    if (directionOption) {
      this.setMissingSort(directionOption)
      defaultSortDir = directionOption
    }
    if (!sortValue) {
      return
    }
    const value = sortValue
    const sortDirection = this.sortDirectins(this.controller.sort[value]) || defaultSortDir
    this.controller.sort = {}
    this.controller.sort[value] = sortDirection
    this.controller.search()
    if (e.currentTarget.tagName.toLowerCase() === 'button') {
      e.currentTarget.classList.toggle('tw-rotate-180')
    }
  }

  sortDirectins(value) {
    return { 'asc': 'desc', 'desc': 'asc' }[value]
  }

  setMissingSort(directionOption) {
    if (this.controller.hasMissingSortTarget) {
      if (directionOption['value'] == 'desc') {
        this.controller.missingSortTarget.value = '_last'
      } else {
        this.controller.missingSortTarget.value = ''
      }
    }
  }

}
