import { Controller } from 'stimulus'

export default class extends Controller {
  // data-controller="inline-edit"

  // to pass in the param structure. !IMPORTANT to pass in an array.
  // single / double quotes need to be used exactly as in example
  // data-inline-edit-param-name-value='["model_name", "attribute_name"]'
  // data-inline-edit-url-value='/path/123'
  // data-inline-edit-method-value='PUT'     default is POST if ommitted
  // data-action="click->inline-edit#toggle click@window->inline-edit#close"

  // for a different input element than input_field, set value
  // data-inline-edit-input-type-value="textarea"

  // to  define a different element than the one clicked e.g. with button), set source target
  // data-inline-edit-target="source"

  // to disable any editing, pass in
  // data-inline-edit-disabled="true / false" --> true == disabled

  // IMPORTANT
  // server must return 422 if an update fails

  // To text should be clickable on edit, this can be indicated with CSS
  // use class <span class="inline-edit"></span>

  static targets = ['source', 'input', 'toggleButton']

  static values = {
    paramName: Array,
    newRecord: Boolean,
    url: String, // URL to where to make the call to.
    method: { type: String, default: 'POST' }, // define the js.fetch method to the server, PUT, POST, ...
    inputType: String, // define what type of input element shuold be shown
    toggled: Boolean,
    disabled: Boolean,
    locale: String,
    itemId: String,
  }

  connect() {
    if (this.inputTypeValue === '') {
      this.inputTypeValue = 'textField'
    }

    this.setTargetElement()
    this.addEditIconForEmptySpaces()
  }

  addEditIconForEmptySpaces() {
    if (this.targetElement.textContent.trim() == '') {
      this.element.classList.add('show-child-on-hover')
      this.element.innerHTML = '<i class="fas fa-pen text-muted hover-child pl-4"></i>'
    }
  }

  toggle(event) {
    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(event.target.tagName)) {
      return
    }
    if (this.toggledValue != true) {
      this.open(event)
    } else {
      this.close(event)
    }
  }

  open(event) {
    event.stopPropagation()
    // if value disabled == true --> return and don't allow to toggle input fields
    if (this.disabledValue) return false

    this.toggleToInput()
    this.toggledValue = true
  }

  close(event) {
    event.stopPropagation()
    if (this.toggledValue == true) {
      this.toggleToShowView()
      this.toggledValue = false
    }
  }

  catchEnter(event) {
    event.stopPropagation()
    if (event.keyCode == 13) {
      this.submit(event)
    }
  }

  emitGlobalEvent(payload) {
    const event = new CustomEvent('submit-inline-edit-item', {
      detail: {
        name: 'submit-inline-edit-item',
        id: this.itemIdValue,
        payload: payload,
      },
    })
    window.dispatchEvent(event)
  }

  submit(event) {
    event.stopPropagation()
    if (!this.urlValue) {
      return this.emitGlobalEvent(this.payLoad)
    }
    fetch(this.formUrl, {
      method: this.methodValue,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.authenticityToken,
      },
      body: JSON.stringify(this.payLoad),
    }).then(function(response) {
      // 201(created) response if a new positin was created
      if (response.status >= 200 && response.status <= 299) {
        // set the new currentContent Value to the input field
        this.setNewCurrentContent()
        this.close(event)
        this.addEditIconForEmptySpaces()
        this.inputTarget.focus()
      } else {
        window.$.notify('Could not update the record')
        this.toggle(event)
      }
    }.bind(this))
  }

  /* eslint-disable */
  textField() {
    return `
        <div class="input-group" stlye="z-index: 100;" %>
            <input type="text" value="${this.currentContent}" class="form-control" data-action="keyup->inline-edit#catchEnter" data-inline-edit-target="input" onclick="event.stopPropagation()">
            <div class="input-group-append input-group-addon">
                <a href="javascript:void(0)" class="btn text-success text-success-hover px-3" data-action="click->inline-edit#submit">
                    </i><i class="far fa-save fa-lg"></i>
                </a>
                <a href="javascript:void(0)" class="btn text-warning text-warning-hover px-3" type="button" data-action="click->inline-edit#close">
                    </i><i class="fas fa-times fa-lg"></i>
                </a>
            </div>
        </div>
        `
  }

  /* eslint-disable */
  textFieldWithDatePicker() {
    let currentDate = this.currentContent.replace("-", "")
    if (currentDate){
      const values = currentDate.split(/[\.\/-]/)
      currentDate = values[2] + '-' + values[1] + '-' + values[0]
    }
    return `
        <div class="input-group" stlye="z-index: 100;" >
            <input type="text" value="${currentDate}" class="form-control" data-action="keyup->inline-edit#catchEnter" data-inline-edit-target="input" onclick="event.stopPropagation()" data-controller='flatpickr' data-flatpickr-locale-value='${this.localeValue}' data-flatpickr-default-dates-value='[${currentDate}]'>
            <div class="input-group-append input-group-addon">
                <a href="javascript:void(0)" class="btn text-success text-success-hover px-3" data-action="click->inline-edit#submit">
                    </i><i class="far fa-save fa-lg"></i>
                </a>
                <a href="javascript:void(0)" class="btn text-warning text-warning-hover px-3" type="button" data-action="click->inline-edit#close">
                    </i><i class="fas fa-times fa-lg"></i>
                </a>
            </div>
        </div>
        `
  }
  /* eslint-enable */

  /* eslint-disable */
  textarea() {
    return `
        <textarea class="form-control" class="form-control" data-action="keyup->inline-edit#catchEnter" data-inline-edit-target="input" onclick="event.stopPropagation()">${this.currentContent}</textarea>
        <div class="mt-2 d-flex justify-content-between align-items-center">
            <a href="javascript:void(0)" class="btn btn-oval btn-outline-warning btn-sm" type="button" data-action="click->inline-edit#close">
                </i><i class="fas fa-times fa-lg"></i>
            </a>
            <a href="javascript:void(0)" class="btn btn-oval btn-outline-success btn-sm" data-action="click->inline-edit#submit">
                <i class="fas fa-arrow-right mr-1"></i><i class="far fa-save fa-lg"></i>
            </a>
        </div>
        `
  }
  /* eslint-enable */

  get formUrl() {
    return this.hasUrlValue ? this.urlValue : window.location.pathname
  }

  get authenticityToken() {
    return document.querySelector('meta[name=\'csrf-token\']').getAttribute('content')
  }

  get payLoad() {
    let key
    let tempObj
    const data = {
      authenticity_token: this.authenticityToken,
      new_record: this.newRecordValue,
    }
    tempObj = data
    const lastKeyIndex = this.paramNameValue.length - 1

    for (let i = 0; i < lastKeyIndex; i++) {
      key = this.paramNameValue[i]
      if (!(key in tempObj)) {
        tempObj[key] = {}
      }
      tempObj = tempObj[key]
    }
    tempObj[this.paramNameValue[lastKeyIndex]] = this.inputTarget.value
    return data
  }

  setNewCurrentContent(value = this.inputTarget.value.trim()) {
    if (this.inputTypeValue == 'textFieldWithDatePicker' && value.match(/[0-9]{4}[\.\/-][0-9]{2}[\.\/-][0-9]{2}/)) {
      const values = value.split(/[\.\/-]/)
      this.currentContent = values[2] + '.' + values[1] + '.' + values[0]
    } else {
      this.currentContent = value
    }
  }

  toggleToInput() {
    // Store current values for toggling back
    if (this.currentContent) {
      this.currentContent = this.currentContent
    } else {
      this.currentContent = this.targetElement.textContent.trim()
    }
    // Placeholder to place the content there.
    // Why solved this way: Like this is also works if content == ''.
    this.targetElement.textContent = '*/_content_placeholder_/*'
    this.currentParentInnerHTML = this.targetElementParent.innerHTML

    // Display the element
    this.targetElementParent.innerHTML = this[this.inputTypeValue]()

    // set the focus on the newly added input field
    this.targetElementParent.querySelector('input, textarea').focus()
  }

  toggleToShowView() {
    this.targetElementParent.innerHTML =
      this.currentParentInnerHTML.replaceAll('*/_content_placeholder_/*', this.currentContent)
    // Update the targetElement with the new content.
    this.setTargetElement()
    this.addEditIconForEmptySpaces()
  }

  setTargetElement() {
    if (this.hasSourceTarget) {
      this.targetElement = this.sourceTarget
      this.targetElementParent = this.targetElement.parentElement
    } else {
      this.targetElement = this.element
      // if not source target is added, it chooses automatically the parent where to attache the input element
      this.targetElementParent = this.element
    }
  }
}
