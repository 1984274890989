import { Controller } from 'stimulus'

const SELECT2_CUSTOM_CHANGE_EVENT = 'select2-change'
// add data-controller="select2"

// --> alternative for removing select2 with
// TOM select https://tom-select.js.org/
export default class extends Controller {
  static targets = ['select2']
  static values = {
    tags: { type: Boolean, default: false },
    allowClear: { type: Boolean, default: false },
    eventName: String,
    parent: String,
  }

  connect() {
    let el
    const customEvent = this.hasEventNameValue ? this.eventNameValue : SELECT2_CUSTOM_CHANGE_EVENT
    if (this.hasSelect2Target) {
      el = this.select2Target
    } else {
      el = this.element
    }
    const parentEle = this.hasParentValue ? '' : $(el).parent()
    window.$(el).select2({
      theme: 'bootstrap4',
      tags: this.tagsValue,
      allowClear: this.allowClearValue,
      multiple: el.dataset.multiple,
      dropdownParent: parentEle,
      templateResult: (data) => {
        if ($(data.element).data('level')) {
          const $wrapper = $('<span></span>')
          $wrapper.addClass(`level-${$(data.element).data('level')}`)
          $wrapper.text(data.text)
          return $wrapper
        } else {
          return data.text
        }
      },
      language: {
        noResults: function() {
          return window.I18n.t('js.no_result_found')
        },
      },
    })

    window.$(el).change((e) => {
      this.showInformationForSelectValue(e)
      const event = new CustomEvent(customEvent, {
        detail: {
          node: el.name,
          selectedOptions: e.target.selectedOptions,
        },
      })
      window.dispatchEvent(event)
    })

    // on first focus (bubbles up to document), open the menu
    $(document).on(
      'focus',
      '.select2-selection.select2-selection--single',
      function(e) {
        // eslint-disable-next-line no-invalid-this
        $(this)
          .closest('.select2-container')
          .siblings('select:enabled')
          .select2('open')
      },
    )

    // steal focus during close - only capture once and stop propogation
    $('select.select2').on('select2:closing', function(e) {
      $(e.target)
        .data('select2')
        .$selection.one('focus focusin', function(e) {
          e.stopPropagation()
        })
    })

    $(document).on('select2:open', (e) => {
      setTimeout(() => {
        if (this.tagsValue) {
          $('input.select2-search__field').prop('placeholder', 'Search or Create new value')
        }
        const inputElementId = `select2-${$(e.target).attr('id')}-results`
        if (document.querySelector(`[aria-controls="${inputElementId}"]`)) {
          document.querySelector(`[aria-controls="${inputElementId}"]`).focus()
        }
      }, 1000)
    })
  }

  showInformationForSelectValue(e) {
    if ($('[data-select-target="selectedInfo"]').length > 0) {
      $('[data-select-target="selectedInfo"]').each((index, selectedInfo) => {
        if (selectedInfo.dataset.selectedValue === e.target.value) {
          selectedInfo.classList.remove('d-none')
        } else {
          selectedInfo.classList.add('d-none')
        }
      })
      const event = new CustomEvent('update-select', {
        detail: {
          name: e.target.name,
          value: e.target.value,
        },
      })
      window.dispatchEvent(event)
    }
  }

  resetValues() {
    window.$(this.select2Target).val(null).trigger('change')
  }

  refershSelect() {
    window.$(this.select2Target).trigger('change')
  }
}
