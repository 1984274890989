import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'
import { map, filter, trim } from 'lodash'

import {
  toggleDisplayClass, showElement,
  hideElement, fetchOptionValue, findSelectedOptionIndex,
} from '../../utils/html_element_helpers'

const THROTTLE_WAIT = 2000
const DEFAULT_RESPONSIBLE_OPTION = 0
const DISPLAY_CLASS = 'd-none'

export default class extends Controller {
  initialize() {
    this.updateOverview = throttle(this.updateOverview, THROTTLE_WAIT).bind(this)
  }

  toggleFiltersBlock() {
    toggleDisplayClass(this.formOptionsTarget)

    if (trim(this.moreFiltersTarget.text) === window.I18n.t('more_filters')) {
      this.moreFiltersTarget.text = window.I18n.t('hide')
    } else {
      this.moreFiltersTarget.text = window.I18n.t('more_filters')
    }
  }

  fetchSelectedLocationIds() {
    const allLocationCheckboxElements = document.getElementsByName('locations[id][]')
    const selectedLocationsElements = filter(allLocationCheckboxElements, function(el) {
      return el.checked
    })
    return map(selectedLocationsElements, fetchOptionValue)
  }

  resetResponsible() {
    const foundIndex = findSelectedOptionIndex(this.responsibleSelectTarget.options, window.I18n.t('all'))

    this.loadOverviewIfChanged(foundIndex)
  }

  findCurrentUserResponsible() {
    const currentUserOptionValue = ''
    const foundIndex = findSelectedOptionIndex(
      this.responsibleSelectTarget.options, currentUserOptionValue, function(option) {
        return option.dataset['ownOnly']
      })

    this.loadOverviewIfChanged(foundIndex)
  }

  loadOverviewIfChanged(foundIndex) {
    if (this.responsibleSelectTarget.selectedIndex !== foundIndex) {
      this.responsibleSelectTarget.selectedIndex = foundIndex
      this.updateOverview()
    }
  }

  resetFormData() {
    this.responsibleSelectTarget.selectedIndex = DEFAULT_RESPONSIBLE_OPTION
    hideElement(this.clearAllFiltersTarget, DISPLAY_CLASS)
  }

  updateOverview(params) {
    const _this = this

    Rails.ajax({
      url: this.urlValue,
      type: 'POST',
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify(_this.buildRequestData(params))
        return true
      },
      success: function(result) {
        document.getElementById('optionTable').innerHTML = result.body.innerHTML
        showElement(_this.clearAllFiltersTarget, '', DISPLAY_CLASS)
      },
    })
  }
}
