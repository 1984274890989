import { Controller } from 'stimulus'
import { setCookie } from '../utils/cookies_helper'

export default class extends Controller {
  static targets = ['perPage']
  static values = {
    url: String,
  }

  selectAllRecords(event) {
    if (event.currentTarget.checked) {
      Rails.ajax({
        url: this.urlValue,
        type: 'POST',
        success: function(result) {
          window.dispatchEvent(new CustomEvent('select-all-records', {
            detail: {
              checked: true,
              records: result['records'],
            },
          }))
        },
      })
    } else {
      window.dispatchEvent(new CustomEvent('select-all-records', {
        detail: {
          checked: false,
        },
      }))
    }
  }

  changePerPage() {
    const perPage = this.perPageTarget.value
    setCookie('stocktaking_per_page', perPage, 30)
    const href = `${this.urlValue}&per_page=${perPage}`
    fetch(href, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }
}
