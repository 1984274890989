import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="assign-employee"
// use data-assign-employee-id-value if want to pass single value
export default class extends Controller {
  static targets = ['employeeName']
  static values = {
    id: String,
    fieldName: String,
  }

  connect() { }

  assignEmployee(event) {
    event.preventDefault()
    event.stopPropagation()
    this.removeElements(this.fieldNameValue)
    this.createHiddenField(this.idValue)
    Rails.fire(document.getElementById('assign_person_form'), 'submit')
    this.removeElements(this.fieldNameValue)
  }

  addEmployee(event) {
    event.preventDefault()
    event.stopPropagation()
    if (!document.getElementById(this.idValue)) {
      this.createHiddenField(this.idValue)
      this.hideAndCreateBadge(this.idValue)
      if (document.getElementById('fill-expiry-date-link')) {
        document.getElementById('fill-expiry-date-link').disabled = false
      }
    }
  }

  hideAndCreateBadge(employeeId) {
    setTimeout(() => {
      document.getElementById(`remove-employee-${employeeId}-to-skill`).classList.remove('d-none')
      document.getElementById(`add-employee-${employeeId}-to-skill`).classList.add('d-none')
      const currentSelectedEmployee = document.getElementById('currently-selected-persons')
      currentSelectedEmployee.insertAdjacentHTML('beforeend', this.createEmployeeBadge(employeeId))
    }, 200)
  }

  createHiddenField(id) {
    const hiddenField = document.createElement('input')
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('value', id)
    hiddenField.setAttribute('name', this.fieldNameValue)
    hiddenField.setAttribute('multiple', true)
    document.getElementById('assign_person_form').appendChild(hiddenField)
  }

  removeElements(name) {
    const elements = document.getElementsByName(name)
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0])
    }
  }

  createEmployeeBadge(employeeId) {
    return `<label style="margin: 5px" id='${employeeId}'>
              <label id="checkout-alert" class="badge badge-primary mr-2">${this.employeeNameTarget.innerText}
              <a>
                <i class="fa fa-times ml-2 text-primary bg-light clickable"
                style="padding: 2px 4px; border-radius: 50%; margin-left: 5px"
                onclick="window.dispatchEvent(new CustomEvent('remove-employee',
                 { detail: {employeeId: ${employeeId}, fieldName: '${this.fieldNameValue}'}}))">
                </i>
              </a>
              </label>
            </label>`
  }

  removeEmoployee(e) {
    e.preventDefault()
    console.log(e.detail)
  }
}
