import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['searchForm', 'personTypeField']

  cleanPersonTypeFilers(e) {
    this.removeActiveClass()
    this.personTypeFieldTarget.value = ''
    this.submitSearchForm()
  }

  setPersonTypeFilter(event) {
    this.personTypeFieldTarget.value = event.params.id
    this.removeActiveClass()
    event.currentTarget.classList.add('active')
    this.submitSearchForm()
  }

  removeActiveClass() {
    const elements = document.querySelectorAll('.person-type-button')
    elements.forEach((element) => {
      element.classList.remove('active')
    })
  }

  submitSearchForm() {
    Rails.fire(this.searchFormTarget, 'submit')
  }

  removeEmployee(e) {
    e.preventDefault()
    const fieldName = e.detail.fieldName
    const employeeId = e.detail.employeeId
    const elements = document.getElementsByName(fieldName)
    this.removeEmployeeIds(elements, employeeId)
    if (document.getElementsByName(fieldName).length == 0) {
      document.getElementById('fill-expiry-date-link').disabled = true
    }
  }

  removeEmployeeIds(elements, employeeId) {
    elements.forEach((element) => {
      if (element.value == employeeId) {
        element.remove()
        document.getElementById(employeeId).remove()
        const removeEmpEle = document.getElementById(`remove-employee-${employeeId}-to-skill`)
        const addEmpEle = document.getElementById(`add-employee-${employeeId}-to-skill`)
        if (removeEmpEle) {
          removeEmpEle.classList.add('d-none')
        }
        if (removeEmpEle) {
          addEmpEle.classList.remove('d-none')
        }
      }
    })
  }
}
