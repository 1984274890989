import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['searchForm']

  changeInputField(e) {
    this.q = e.target.value
    clearTimeout(this.inputTimeout)
    this.inputTimeout = setTimeout(() => {
      this.search()
    }, 400)
  }

  search() {
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => {
      Rails.fire(this.searchFormTarget, 'submit')
    }, 400)
  }
}
