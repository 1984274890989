import { Controller } from 'stimulus'

const TABLE_HEAD_UP_POS = 200

export default class extends Controller {
  tableHeadObject() {
    if (this.hasTableHeadTarget) {
      return this.tableHeadTarget
    }

    return this.element
  }

  toggleTableHeadFloating() {
    const tableHeadTarget = this.tableHeadObject()
    const isPositionFixed = tableHeadTarget.style.position === 'fixed'
    const doc = tableHeadTarget
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

    if (top > TABLE_HEAD_UP_POS && !isPositionFixed) {
      tableHeadTarget.style.position = 'fixed'
      tableHeadTarget.style.top = '0px'
      tableHeadTarget.padding = '0 14px'
    }

    if (top < TABLE_HEAD_UP_POS && isPositionFixed) {
      tableHeadTarget.style.position = 'static'
      tableHeadTarget.style.top = '0px'
      tableHeadTarget.padding = '0 0'
    }
  }
}
