import { Controller } from 'stimulus'
import getHiddenHeight from '../utils/get_hidden_height'

export default class Dropdown extends Controller {
  static targets = ['menu', 'input', 'selectedValue']

  connect() {
    this.updateOptions()
  }

  updateOptions() {
    this.menuTarget.querySelectorAll('button').forEach((button) => {
      if (button.value === this.inputTarget.value) {
        this.selectedValueTarget.innerText = button.dataset.text
        button.classList.add('active', 'tw-font-semibold')
      } else {
        button.classList.remove('active', 'tw-font-semibold')
      }
    })
  }

  toggle(event) {
    event.preventDefault()
    const targetRect = event.target.getBoundingClientRect()
    const modalParent = event.target.closest('.modal-content')
    let spaceBelow; let spaceAbove
    if (modalParent) {
      const modalRect = modalParent.getBoundingClientRect()
      spaceBelow = modalRect.bottom - targetRect.bottom
      spaceAbove = targetRect.top - modalRect.top
    } else {
      spaceBelow = window.innerHeight - targetRect.bottom
      spaceAbove = targetRect.top
    }
    const optionsHeight = getHiddenHeight(this.menuTarget)
    this.menuTarget.style.bottom = 'auto'

    if (!(event.target.hasAttribute('data-dropdown-target') || event.target.hasAttribute('data-toggle-type'))) {
      this.inputTarget.value = event.target.value
      this.updateOptions()
    }

    if (spaceBelow < optionsHeight && spaceAbove > spaceBelow) {
      this.menuTarget.style.bottom = '45px'
      if (spaceAbove < optionsHeight) {
        this.menuTarget.style.maxHeight = `${spaceAbove - 10}px`
      }
    } else {
      if (spaceBelow < optionsHeight) {
        this.menuTarget.style.maxHeight = `${spaceBelow - 10}px`
      }
    }
    this.menuTarget.classList.toggle('tw-hidden')
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('tw-hidden')) {
      this.menuTarget.classList.toggle('tw-hidden')
    }
  }
}
