import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="add-participant"
// use data-add-participant-id-value if want to pass single value
export default class extends Controller {
  static values = {
    id: String,
    fieldName: String,
  }

  connect() {}

  addParticipant(event) {
    event.preventDefault()
    event.stopPropagation()
    this.removeElements(this.fieldNameValue)
    this.createHiddenField(this.idValue)
    Rails.fire(document.getElementById('add_participant_form'), 'submit')
    this.removeElements(this.fieldNameValue)
  }

  createHiddenField(id) {
    const hiddenField = document.createElement('input')
    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('value', id)
    hiddenField.setAttribute('name', this.fieldNameValue)
    hiddenField.setAttribute('multiple', true)
    document.getElementById('add_participant_form').appendChild(hiddenField)
  }

  removeElements(name) {
    const elements = document.getElementsByName(name)
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0])
    }
  }
}
