import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'check']
  static values = { item: Object, merge: Boolean }

  initialize() {
    if (this.mergeValue) {
      this.add()
    }
  }

  connect() {
    this.hideButton()
  }

  add() {
    if (this.itemIsIncluded()) return

    const ticketList = document.getElementById('ticket-items-list')
    ticketList.insertAdjacentHTML('beforeend', this._template())
    this.updateDeadlines()
    this.hideButton()
    this.updateRootCategory()
  }

  updateDeadlines() {
    const ids = this.currentIds().filter(Boolean)
    const idParams = ids.map((id) => `ids[]=${id}`).join('&')
    const href = `/items_deadlines?${idParams}&ticketId=${this.itemValue.ticketId}&deadline_id=${this.itemValue.deadlineId}`
    fetch(href, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  hideButton() {
    if (!this.hasButtonTarget) {
      return
    }

    if (this.itemIsIncluded()) {
      this.buttonTarget.hidden = true
      this.checkTarget.hidden = false
    } else {
      this.buttonTarget.hidden = false
      this.checkTarget.hidden = true
    }
  }

  itemIsIncluded() {
    const ids = this.currentIds()

    return ids.includes(this.itemValue.id)
  }

  _template() {
    const recordId = new Date().getTime()

    return `<tr data-new-record="false" class="nested-fields" data-item-id="${this.itemValue.id}">
    <td><a href="/items/${this.itemValue.id}">${this.itemValue.linkText}</a>
    <input autocomplete="off" type="hidden" value="${this.itemValue.id}"
    name="ticket[ticket_items_attributes][${recordId}][item_id]"
    id="ticket_ticket_items_attributes_${recordId}_item_id">
    <input autocomplete="off" type="hidden" value="false"
    name="ticket[ticket_items_attributes][${recordId}][_destroy]"
    id="ticket_ticket_items_attributes_${recordId}__destroy">
    </td>
    <td>${this.itemValue.barcode}</td>
    <td>${this.itemValue.categoryName}</td>
    <td class="text-right">
    <a data-action="nested-form#removeAssociation ticket-item-form#updateDeadlines" class="ml-2" href="#">
    <i class="fa-light fa-trash-can text-danger"></i></a></td></tr>`
  }

  currentIds() {
    const ids = []
    document.querySelectorAll('[data-item-id]').forEach((row) => {
      ids.push(parseInt(row.dataset.itemId))
    })

    return ids
  }

  updateRootCategory() {
    const rootCatEle = document.getElementById('ticket_root_category_id')
    if (rootCatEle.value == '') {
      rootCatEle.value = this.itemValue.rootCategoryId
    }
  }
}
