import { LocaleManager } from '@bryntum/scheduler/scheduler.module.min.js'

const De = {
  localeName: 'De',
  localeDesc: 'Deutsch',
  DateHelper: {
    locale: 'de',
  },
  EventEdit: {
    'Name': 'Name',
    'Resource': 'Ressource',
    'Start': 'Anfang',
    'End': 'Ende',
    'Save': 'Speichern',
    'Delete': 'Löschen',
    'Cancel': 'Absagen',
    'Edit event': 'Eintrag bearbeiten',
    'Repeat': 'Wiederholen',
  },
  Column: {
    Name: 'Objekt',
    Barcode: 'Barcode',
  },
  Object: {
    Yes: 'Ja',
    No: 'Nein',
    Cancel: 'Absagen',
    Ok: 'OK',
    Week: 'Woche',
    newEvent: 'Neuer Eintrag',
  },
  Field: {
    badInput: 'Invalid field value',
    patternMismatch: 'Value should match a specific pattern',
    rangeOverflow: function(e) {
      return 'Value must be less than or equal to '.concat(e.max)
    },
    rangeUnderflow: function(e) {
      return 'Value must be greater than or equal to '.concat(e.min)
    },
    stepMismatch: 'Value should fit the step',
    tooLong: 'Value should be shorter',
    tooShort: 'Value should be longer',
    typeMismatch: 'Value is required to be in a special format',
    valueMissing: 'This field is required',
    invalidValue: 'Invalid field value',
    minimumValueViolation: 'Minimum value violation',
    maximumValueViolation: 'Maximum value violation',
    fieldRequired: 'This field is required',
    validateFilter: 'Value must be selected from the list',
  },
  Scheduler: {
    dragValidation: 'Vergangene Daten können nicht geplant werden',
    time: 'Zeit',
    assignedTo: 'Zugewiesen an',
    remarks: 'Kommentare',
    split: 'Teilen',
    checkin: 'Rückgabe',
    checkout: 'Zuweisen',
    checkin_item_first: 'Schliessen Sie zuerst den Rückgabeprozess ab.',
    barcode: 'Barcode',
    responsible: 'Eingetragene Verantwortliche',
    category: 'Kategorie',
    serial_number: 'Seriennummer',
    scheduled_by: 'Disponiert von',
  },
  ColumnPicker: {
    column: 'Spalte',
    columnsMenu: 'Säulen',
    hideColumn: 'Spalte ausblenden',
    hideColumnShort: 'Ausblenden',
    newColumns: 'Neue Spalten',
  },
  Sort: {
    sort: 'Sortieren',
    sortAscending: 'Aufsteigend sortieren',
    sortDescending: 'Absteigend sortieren',
    multiSort: 'Mehrfachsortierung',
    removeSorter: 'Sortierer entfernen',
    addSortAscending: 'Aufsteigenden Sortierer hinzufügen',
    addSortDescending: 'Absteigender Sortierer hinzufügen',
    toggleSortAscending: 'Wechseln Sie zu aufsteigend',
    toggleSortDescending: 'Auf absteigend umstellen',
    sortAscendingShort: 'Aufsteigend',
    sortDescendingShort: 'Absteigend',
    removeSorterShort: 'Entfernen',
    addSortAscendingShort: '+ Aufsteigend',
    addSortDescendingShort: '+ Absteigend',
  },
  TimeAxisHeaderMenu: {
    pickZoomLevel: 'Zoomen',
    activeDateRange: 'Datumsbereich',
    startText: 'Startdatum',
    endText: 'Endtermin',
    todayText: 'Heute',
  },
  EventFilter: {
    filterEvents: 'Fristen filtern',
    byName: 'Namentlich',
  },
  TimeRanges: {
    showCurrentTimeLine: 'Aktuellen Zeitplan anzeigen',
  },
  SchedulerBase: {
    'Add event': 'Eintrag hinzufügen',
    'Delete event': 'Eintrag löschen',
    'Unassign event': 'Zuweisung aufheben',
  },
  EventCopyPaste: {
    copyEvent: 'Eintrag kopieren',
    cutEvent: 'Eintrag schneiden',
    pasteEvent: 'Eintrag einfügen',
  },
  Group: {
    group: 'Gruppe',
    groupAscending: 'Gruppe aufsteigend',
    groupDescending: 'Gruppe absteigend',
    groupAscendingShort: 'Aufsteigend',
    groupDescendingShort: 'Absteigend',
    stopGrouping: 'Stoppen Sie die Gruppierung',
    stopGroupingShort: 'Halt',
  },
  GridBase: {
    loadFailedMessage: 'Datenladen fehlgeschlagen!',
    syncFailedMessage: 'Datensynchronisation fehlgeschlagen!',
    unspecifiedFailure: 'Nicht näher bezeichneter Fehler',
    networkFailure: 'Netzwerkfehler',
    parseFailure: 'Serverantwort konnte nicht analysiert werden',
    serverResponse: 'Antwort des Servers:',
    noRows: 'Keine planbaren Objekte zum Anzeigen',
    moveColumnLeft: 'Zum linken Abschnitt wechseln',
    moveColumnRight: 'Zum rechten Abschnitt wechseln',
    moveColumnTo: function(e) {
      return 'Spalte verschieben nach '.concat(e)
    },
  },
}
LocaleManager.registerLocale('De', { locale: De })

enLocale = {
  Column: {
    Name: 'Item',
    Barcode: 'Barcode',
  },

  Scheduler: {
    dragValidation: 'Cannot schedule for past dates',
    time: 'Time',
    assignedTo: 'Assigned To',
    remarks: 'Comments',
    split: 'Split',
    checkin: 'Return',
    checkout: 'Checkout',
    checkin_item_first: 'Complete the return procedure first',
    barcode: 'Barcode',
    responsible: 'Responsible',
    category: 'Category',
    serial_number: 'Serial Number',
    scheduled_by: 'Scheduled by',
  },
  GridBase: {
    noRows: 'No schedulable objects to display',
  },

}
LocaleManager.extendLocale('En', enLocale)
