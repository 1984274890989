import { Controller } from '@hotwired/stimulus'
import Swal from 'sweetalert2'
import SwalStyles from '../utils/swal_styles'

// Connects to data-controller="main-form"
export default class extends Controller {
  static targets = ['form']

  submit(event) {
    this.alertMessage = event.target.dataset.alertMessage
    this.confirmation = event.target.dataset.complete
    this.signature = event.target.dataset.signatureRequired
    if (this.alertMessage) {
      this.requestConfirmation()
    } else {
      if (this.signature) {
        event.preventDefault()
        Turbo.visit('/tickets/complete_with_signature', {
          frame: 'modal-component',
        })
      } else {
        this.formTarget.submit()
      }
    }
  }

  requestConfirmation() {
    Swal.fire({
      title: this.alertMessage,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: window.I18n.t('js.cancel'),
      icon: 'question',
      iconHtml: '<div class="tw-text-3xl">?</div>',
      customClass: SwalStyles,
    }).then((result) => {
      if (result.isConfirmed) {
        this.setConfirmation()
        this.formTarget.submit()
      }
    })
  }

  setConfirmation() {
    if (!this.confirmation) {
      return
    }
    document.getElementById('ticket_complete').value = 1
  }
}
